/***************************/
/* RoadSignShimmerAnimator */
/**********************************************************************/
/* The animation class for the Road Sign Shimmer.                     */
/*                                                                    */    
/* Copyright (C) 2017-2020 Orcadia Labs LLC                           */   
/**********************************************************************/
import React  from 'react'; 
import {RoadSign_Constants    } from "../RoadSignConstants.js"             ;
import {RoadSignAnimator      } from './RoadSignAnimator.js'               ;
import {RoadSignAnimationTimer} from './RoadSignAnimationTimer'            ;
import roadSignShimmer          from '../images/spark/shimmer.png'; 

/****************/
/* ShimmerConst */
/**********************************************************************/
/* Known values for the shimmer animation.                            */
/**********************************************************************/
let ShimmerConst = {
  shimmerWidth   : '"20%',
}; /* ShimmerConst */

export class RoadSignShimmerAnimator extends RoadSignAnimationTimer {

  /*********************/
  /* getAnimationSpeed */
  /********************************************************************/
  /* Obtain the speed of this Road Sign animation.  The animator will */
  /* take more time between 'shimmers' than during.                   */ 
  /* Note: the Timer uses this value during a shimmer, even between   */
  /*       zero-th and first frame of thew first shimmer.             */
  /*       The first shimmer is special and should never be delayed   */ 
  /*       in order to have an immediate Shimmer.                     */
  /* @return the computed speed.                                      */
  /********************************************************************/
  getAnimationSpeed() {
    // in-between Shimmer frames:
    if (this.isShimmering())  return 20;
    
    // before first Shimmer:
    if (!this.isLaterShimmer) {
      this.isLaterShimmer = true;
      return 10;
    } /*if*/
    
    // normal cases between Shimmers: 0 - 20 seconds
    return (Math.random() * 20000);
  } /* getAnimationSpeed */


  /*********************/
  /* getAnimationDelay */
  /********************************************************************/
  /* The user deserves immediate feedback for starting animaitons.    */
  /* She will see shimmer right away.                                 */
  /********************************************************************/
  getAnimationDelay() {
    return (0); 
  } /* getAnimationDelay */
  

  /*****************/
  /* setShimmering */
  /* isShimmering  */
  /********************************************************************/
  /* Set this shimmer animators state or return that state.           */
  /* @param toShimmer will be true to enter into the shimmer state    */
  /*                  from the wait state, false is otherwise.        */
  /* @return          whether this shimmer animator is 'shimmering.'  */
  /********************************************************************/
  isShimmering(          ) { return this.simmering; };
  setShimmering(toShimmer) { this.simmering = (!!(toShimmer)); };
  
  
  /***************/
  /* renderFrame */
  /********************************************************************/
  /* The shimmer animator will 'animate' a single shimmer very        */
  /* quickly for effect.  It will take a fairly long break in-between */ 
  /* shimmers.                                                        */
  /* @param frameNumber is the 0-based sequential frame number.       */
  /*                    Frame #0 is the first, initialization frame.  */
  /********************************************************************/
  renderFrame(frameNumber) {
    // If this is the first frame, then perform all initialization.
    // initialization includes setting the shimmer state to wait.
    // If the animator has been stopped, request a dismount.
    if (0 == frameNumber) {
      this.setShimmering(false);
      return (<div></div>);
    } /*if*/

    // continue shimmering as long as directed to not do so.
    if (!this.isAnimating())   return null; 

    // precalculate some of the properties:
    var topPercent    = (RoadSign_Constants.portal.dimensions.fullPortal.percent.top    -  5);
    var heightPercent = (RoadSign_Constants.portal.dimensions.fullPortal.percent.height + 10);
    
    
    var shimmerStyles = {
      position         : 'absolute',
      top              : ('' + topPercent + '%'),
      left             : 0,
      width            : '20%',
      height           : ('' + heightPercent + '%'),
      transform        : "rotateZ(12deg)",
      transformOrigin  : "0 0 0",
    }; /* shimmerStyles */

    // the timer is requesting a shimmer frame:
    //   1) If a shimmer is currently ongoing, setup and return nothing.
    //   2) If a shimmer is not currently ongoing, start it.
    if (!this.isShimmering()) {
      // setup for the next shimmer
      this.setShimmering(true);
      this.leftValue = RoadSign_Constants.portal.dimensions.lowerLeft.percent.x;
      return (<div/>);
    } /*if*/

    // keep track of which shimmer frame this animator should generate
    // then increment to the next state and return image for this state.
    // the next step lies a little to the right of the previous
    if (this.leftValue <= RoadSign_Constants.portal.dimensions.lowerRight.percent.x) {
      // still some shimmer to do:
      shimmerStyles.left = '' + (++this.leftValue) + '%';
      
      return (
        <img src={roadSignShimmer} style={shimmerStyles} alt="Road sign shimmer"/>
      ); /*return*/
    } /*if*/

    // all done with this shimmer:
    this.setShimmering(false);
    return (<div/>);    
  }; /* renderFrame */
  

  /**************/
  /* Life Cycle */
  /********************************************************************/
  /* componentDidMount - register as a listener to when the conduit   */
  /*                     is instructed to start animations.           */
  /********************************************************************/
  componentDidMount() {
    this.getConduit().register(this);
    this.setShimmering();
  }; /* componentDidMount */
  
  
  /********/
  /* CTOR */
  /********************************************************************/
  /* Do everything necessary to ensure that this                      */
  /* RoadSignTargetAnimator is ready to animate.                      */
  /* @param props is component properties.                            */
  /********************************************************************/
  constructor(props) {
    super(props);
  }; /* CTOR */

};  /* RoadSignShimmerAnimator */


