/********************/
/* RoadSignAnimator */
/**********************************************************************/
/* A Road Sign Animator is any animator for the Road Sign page.       */
/* This class doesn't perform any interesting action, in particular.  */
/* It provides some common defaults and helps identify animators.     */
/*                                                                    */    
/* Copyright (C) 2019 - 2020 Orcadia Labs LLC                                                 */   
/**********************************************************************/
import React                      from 'react'                      ; 
import {RoadSign_Constants      } from '../RoadSignConstants.js'    ; 
import {RoadSignAnimationConduit} from './RoadSignAnimationTimer.js'; 


export class RoadSignAnimator extends React.Component {


  /***************/
  /* renderFrame */
  /********************************************************************/
  /* Each RoadSignAnimator instance will implement the renderFrame()  */
  /* method to provide its special animation semantic.                */
  /* By default, no content will be produced, override this method.   */
  /* @param frameNumber is the 1-based number of the frame to render. */
  /********************************************************************/
  renderFrame(frameNumber) {
    return null;
  }; /* renderFrame */
  

  /*********************/
  /* getAnimationSpeed */
  /********************************************************************/
  /* Obtain the speed of this particular animation.  If not otherwise */
  /* specified, this speed will be the declared common intra-speed    */
  /* for animations.                                                  */
  /* Individual animators should override this method to provide a    */
  /* custom-tailored speed value.                                     */
  /* @return a number which will represent the number of milliseconds */
  /*         in-between frame updates for a particular animation.     */
  /********************************************************************/
  getAnimationSpeed() {
    return RoadSign_Constants.animationDelay.intraAnimation;
  } /* getAnimationSpeed */

  
  /*********************/
  /* getAnimationDelay */
  /********************************************************************/
  /* Obtain the number of milliseconds to wait before this particular */
  /* animation begins.  By default, this value will be zero (0), do   */
  /* not delay the start of this animation.                           */
  /* @return a number which will represent the number of milliseconds */
  /*         to wait before this animation begins (first render.)     */
  /********************************************************************/
  getAnimationDelay() {
    return 0;
  } /* getAnimationDelay */
  
  
  /******************/
  /* startAnimating */
  /********************************************************************/
  /* Perform any work necessary before animations can commence.       */
  /* By default, this animator will not require any additional work   */
  /* but, should it need to perform any additonal work after          */
  /* construction and before the first frame rendering, then it       */ 
  /* should override this method.                                     */
  /********************************************************************/
  startAnimating() {
    this.getConduit().startAnimating();
  }; /* stopAnimating */

  
  /***************/
  /* isAnimating */
  /********************************************************************/
  /* Return whether this animator can still animate.  A value of true */
  /* means that this animator can render at least one more frame. A   */
  /* value of false means that this animator cannot and will not      */
  /* render any more frames.  If asked to, this animator will render  */
  /* nothing.                                                         */
  /* If an animator does not override this method, it can and might   */
  /* animate forever.                                                 */
  /* @return whether this animator can continue to animate.           */
  /********************************************************************/
  isAnimating() {
    return (this.getConduit().isAnimating());
  }; /* isAnimating */
  

  /*****************/
  /* stopAnimating */
  /********************************************************************/
  /* Stop this animator from animating.  It will no longer function   */
  /* Typically, an animator will terminate itself, once its internal  */
  /* criteriae for termination has been realized.  However, any       */
  /* Road Sign animator may be terminated externally.                 */
  /* A particular Road Sign animator should override this method if   */
  /* any special work is required.                                    */
  /********************************************************************/
  stopAnimating() {
    this.getConduit().stopAnimating();
  }; /* stopAnimating */
  

  /**************/
  /* setConduit */
  /* getConduit */
  /********************************************************************/
  /* Establish or access the RoadSignAnimator communication channel.  */
  /********************************************************************/
  setConduit(conduit) { this.animatorConduit = conduit;};
  getConduit()        { return this.animatorConduit   ;};

  
  /**********/
  /* render */
  /********************************************************************/
  /* Default render() method.  This RoadSignAnimator will not render  */
  /* anything.  It expects this method to be overridden by an         */
  /* RoadSignAnimator subclass.                                       */
  /********************************************************************/
  render() { 
    return null; 
  }; /* render */

  
  /********/
  /* CTOR */
  /********************************************************************/
  /* By default, an animator state is set to (isAnimating).           */
  /* @param props is component properties.                            */
  /* @prop  RoadSignConstants.property.conduit is the conduit object. */
  /*                                                                  */
  /* This RoadSignAnimator expects a conduit upon input.  If it does  */
  /* not receive one, then it will create one for its own use.        */
  /********************************************************************/
  constructor(props) {
    /* use all props but ensure this animator has a conduit anyway.   */
    super(props);
    this.setConduit(((props) && (props[RoadSign_Constants.property.conduit])) ||
                    new RoadSignAnimationConduit());
    this.stopAnimating();
  }; /* stopAnimating */
  
};  /* RoadSignAnimator */



