/**************************/
/* RedWingTradingPost.com */
/**********************************************************************************************/
/* SPA for the root Red Wing Trading Post website.                                            */
/*                                                                                            */    
/* Copyright (C) 2019 - 2020 Orcadia Labs LLC                                                 */   
/**********************************************************************************************/
// React boilerplate
import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';


// Orcadia Labs - Red Wing Trading Post
import './App.css';
import {RoadSign                         } from "./roadsign/RoadSign.js"                        ;
import {MyStory                          } from "./mystory/MyStory.js"                          ;
import {RedWingTradingPostLibraryConveyer} from './library/RedWingTradingPostLibraryConveyer.js';
import {RWTP_404                         } from './RWTP_404.js'                                 ;
import {RWTP_Constants                   } from './constants.js'                                ;

       
// RedWingTradingPost.com SPA page
export class App extends React.Component { 



  // Route to specific pages
  // To link to a specific page in the route, use ie:
  //   <Link to="/about">About Us </Link>
  render() {
    // As the main app renders, change the title (Chrome tab)
    document.title = RWTP_Constants.titleRWTP;
    
    return (
      <div className="App" >
        <Switch>
          <Route path = "/"         component={RoadSign} exact />
          <Route path = "/roadsign" component={RoadSign} exact />
          <Route path = "/mystory"  component={MyStory } exact />
          <Route                    component={RWTP_404}       />
        </Switch>
      </div>
    ); /*return*/
  } /* render */
  
  constructor(props) {
    super(props);
    
    // Perform initialization:
    RedWingTradingPostLibraryConveyer.ConstructConveyer();
    
  }; /* CTOR */
}; /* App */

