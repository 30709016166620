/****************************/
/* RoadSignForthAnimator.js */
/**********************************************************************************************/
/* The animation class for the Road Sign Forth experience.                                    */
/*                                                                                            */    
/* Copyright (C) 2017-2020 Orcadia Labs LLC                                                   */   
/**********************************************************************************************/
import React  from 'react'; 
import {RoadSign_Constants               } from '../RoadSignConstants.js'                     ;
import {RoadSignAnimator                 } from './RoadSignAnimator.js'                       ;
import {RoadSignAnimationTimer           } from './RoadSignAnimationTimer'                    ;
import {RedWingTradingPostLibrarian      } from '../../library/RedWingTradingPostLibrarian.js'; 
import {RedWingTradingPostLibraryConveyer} from '../../library/RedWingTradingPostLibraryConveyer.js' ; 


/***************************/
/* RoadSignForth_Constants */
/**********************************************************************************************/
/* Constants for use by the RoadSign Forth animation.                                         */
/**********************************************************************************************/
const RoadSignForth_Constants = {
  /* Side identifies to which side on the Roach Sign perimeter Forth  */
  /* is referring.                                                    */
  side : {
    top    : 0,
    right  : 1,
    bottom : 2,
    left   : 3,
    
    // randomly choose one of the available RoadSignForth_Constants.side(s)
    pick   : () => {return (Math.floor(Math.random() * 4))},
  }, /* side */  
  
  /* Declare how fast this Forth animation will animate.              */
  /* A (speed) value of 0 means no animation, 1 is slow, 2 is faster. */
  speed    : 0.1,
  
  /* Declare the scale of the Forth animation at start and transition */
  /* Also declare the delta for each Forth step.                      */
  scalePercent : {
    start      :  30,
    transition : 100,
  }, /* scale */
}; /* RoadSignForth_Constants */


/**********************************/
/* ForthAnimationState            */
/**********************************************************************************************/
/* A particular Road Sign Forth animation can be in one of three animation states.  These     */
/* values represent states, not transitions.                                                  */
/* There is no first, second, last state.  All are mutually possible.                         */
/*                                                                                            */
/*   forthAnimationPreTransition  - is animating before transition.                           */
/*   forthAnimationPostTransition - is animating after  transition.                           */
/*   forthAnimationDone           - is done animating.                                        */
/**********************************************************************************************/
const ForthAnimationState = {
  forthAnimationPreTransition  : 'a',
  forthAnimationPostTransition : 'b',
  forthAnimationDone           : 'c',
}; /* ForthAnimationState */


/****************************/
/* RoadSignForthInformation */
/**********************************************************************************************/
/* Represents all information required for one step in a Forth animation.                     */
/**********************************************************************************************/
class RoadSignForthInformation {

  /********************/
  /* getTopPercent    */
  /* getRightPercent  */
  /* getBottomPercent */
  /* getLeftPercent   */
  /* getWidthPercent  */
  /* getHeightPercent */
  /********************************************************************************************/
  /* Obtain access to all information used to render a Forth frame.                           */
  /********************************************************************************************/
  getTopPercent    () { return this.forthPercent.top   ; };
  getRightPercent  () { return this.forthPercent.right ; };
  getBottomPercent () { return this.forthPercent.bottom; };
  getLeftPercent   () { return this.forthPercent.left  ; };
  getWidthPercent  () { return this.forthPercent.width ; }; 
  getHeightPercent () { return this.forthPercent.height; };

  
  /********/
  /* step */
  /********************************************************************************************/
  /* Perform one more step in this Forthing animation.                                        */
  /********************************************************************************************/
  step() {
    // The speed factor determines how fast the image "Forth"s  
    var speed = (RoadSignForth_Constants.speed);
    var trajectory = this.forthPlan.getTrajectory();

    this.forthPercent.top    += (speed * trajectory.top   );
    this.forthPercent.right  += (speed * trajectory.right );
    this.forthPercent.bottom += (speed * trajectory.bottom);
    this.forthPercent.left   += (speed * trajectory.left  );
    this.forthPercent.width   = (this.forthPercent.right  - this.forthPercent.left);
    this.forthPercent.height  = (this.forthPercent.bottom - this.forthPercent.top );
  }; /* step */

  
  /********************/
  /* getForthingState */
  /* setForthingState */
  /********************************************************************************************/
  /* Obtain access to the state of this Forthing as defined above.                            */
  /********************************************************************************************/
  getForthingState(             ) { return this.forthingState;         };
  setForthingState(forthingState) { this.forthingState = forthingState; };

    
  /**************/
  /* initialize */
  /********************************************************************************************/
  /* Create all internal data required to commit this Forthing.                               */
  /********************************************************************************************/
  initialize(thisForthPlan) {
    this.forthPlan    = thisForthPlan;
    this.forthPercent = {
                          top    : thisForthPlan.travelPath.boxPercent.start.top   ,
                          right  : thisForthPlan.travelPath.boxPercent.start.right ,
                          bottom : thisForthPlan.travelPath.boxPercent.start.bottom,
                          left   : thisForthPlan.travelPath.boxPercent.start.left  ,
                          width  : thisForthPlan.travelPath.boxPercent.start.width ,
                          height : thisForthPlan.travelPath.boxPercent.start.height,
                        }; /* forthPercent */
  }; /* initialize */
  
  /********/
  /* CTOR */ 
  /********************************************************************************************/
  /* save all relevant data.                                                                  */
  /* @param forthPlan is the pre-calculated Forth plan.  This class will extract/use this     */
  /*                  information as needed.                                                  */
  /********************************************************************************************/
  constructor(forthPlan) { 
    this.initialize(forthPlan);    
  }; /* CTOR */
  
}; /* RoadSignForthInformation */


/*************************/
/* RoadSignForthExecutor */
/**********************************************************************************************/
/* Given a RedWingFortPlan, this method 'executes' it, rendring one frame image per           */
/* invocation.                                                                                */
/* The plan does not animate Forth, it just holds data for it.                                */
/**********************************************************************************************/
class RoadSignForthExecutor {


  /***************************/
  /* getNextForthInformation */
  /********************************************************************************************/
  /* Initialize this executor so that it can render individual Forth frames.                  */
  /* Internally, this method will return the current Forth information and then set up for    */
  /* the next.  This way, it will return the initial Forth information at its first           */
  /* invocation.                                                                              */
  /* @return a RoadSignForthInformation object containing all of the information required to  */
  /*         render the next Forth frame.  This method will return a null when it has fully   */
  /*         executed an entire Forth sequence.                                               */
  /********************************************************************************************/
  getNextForthInformation() {

    // Initialize state that will help render this Forthing:
    var isTransitioned    = false; 
    var isDone            = false;
  
    if (!this.currentForthFrame) { // Compute the first frame:
      this.currentForthFrame = new RoadSignForthInformation(this.forthPlan);
    } /*if*/
    else                         { // Compute the next frame(s:)
      this.currentForthFrame.step();
    } /*else*/
    
    // This animation is over once the opposite end of the image exceeds a side:
    // This animation has transitioned once the width of the imagebox exceeds the width
    // of the transition box, or the same rule for height (both should occur at the same time.
    var isDone         = ((this.currentForthFrame.getTopPercent   () > 100) ||
                          (this.currentForthFrame.getRightPercent () <   0) ||
                          (this.currentForthFrame.getBottomPercent() <   0) ||
                          (this.currentForthFrame.getLeftPercent  () > 100));
    var isTransitioned = ((this.currentForthFrame.getWidthPercent () >= this.forthPlan. getBoxTransitionPercent().width) ||
                          (this.currentForthFrame.getHeightPercent() >= this.forthPlan. getBoxTransitionPercent().height));
      
    // save all of the calculations for the image render:
    this.currentForthFrame.setForthingState(ForthAnimationState.forthAnimationPreTransition); 
    if      (isDone        ) {
      this.currentForthFrame.setForthingState(ForthAnimationState.forthAnimationDone); 
    } /*if*/
    else if (isTransitioned) {
      this.currentForthFrame.setForthingState(ForthAnimationState.forthAnimationPostTransition);
    } /*else*/
      
    return (this.currentForthFrame);     
  }; /* RoadSignForthExecutor */

  
  /********/
  /* CTOR */
  /********************************************************************************************/
  /* Initialize this executor so that it can render individual Forth frames.                  */
  /* @param forthPlan is the non-null, already constructed and computed RoadSignForthPlan     */
  /*                  object.                                                                 */
  /*                                                                                          */
  /* The executor should have been already constructed and internal initial values set.       */
  /********************************************************************************************/
  constructor(forthPlan) {
    this.forthPlan = forthPlan;
  } /* CTOR */
}; /* RoadSignForthExecutor */


/*********************/
/* RoadSignForthPlan */
/**********************************************************************************************/
/* Contains all methods and members for a particular forth plan.  The plan does not animate   */
/* Forth, it just holds data for it.                                                          */
/**********************************************************************************************/
class RoadSignForthPlan {

  /******************/
  /* getLibraryCard */
  /********************************************************************************************/
  /* Access the library card for the image and related information.                           */
  /********************************************************************************************/
  getLibraryCard() {
    return this.imageLibraryCard;
  }; /* imageLibraryCard */



  /****************/
  /* getImageSize */
  /********************************************************************************************/
  /* The (original / untranslated / unscaled) size of this image as expressed as pixels.      */
  /* @return the size of this image, an object with x and y fields.                           */
  /********************************************************************************************/
  getImageSize() {
    return this.image.getSize();
  }; /* getImageSize */


  /****************/
  /* getStartSide */
  /********************************************************************************************/
  /* @return the starting side of the Forth animation according to this Red Wing plan.        */
  /********************************************************************************************/
  getStartSide() {
    return (this.travelPath.path.startSide); 
  } /* getStartSide */
  
  
  /**********************/
  /* getBoxStartPercent */
  /********************************************************************************************/
  /* @return the starting position of this Forth animation according to this Red Wing Forth   */
  /*         plan.                                                                            */
  /*         This object will have the following members as %s:                               */
  /*           top, left, width, height                                                       */
  /********************************************************************************************/
  getBoxStartPercent() {
    return (this.travelPath.boxPercent.start);
  } /* getStartPosition */
  
  
  /***************************/
  /* getBoxTransitionPercent */
  /********************************************************************************************/
  /* @return the transition box properties:                                                   */ 
  /********************************************************************************************/
  getBoxTransitionPercent() {
    return (this.travelPath.boxPercent.transition);
  } /* getBoxTransitionPercent */
  
  
  /*****************/
  /* getTrajectory */
  /********************************************************************************************/
  /* @return the trajectory object for this Red Wing Forth plan.                              */
  /*         Each of the four sides are represented.                                          */
  /********************************************************************************************/
  getTrajectory() {
    return (this.travelPath.path.trajectory);
  } /* getTrajectory */
  
  
  /*********************/
  /* selectLibraryCard */
  /********************************************************************************************/
  /* Select and return one (RedWingTradingPostLibraryCard) object which will represent the    */
  /* next image to Forth.                                                                     */
  /*                                                                                          */
  /* @return  a Promise that either resolve or reject.                                        */
  /* @resolve when the Forth animator concludes selecting and preparing one library card.     */
  /*          The payload is the one library card.                                            */
  /*                                                                                          */
  /* Important!                                                                               */
  /* This method comes in two flavors.  They both exist here so that they can be easily and   */
  /* quickly swapped in case a problem arises.                                                */
  /*   (1) old version which picks one item at random out of the library.  This item will be  */
  /*       "Forth"ed using its html address and may show some latency because the item will   */
  /*       take some time to download and may not even finished before it is Forthed.  This   */
  /*       effect works but the Forthed item can swap in mid-Forth which is ugly.             */
  /*   (2) new version which uses the (RedWingTradingPostLibraryConveyer) to preload items.   */
  /*       These items will be fully downloaded before being used, eliminating the ugly       */
  /*       swapping, visual effect.                                                           */
  /********************************************************************************************/
      
  async selectLibraryCard() {
    return (new Promise((resolve, reject) => {
  
              // (1) use the (RedWingTradingPostLibrarian):
              var selectLibraryCardByLibrarian = () => {
                // pick a library card, be exclusive, demand that the image has dimension (for unscewed display:)
                var imageLibraryCard = null;
                
                do {
                  imageLibraryCard  = RedWingTradingPostLibrarian.PickRedWingTradingPostLibraryCard();
                } /*do*/
                while (!RedWingTradingPostLibrarian.IsUsableLibraryCard(imageLibraryCard));
                
                resolve (imageLibraryCard);
              }; /* selectLibraryCardByLibrarian */
            
            
              // (2) use the (RedWingTradingPostLibraryConveyer):
              var selectLibraryCardByConveyer = () => {
                RedWingTradingPostLibraryConveyer
                  .Pick()
                  .then((pickedLibraryCard) => {
                         resolve(pickedLibraryCard);
                  });
              }; /* selectLibraryCardByConveyer */
            
             
              // Both of these methods will select one library card.  Only one should be uncommented:
              // These methods are described above.
//              selectLibraryCardByLibrarian();
              selectLibraryCardByConveyer ();
           })); /*return*/
  }; /* selectLibraryCard */


  /*****************************************/
  /* buildRoadSignForthPlanViaSelectedCard */
  /********************************************************************************************/
  /* Given one library card, construct a Forth plan that is appropriate and correct for it.   */
  /*                                                                                          */
  /* Actions:                                                                                 */
  /*  (1) select the size of the image to 'Forth'                                             */
  /*  (2) Calculate window that this image will 'travel' though.                              */
  /*      This transition window will use the portion of the transition                       */
  /*      associated with the image's size and the exact dimensions                           */
  /*      of the image, itself, so that it appears to transition                              */
  /*      through the exact center of the transition window,                                  */
  /*      both horizontally and vertically.                                                   */
  /*  keep all information about the path the image will travel:                              */
  /*                                                                                          */
  /* @param libraryCard is the (RedWingTradingPostLibraryCard) for which to build a plan for  */
  /*                    this animator.                                                        */
  /********************************************************************************************/
  buildRoadSignForthPlanViaSelectedCard(libraryCard) {

    // calculate all transition information:
    // The Forth Animator cannot calculate this information if the image height or width is undefined
    // Select one of the translation windows:
    // accept portal dimensions for only the small and medium-sized portals (2.)
    var  transitionCount   = RoadSign_Constants.portal.transition.imageDimensions.length;
    var  transitionWhich   = Math.floor(Math.random() * (transitionCount));
    var  transitionInfo    = RoadSign_Constants.portal.transition.imageDimensions[transitionWhich];
    var  imageWidthPixels  = libraryCard.getImageWidth ();
    var  imageHeightPixels = libraryCard.getImageHeight();
  
    
    // An image may be higher than wider or vice versa:
    this.travelPath = {
      path : {
        startSide  : undefined,        // identifies which of the four side the Forth animates
        
        trajectory : {                 // ratios of direction of motion to four sides of image box
          top    : undefined,          
          right  : undefined,
          bottom : undefined,
          left   : undefined, 
        }, /* trajecory */
      }, /* path */
      
      // the % size of the image width and height to its bounding box, not to the roadsign image
      imagePercent : {
        width           : undefined,
        height          : undefined,
        marginTopBottom : undefined,
        marginLeftRight : undefined,
      }, /* image */
  
      // % dimensions and positions of the bounding box to the roadsign image
      boxPercent : {
      
        // at the start:
        start : {
          top    : undefined,
          right  : undefined,
          bottom : undefined,
          left   : undefined,
          width  : undefined,
          height : undefined,
        }, /* start */
      
        // at the transition:
        transition : {
          top    : (transitionInfo.top   .percent),
          right  : (transitionInfo.right .percent),
          bottom : (transitionInfo.bottom.percent),
          left   : (transitionInfo.left  .percent),
          width  : (transitionInfo.right .percent - transitionInfo.left.percent),
          height : (transitionInfo.bottom.percent - transitionInfo.top .percent),
        }, /* transition */
      }, /* boxPercent */
    }; /* travelPath */
    
        
    // adjust the image to within its (statically-sized) starting box:
    // these will be the same (relative) dimensions within the transition box.
    if (imageWidthPixels >= imageHeightPixels) {
      var imageRatio = imageHeightPixels / imageWidthPixels;
      
      this.travelPath.imagePercent.width = 100;
      this.travelPath.imagePercent.height = (imageRatio * 100);
      this.travelPath.imagePercent.marginLeftRight = 0;
      this.travelPath.imagePercent.marginTopBottom = ((100 - this.travelPath.imagePercent.height) / 2); 
    } /*if*/
    else {
      var imageRatio = imageWidthPixels / imageHeightPixels;
      
      this.travelPath.imagePercent.height = 100;
      this.travelPath.imagePercent.width = (imageRatio * 100);
      this.travelPath.imagePercent.marginLeftRight = ((100 - this.travelPath.imagePercent.width) / 2);;
      this.travelPath.imagePercent.marginTopBottom = 0;
    } /*else*/

    // start anywhere between 5% and 95% of orthogonal axis:
    var positionFactorPercent = (5 + (Math.random() * 90));
    this.travelPath.path.startSide = (Math.floor(Math.random() * 4)); 
    this.travelPath.boxPercent.start.width  = (this.travelPath.boxPercent.transition.width  * RoadSignForth_Constants.scalePercent.start) / 100;
    this.travelPath.boxPercent.start.height = (this.travelPath.boxPercent.transition.height * RoadSignForth_Constants.scalePercent.start) / 100;
    
    switch (this.travelPath.path.startSide) {
      case 0 : /* top */
               this.travelPath.boxPercent.start.bottom = (RoadSign_Constants.portal.dimensions.fullPortal.percent.top);
               this.travelPath.boxPercent.start.top    = (this.travelPath.boxPercent.start.bottom - this.travelPath.boxPercent.start.height);
               this.travelPath.boxPercent.start.left   = (RoadSign_Constants.portal.dimensions.fullPortal.percent.left 
                                                       + (RoadSign_Constants.portal.dimensions.fullPortal.percent.width * positionFactorPercent / 100));
               this.travelPath.boxPercent.start.right  = (this.travelPath.boxPercent.start.left + this.travelPath.boxPercent.start.width);

               // compute trajectory of all four sides relative to top:
               this.travelPath.path.trajectory.top     = +1;
               this.travelPath.path.trajectory.bottom  = (this.travelPath.path.trajectory.top 
                                                       *   ((this.travelPath.boxPercent.transition.bottom - this.travelPath.boxPercent.start.bottom)
                                                             / (this.travelPath.boxPercent.transition.top - this.travelPath.boxPercent.start.top)));
               this.travelPath.path.trajectory.left    = (this.travelPath.path.trajectory.top 
                                                       *   ((this.travelPath.boxPercent.transition.left - this.travelPath.boxPercent.start.left)
                                                             / (this.travelPath.boxPercent.transition.top - this.travelPath.boxPercent.start.top)));
               this.travelPath.path.trajectory.right   = (this.travelPath.path.trajectory.top 
                                                       *   ((this.travelPath.boxPercent.transition.right - this.travelPath.boxPercent.start.right)
                                                             / (this.travelPath.boxPercent.transition.top - this.travelPath.boxPercent.start.top)));
               break;
      case 1 : /* right */
               this.travelPath.boxPercent.start.left   = RoadSign_Constants.portal.dimensions.fullPortal.percent.right;
               this.travelPath.boxPercent.start.right  = this.travelPath.boxPercent.start.left + this.travelPath.boxPercent.start.width;
               this.travelPath.boxPercent.start.top    = (RoadSign_Constants.portal.dimensions.fullPortal.percent.top 
                                                       + (RoadSign_Constants.portal.dimensions.fullPortal.percent.height * positionFactorPercent / 100));
               this.travelPath.boxPercent.start.bottom = this.travelPath.boxPercent.start.top + this.travelPath.boxPercent.start.height;

               // compute trajectory of all four sides relative to right:
               this.travelPath.path.trajectory.right   = -1;
               this.travelPath.path.trajectory.left    = (this.travelPath.path.trajectory.right 
                                                       *   ((this.travelPath.boxPercent.transition.left - this.travelPath.boxPercent.start.left)
                                                             / (this.travelPath.boxPercent.transition.right - this.travelPath.boxPercent.start.right)));
               this.travelPath.path.trajectory.top     = (this.travelPath.path.trajectory.right 
                                                       *   ((this.travelPath.boxPercent.transition.top - this.travelPath.boxPercent.start.top)
                                                             / (this.travelPath.boxPercent.transition.right - this.travelPath.boxPercent.start.right)));
               this.travelPath.path.trajectory.bottom  = (this.travelPath.path.trajectory.right 
                                                       *   ((this.travelPath.boxPercent.transition.bottom - this.travelPath.boxPercent.start.bottom)
                                                             / (this.travelPath.boxPercent.transition.right - this.travelPath.boxPercent.start.right)));
               break;
      case 2 : /* bottom */
               this.travelPath.boxPercent.start.top    = (RoadSign_Constants.portal.dimensions.fullPortal.percent.bottom);
               this.travelPath.boxPercent.start.bottom = (this.travelPath.boxPercent.start.top + this.travelPath.boxPercent.start.height);
               this.travelPath.boxPercent.start.left   = (RoadSign_Constants.portal.dimensions.fullPortal.percent.left 
                                                       + (RoadSign_Constants.portal.dimensions.fullPortal.percent.width * positionFactorPercent / 100));
               this.travelPath.boxPercent.start.right  = (this.travelPath.boxPercent.start.left + this.travelPath.boxPercent.start.width);

               // compute trajectory of all four sides relative to bottom:
               this.travelPath.path.trajectory.bottom  = -1;
               this.travelPath.path.trajectory.top     = (this.travelPath.path.trajectory.bottom 
                                                       *   ((this.travelPath.boxPercent.transition.top - this.travelPath.boxPercent.start.top)
                                                             / (this.travelPath.boxPercent.transition.bottom - this.travelPath.boxPercent.start.bottom)));
               this.travelPath.path.trajectory.left    = (this.travelPath.path.trajectory.bottom 
                                                       *   ((this.travelPath.boxPercent.transition.left - this.travelPath.boxPercent.start.left)
                                                             / (this.travelPath.boxPercent.transition.bottom - this.travelPath.boxPercent.start.bottom)));
               this.travelPath.path.trajectory.right   = (this.travelPath.path.trajectory.bottom 
                                                       *   ((this.travelPath.boxPercent.transition.right - this.travelPath.boxPercent.start.right)
                                                             / (this.travelPath.boxPercent.transition.bottom - this.travelPath.boxPercent.start.bottom)));
               break;
      case 3 : /* left */
               this.travelPath.boxPercent.start.right  = (RoadSign_Constants.portal.dimensions.fullPortal.percent.left);
               this.travelPath.boxPercent.start.left   = (this.travelPath.boxPercent.start.right - this.travelPath.boxPercent.start.width);
               this.travelPath.boxPercent.start.top    = (RoadSign_Constants.portal.dimensions.fullPortal.percent.top 
                                                       + (RoadSign_Constants.portal.dimensions.fullPortal.percent.height * positionFactorPercent / 100));
               this.travelPath.boxPercent.start.bottom = (this.travelPath.boxPercent.start.top + this.travelPath.boxPercent.start.height);

               // compute trajectory of all four sides relative to left:
               this.travelPath.path.trajectory.left    = +1;
               this.travelPath.path.trajectory.right   = (this.travelPath.path.trajectory.left 
                                                       *   ((this.travelPath.boxPercent.transition.right - this.travelPath.boxPercent.start.right)
                                                             / (this.travelPath.boxPercent.transition.left - this.travelPath.boxPercent.start.left)));
               this.travelPath.path.trajectory.top     = (this.travelPath.path.trajectory.left 
                                                       *   ((this.travelPath.boxPercent.transition.top - this.travelPath.boxPercent.start.top)
                                                             / (this.travelPath.boxPercent.transition.left - this.travelPath.boxPercent.start.left)));
               this.travelPath.path.trajectory.bottom  = (this.travelPath.path.trajectory.left 
                                                       *   ((this.travelPath.boxPercent.transition.bottom - this.travelPath.boxPercent.start.bottom)
                                                             / (this.travelPath.boxPercent.transition.left - this.travelPath.boxPercent.start.left)));
    }; /*switch*/

  }; /* buildRoadSignForthPlanViaSelectedCard */
  
  
  /*************/
  /* buildPlan */
  /********************************************************************************************/
  /* Build a Forth Plan so it is ready to go.  After this Forth plan is build, it may be      */
  /* executed.                                                                                */
  /*                                                                                          */
  /* @return  a Promise that will resolve().                                                  */
  /* @resolve once the Forth plan has been built.  The payload will be (this)                 */
  /*          (RoadSignForthPlan).                                                            */
  /* @reject  this method will not reject, it will resolve() a (RoadSignForthPlan).           */
  /********************************************************************************************/
  async buildPlan() {
    return (new Promise((resolve, reject) => {
    
      this.selectLibraryCard()
          .then ((selectedLibraryCard) => {
                 this.imageLibraryCard  = selectedLibraryCard;
                     
                 // build all of the information needed for this Forthing:
                 this.buildRoadSignForthPlanViaSelectedCard(this.imageLibraryCard);
                 resolve(this);
           }); /* then */
    })); /*return*/
  }; /* buildPlan */
  
  
  /********/
  /* CTOR */
  /********************************************************************************************/
  /* constructor.  Establish this plan to contain all information about a Forth.              */
  /********************************************************************************************/ 
  constructor() {
    this.imageLibraryCard = null;
    this.travelPath       = null;
  }; /* CTOR */
  
}; /* RoadSignForthPlan */



/*************************/
/* RoadSignForthAnimator */
/**********************************************************************************************/
/* Controlling animator for the forth animation.                                              */
/**********************************************************************************************/
export class RoadSignForthAnimator extends RoadSignAnimationTimer {

  /*********************/
  /* getAnimationSpeed */
  /********************************************************************************************/
  /* Obtain the speed of this Road Sign animation.  This animation operate at randomly select */
  /* speeds.                                                                                  */
  /* @return the standard speed.                                                              */
  /********************************************************************************************/
  getAnimationSpeed() {
    // In-between forth's delay 7 seconds on average:
    // During a forth, do not delay:
    if (this.isForthing()) {
      return 0;
    } /*if*/
    
    return (Math.random() * 14000 );
  } /* getAnimationSpeed */


  /*********************/
  /* getAnimationDelay */
  /********************************************************************************************/
  /* This animation should start without much delay.                                          */
  /* @return short delay of 4 seconds on average.                                             */
  /********************************************************************************************/
  getAnimationDelay() {
    // delay drawing the first forth for anywhere from 2 to 10 seconds.
    return (2000 + (Math.random() * 8000)); 
  } /* getAnimationDelay */
  

  /***********************/
  /* initializeAnimation */
  /******************************************************************************************/
  /* The (RoadSignAnimationTimer) invokes this function once before animation start.        */
  /* For the Forth animation, this is the opportunity to download the image that it will    */
  /* "Forth."                                                                               */
  /* This method is asynchronous, ensuring that the (RoadSignAnimationTimer) will wait to   */
  /* begin the animation cycle until this animator has finished the download.               */
  /*                                                                                        */
  /* @return  a Promise that can be resolve()d or reject()ed.                               */
  /* @resolve when this process has finished downloading the image.  It has no payload.     */
  /******************************************************************************************/
  async initializeAnimation() {
    return (new Promise((resolve, reject) => {
      // download the image:
//      fetch (this.
    
      // and then return to the (RoadSignAnimationTimer) to begin the Forth animation using this image:
      resolve();
    }));
  }; /* initializeAnimation */

  
  /*******************************/
  /* setForthing                 */
  /* isForthing                  */
  /* startForthing               */
  /* isDoneForthing              */
  /* registerNewForthingForCount */
  /********************************************************************************************/
  /* Maintain the Forthing state of this animator.                                            */
  /*   setForthing                 - set true or false to state whether a Forthing is ongoing.*/
  /*   isForthing                  - return whether this current Forth is actually Forthing.  */
  /*   startForthingCount          - establish that no Forths have begun.                     */
  /*   isForthingByCount           - return whether this animator is done Forthing.           */
  /*   registerNewForthingForCount - mark the start of another Forthing.                      */
  /* Establish if this animator is currently forthing.                                        */
  /* @param  forth whether a forth animation is occuring, default true.                       */
  /*                                                                                          */
  /* @return whether a forth animation is occuring.                                           */
  /********************************************************************************************/
  setForthing (forthing = false) { 
    if (forthing) {
      this.currentlyForthing = forthing;
    }
    else {
      this.currentlyForthing = forthing;
      this.registerNewForthingForCount();
    } 
  }; /* setForthing */
  
  
  isForthing() {
    // having an actual Forth plan to be Forthing is not a requirement, it is guaranteed by flow:
    //    return ((this.currentlyForthing) && (this.redWingForthPlan));
    return (this.currentlyForthing);
  }; /* isForthing */
  
  
  startForthingCount() {
    // pick a random number of the number of Forthings for this Forth animator
    // do not pick so few as to make the animator uninteresting
    // do not pick so many that the animator would download too many Red Wing images:
    this.currentForthingCount = Math.round(( Math.random() * (19 /* variable number */) + (13 /* minimum number */))); 
  }; /* startForthingCount */
  
  
  isForthingByCount() {
    return (this.currentForthingCount > 0);
  }; /* isForthingByCount */
  
  
  registerNewForthingForCount() {
    --this.currentForthingCount;
  }; /* registerNewForthing */
  
  
  /******************/
  /* selectForthing */
  /********************************************************************************************/
  /* Perform all of the static selection required when the timer requests a new 'forthing.'   */
  /* Selections include:                                                                      */
  /*   (1) which Red Wing image to 'forth.'                                                   */
  /*   (3) which direction the image will 'forth.'                                            */
  /* This method assumes that the animation state is handled elsehwere.                       */
  /********************************************************************************************/
  async selectForthing() {
    return (new Promise((resolve, reject) => {
      this.redWingForthPlan     = new RoadSignForthPlan();
      this.redWingForthPlan.buildPlan().then((newForthPlan) => {
        this.redWingForthExecutor = new RoadSignForthExecutor(newForthPlan);
        resolve();
      }); /* buildPlan */
    })); /*return*/
  }; /* selectForthing */
  

  static staticallyTestForthPlan() {
    this.redWingForthPlan = new RoadSignForthPlan();
  };  /* staticallyTestForthPlan */
  

  /*********************/
  /* renderSingleFrame */
  /********************************************************************************************/
  /* This method is the breakout from renderFrame().  This method performs the rendering of a */
  /* single frame of the Forth animation.                                                     */
  /* @param frameNumber is the number of the frame to render.  This method will generate no   */
  /*                    information at (frameNumber == 0).                                    */
  /********************************************************************************************/
  renderSingleFrame(frameNumber) {
    var frameStyles = {
          boxPosition : {
            position : 'absolute',
            top      : undefined,
            left     : undefined,
            height   : undefined,
            width    : undefined,
            zIndex   : undefined,
          }, /* position */
          
          /* image width and height, represented as %s                */
          image : {
            position : 'absolute',
            width    : undefined,
            height   : undefined,
            left     : undefined,
            top      : undefined,
            zIndex   : undefined,
          }, /* scale */
        }; /* frameStyles */
    
    
    // Obtain updated executor information and draw the image: 
    // Calculate the bounding box information:
    var frameInfo = this.redWingForthExecutor.getNextForthInformation();
    frameStyles.boxPosition.top     = (frameInfo.getTopPercent   () + '%'); 
    frameStyles.boxPosition.left    = (frameInfo.getLeftPercent  () + '%'); 
    frameStyles.boxPosition.width   = (frameInfo.getWidthPercent () + '%');
    frameStyles.boxPosition.height  = (frameInfo.getHeightPercent() + '%');
//    frameStyles.boxPosition.zIndex  = (ForthAnimationState.forthAnimationPreTransition == frameInfo.getForthingState())
//                                    ? RoadSign_Constants.depth.forth.preTransition
//                                    : RoadSign_Constants.depth.forth.postTransition;
//    frameStyles.image.zIndex  = (ForthAnimationState.forthAnimationPreTransition == frameInfo.getForthingState())
//                                    ? RoadSign_Constants.depth.forth.preTransition
//                                    : RoadSign_Constants.depth.forth.postTransition;
    
    // calculate the new depth for the simulated Forthing and assign it:
    var newZIndex = RoadSign_Constants.depth.forth.preTransition;
    if (ForthAnimationState.forthAnimationPostTransition == frameInfo.getForthingState()) {
      newZIndex = RoadSign_Constants.depth.forth.postTransition;
    } /*if*/    
    frameStyles.boxPosition.zIndex = newZIndex;
    frameStyles.image      .zIndex = newZIndex;
    
    // Calculate the image within-the-box information:    
    var imageInBox = frameInfo.forthPlan.travelPath.imagePercent;
    frameStyles.image.width        = ('' + imageInBox.width           + '%'); 
    frameStyles.image.height       = ('' + imageInBox.height          + '%');
    frameStyles.image.top          = ('' + imageInBox.marginTopBottom + '%');
    frameStyles.image.left         = ('' + imageInBox.marginLeftRight + '%');
    
    this.setForthing(ForthAnimationState.forthAnimationDone != frameInfo.getForthingState());

    return(
      <div style={frameStyles.boxPosition}>
        <img style={frameStyles.image} src={this.redWingForthPlan.getLibraryCard().getImageAddress()}/>
      </div>
    ); /*return*/
    
  }; /* rendersingleFrame */
  
  
  /***************/
  /* renderFrame */
  /********************************************************************************************/
  /* The forth animator will 'animate' exactly one 'forthing' at a time.  It will select a    */
  /* Red Wing image at random and then the image 'forthing' for a realistic animation.  To    */
  /* show multiple 'forthings' at-a-time, declare multiple RoadSignForthAnimators.            */
  /* This animator will animate only so many Forthings.  After this number, it stops.         */
  /*                                                                                          */
  /* @param frameNumber is the 0-based sequential frame number.  Frame #0 is the first,       */
  /*                    initialization frame.                                                 */
  /********************************************************************************************/
  renderFrame(frameNumber) {
    
    // allow this Forth animator to animate a Forth so many times:
    if (!this.isForthingByCount()) {
      this.stopAnimating();
    } /*if*/
    
    // If this is the first frame of a new Forthing,
    // then return no frame because there is no active Forth at this time.
    if ((0 == frameNumber) || (!this.isForthing())) {
      return (<div></div>);
    } /*if*/

    // now, return the first/next stage of current Forth:
    return (this.renderSingleFrame(frameNumber));
  }; /* renderFrame */
  

  /*************************/
  /* performInterframeWork */
  /********************************************************************************************/
  /* For Forth, keeping the (RedWingTradingPostLibraryConveyer) full is important work.       */
  /* If Forth is in-between Forthings, asynchronously download another image:                 */
  /* @return  a Promise that will resolve.                                                    */
  /* @resolve after this (RedWingForthAnimator) is ready to go.                               */
  /* @reject  this method will not reject.                                                    */
  /********************************************************************************************/
  async performInterFrameWork() {
    return (new Promise((resolve, reject) => {
              // every time a new Forthing should be started, first initialize it:
             if (!this.isForthing()) {
               this.setForthing   (true);
               this.selectForthing(    )
                   .then(() => {
                      resolve();
                    });
             } /*if*/
             else {
               // still Forthing, no need to do anything:
               resolve();
            } /* else */
    })); /*return*/      
  }; /* performInterFrameWork */
  
  
  /**************/
  /* Life Cycle */
  /********************************************************************************************/
  /* componentDidMount - register as a listener to when the conduit is instructed to start    */
  /*                     animations.                                                          */
  /********************************************************************************************/
  componentDidMount() {
    this.getConduit().register(this);
    this.setForthing(false);
  }; /* componentDidMount */
  
  
  /********/
  /* CTOR */
  /********************************************************************************************/
  /* Do everything necessary to ensure that this RoadSignForthAnimator is ready to animate.   */
  /* @param props is component properties.                                                    */
  /********************************************************************************************/
  constructor(props) {
    super(props);
    this.startForthingCount();
  }; /* CTOR */

};  /* RoadSignForthAnimator */


