/**********************/
/* RoadSign_Constants */
/**********************************************************************/
/* Collection of useful information for the RWTP Road Sign.           */
/*                                                                    */    
/* Copyright (C) 2019 - 2020 Orcadia Labs LLC                                                 */   
/**********************************************************************/
export const RoadSign_Constants = {
  /* general constant values                                          */
  constant : {
    imageStartingScale   : 0.5, /* The relative size of the image     */
                                /* at its off-portal starting point.  */
    imageTransitionScale : 1.0, /* The relative size of the image     */
                                /* at its in-portal transition point. */
    imageStoppingScale   : 2.0, /* the relative size of the image     */
                                /* at its off-portal stopping point.  */
  },
  
  /* Uniquely identify each animation                                 */
  /* these values provide no useful information, each value need only */
  /* be unique in this set.                                           */
  animation : {
    // this block of readSign...Animation is not used, delete it:
    roadSignNoAnimation      : '',        /* display no animation     */
    roadSignTargetAnimation  : 'a1',      /* menu target animation    */
    roadSignTitleAnimation   : 'a2',      /* RWTP title animation     */
    roadSignShimmerAnimation : 'a3',      /* shimmer animation        */
    roadSignStarAnimation    : 'a4',      /* star animation           */
    roadSignForthAnimation   : 'a5',      /* forth animation          */
    roadSignHeartAnimation   : 'a6',      /* heart animation          */
  }, /* animation */

  /* properties to use for communication between individual           */
  /* components of the roadsign or for the RoadSign componet outright */
  property : {
    animation  : 'animation',        /* which animation               */
    animator   : 'animator',         /* an animator object.           */
    conduit    : 'animationConduit', /* the line of communication     */
                                     /* between an animator and it's  */
                                     /* owner                         */
  }, /* property                                                      */
  
  /* Delays (milliseconds) to use for consistent animations:          */
  animationDelay: {            /* consistent, standard delays         */
    intraAnimation :  100,     /* standard delay in animation renders */ 
    interAnimation : 2000,     /* standard delay between animations   */
  }, /* animationDelay */
  
  /* Depth is a common place to assign z-index values so that         */
  /* animations overlap consistently and attractively.                */
  depth : {
    star             :   3000,   // for the Star animator
    forth : {                    // for the Forth animator
      preTransition  :      0,   //    preTransitin < postTransition
      postTransition :   2000,
    }, /* forth */
    menuSign         :  10000,   // for the Menu Sign, should be  > Road Sign
    roadSign : {                 // for the Road Sign, front and back
      fore           :      0,   
      back           : - 5000,  
    }, /* roadSign */
    shimmer          : - 2000,   // shimmer depth between Road Sign fore and back
    inOutGo          :  10000,   // for InOutGo images, should be > Road Sign
  },
  
  /* define dimensions of entire Road Sign image:                     */
  landingPageImageDimensions  : {pixels  : { x : 4600, y : 3200,}, 
                                 percent : { x :  100, y :  100,}, 
  }, /* landingPageImageDimensions
  
  /* Define the portal space within the landing page image as both    */
  /* pixels and percentages:                                          */
  
  
  portal : {
    /* (imageSelection) identifies which image information to use and */
    /* correspons to array portal.transition.imageDimensions.         */
    imageSelection : {
      largeImage : 0,
      mediumImage: 1,
      smallImage : 2,
      leftImage  : 3,
      rightImage : 4,
    }, /* imageSelection */
    
    dimensions : {
      // the full portal dimensions is the tightest bounding box of the portal:
      fullPortal   : {pixels : {top : 697    , right : 3427    , bottom : 1490    , left : 1371    , width : 2056    , height : 793    ,},
                      percent: {top :  21.78 , right :   74.50 , bottom :   46.56 , left :   29.80 , width :   44.70 , height :  24.78 ,},
                     }, /* full_portal */
      /* dimensions gives actual locations for each point on roadsign */
      upperCentral  : {pixels  : {x : 2380    , y :  697    ,},
                       percent : {x :   51.74 , y :   21.78 ,}, 
                      }, /* upper_central */
      lowerCentral  : {pixels  : {x : 2380    , y : 1480    ,},
                       percent : {x :  51.74  , y :   46.25 ,}, 
                      }, /* upper_central */
      lowerLeft     : {pixels  : {x : 1371    , y : 1490    ,},
                       percent : {x :   29.80 , y :   46.56 ,}, 
                      }, /* lower_left */
      lowerRight    : {pixels  : {x : 3427    , y : 1470    ,},
                       percent : {x :   74.50 , y :   45.94 ,}, 
                      }, /* lower_right */
    }, /* dimensions */
    
    transition : {
      center : { pixels  : {x :  2399   , y : 1089    ,},
                 percent : {x :    52.15, y :   34.03 ,},
               },
      imageDimensions : [
        {     /* imageSelection.largeImage  : */
          image  : {pixels  : {width : 500   , height : 500   ,},
                    percent : {width :  10.87, height :  15.65,},
                   },
          top    : {pixels :  875, percent : 27.34,},
          right  : {pixels : 2650, percent : 57.61,},
          bottom : {pixels : 1375, percent : 42.97,},
          left   : {pixels : 2150, percent : 46.74,},
        }, /* large image */

        {     /* imageSelection.mediumImage  : */
          image  : {pixels  : {width : 350   , height : 350   ,},
                    percent : {width :  7.61, height :  10.94,},
                   },
          top    : {pixels :  950, percent : 29.69,},
          right  : {pixels : 2575, percent : 55.98,},
          bottom : {pixels : 1300, percent : 40.63,},
          left   : {pixels : 2225, percent : 48.37,},
        }, /* medium image */

        {     /* imageSelection.smallImage  : */
          image  : {pixels  : {width : 250   , height : 250   ,},
                    percent : {width :  5.43, height :  7.81,},
                   },
          top    : {pixels : 1000, percent : 31.25,},
          right  : {pixels : 2550, percent : 55.43,},
          bottom : {pixels : 1250, percent : 39.06,},
          left   : {pixels : 2300, percent : 50.00,},
        }, /* small image */

        {     /* imageSelection.leftImage  : */
          image  : {pixels  : {width : 200   , height : 200   ,},
                    percent : {width :  4.35, height : 6.25 ,},
                   },
          top    : {pixels : 1225, percent : 38.28,},
          right  : {pixels : 1800, percent : 39.13,},
          bottom : {pixels : 1425, percent : 44.53,},
          left   : {pixels : 1600, percent : 34.78,},
        }, /* left image */

        {     /* imageSelection.rightImage  : */
          image  : {pixels  : {width : 2000   , height : 200   ,},
                    percent : {width :  4.35, height :  6.25,},
                   },
          top    : {pixels : 1200, percent : 37.50,},
          right  : {pixels : 3175, percent : 69.02,},
          bottom : {pixels : 1400, percent : 43.75,},
          left   : {pixels : 2975, percent : 64.67,},
        }, /* right image */
      ],   /* image dimensions */
    },     /* transition       */
  }, /* portal */
  
}; /* RoadSign_Constants */





