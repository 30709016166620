/**********************/
/* RWTP_AllCollection */
/**********************************************************************************************/
/* A Red Wing Trading Post collection which collects the entire Red Wing Trading Post Library */
/* of items.                                                                                  */
/*                                                                                            */
/* Copyright 2020, Orcadia Labs LLS.  All Rights Reserved.                                    */
/**********************************************************************************************/ 
import {RedWingTradingPostLibraryCollection} from './RedWingTradingPostLibraryCollection.js'; 
import {RedWingTradingPostLibrary}           from '../RedWingTradingPostLibrary.js'         ; 


export class RWTP_AllCollection extends RedWingTradingPostLibraryCollection {

  /*********************/
  /* getCollectionName */
  /********************************************************************************************/
  /* Statically- and globally-accessible name of this collection.  This name may be           */
  /* referenced statically to obtain the name of this specific collection.  This name may     */
  /* also be referenced globally through the class instance method getName().                 */
  /********************************************************************************************/
  static getKnownCollectionName() { return "RWTP_All_Collection"};
  
  
  /***********/
  /* getName */
  /********************************************************************************************/
  /* Each RedWingTradingPostLibraryCollection subclass will return its name.                  */
  /* @return the name of this collection.                                                     */
  /********************************************************************************************/
  getName() { return (RWTP_AllCollection.getKnownCollectionName()); }; 
  
  
  /********/
  /* CTOR */
  /********************************************************************************************/
  /* Record access to this collection.                                                        */
  /* For this objects integrity, this collection will contain the computed the library keys   */
  /********************************************************************************************/
  constructor() {
    super(Object.keys(RedWingTradingPostLibrary));
  }; /* CTOR */
}; /* RWTP_AllCollection  */


