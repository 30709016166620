/*************************/
/* RoadSignStarAnimator */
/**********************************************************************/
/* The animation class for the Road Sign Star.                        */
/*                                                                    */    
/* Copyright (C) 2017-2020 Orcadia Labs LLC                           */   
/**********************************************************************/
import React  from 'react'; 
import {RoadSign_Constants    } from "../RoadSignConstants.js"          ;
import {RoadSignAnimator      } from './RoadSignAnimator.js'            ;
import {RoadSignAnimationTimer} from './RoadSignAnimationTimer'         ;
import roadSignStar             from '../images/spark/star.png'; 


/*****************/
/* StarLocations */
/********************************************************************/
/* This object contains all valid star positions including sizes.   */
/* All values are percentages.                                      */
/********************************************************************/
const RoadSignStarAnimator_Constants = {
  maxNumberInterations : 50,
  
  starPositions : [
    /* horizontal   vertical   star width                             */
    {x : "27%",   y : "18%",  w : '3%' },   /* RWTPost" menu sign      */  
    {x : "77.5%", y : "50%", w : '5%' }, /* outside "Red Wing"      */
    {x : "48.5%",   y : "26%", w : '8%'},   /* inside "Red Wing"       */
  ], /* starPositions */
};  /* RoadSignStarAnimator_StarPositions */
  
  

export class RoadSignStarAnimator extends RoadSignAnimationTimer {

  /*********************/
  /* getAnimationSpeed */
  /********************************************************************/
  /* Obtain the speed of this Road Sign animation.  This animation    */
  /* operate at randomly select speeds.                               */
  /* @return the standard speed.                                      */
  /********************************************************************/
  getAnimationSpeed() {
    // draw a new star every 20 seconds on average:
    return (Math.random() * 40000);
  } /* getAnimationSpeed */


  /*********************/
  /* getAnimationDelay */
  /********************************************************************/
  /* This animation has a considerable dalay.                         */
  /* @return this delay.                                              */
  /********************************************************************/
  getAnimationDelay() {
    // delay drawing the first star for 0..40 seconds.
    return (Math.random() * 40000); 
  } /* getAnimationDelay */
  

  /***************/
  /* renderFrame */
  /********************************************************************/
  /* The star animator will 'animate' exactly one star at a time.     */
  /* It will select a star depending upon the modules of the input    */
  /* (frameNumber.)  To show multiple stars at-a-time, declare        */
  /* multiple RoadSignStarAnimators.                                  */
  /* @param frameNumber is the 0-based sequential frame number.       */
  /*                    Frame #0 is the first, initialization frame.  */
  /********************************************************************/
  renderFrame(frameNumber) {
    // If this is the first frame, then perform all initialization.
    // If the animator has been stopped, request a dismount.
    if (0 == frameNumber) 
      return (<div></div>); // no init at this time.
    if (!this.isAnimating()) 
      return null;
    
    // the timer is requesting a star frame:
    //   1) randomly pick a star and build it. 
    //   2) generate the JSX to display it.
    var whichStar = Math.floor(Math.random() * RoadSignStarAnimator_Constants.starPositions.length);
    var starStats = {
      position : 'absolute',
      top      : (RoadSignStarAnimator_Constants.starPositions[whichStar].y),
      left     : (RoadSignStarAnimator_Constants.starPositions[whichStar].x),
      width    : (RoadSignStarAnimator_Constants.starPositions[whichStar].w),
      height   : (RoadSignStarAnimator_Constants.starPositions[whichStar].w),
      zIndex   : RoadSign_Constants.depth.star,
    }; /* starStats */
    
    // now, return the star:
    return (
      <img src={roadSignStar} style={starStats} alt="Road sign star"/>
    ); /*return*/
  }; /* renderFrame */
  

  /**************/
  /* Life Cycle */
  /********************************************************************/
  /* componentDidMount - register as a listener to when the conduit   */
  /*                     is instructed to start animations.           */
  /********************************************************************/
  componentDidMount() {
    this.getConduit().register(this);
  }; /* componentDidMount */
  
  
  /********/
  /* CTOR */
  /********************************************************************/
  /* Do everything necessary to ensure that this                      */
  /* RoadSignTargetAnimator is ready to animate.                      */
  /* @param props is component properties.                            */
  /********************************************************************/
  constructor(props) {
    super(props);
  }; /* CTOR */

};  /* RoadSignStarAnimator */


