/************************************/
/* RedWingTradingPostLibraryCard.js */
/**********************************************************************************************/
/* Holds all information about a single image from the Red Wing Trading Post Library.         */  
/*                                                                                            */    
/* Copyright (C) 2020 Orcadia Labs LLC                                                        */   
/**********************************************************************************************/
import {AllLibraryCollectionName} from './RedWingTradingPostLibrary.js';


/************************/
/* RWTP_RealImagePrefix */
/**********************************************************************************************/
/* This string represents the !relative! location of the image library stack.                 */
/* A RedWingTradingPostLibraryCard object contains and maintains all images of the Red Wing   */
/* Trading Post image library.                                                                */ 
/* This library card represents a point-in-time information about the image.  Therefore, it   */
/* has no set()ters, only the constructor.                                                    */
/**********************************************************************************************/
/**********************************************************************/
const RWTP_RealImagePrefix = "https://www.redwingtradingpost.com/library/stacks/";


/********************************************/
/* RedWingTradingPostLibraryCard_FileNumber */
/**********************************************************************************************/
/* This value is a running enumeration used to calculate the file #.                          */
/**********************************************************************************************/
let RedWingTradingPostLibraryCard_FileNumber = 1;


/*********************************/
/* RedWingTradingPostLibraryCard */
/**********************************************************************************************/
/* A RedWingTradingPostLibraryCard object contains and maintains all images of the Red Wing   */
/* Trading Post image library.                                                                */ 
/* This library card represents a point-in-time information about the image.  Therefore, it   */
/* has no set()ters, only the constructor.                                                    */
/**********************************************************************************************/
export class RedWingTradingPostLibraryCard {

  /*******************/
  /* getImageWidth  */
  /********************************************************************************************/
  /* @return width (in pixels) of this Red Wing Trading Post image.                           */
  /********************************************************************************************/
  getImageWidth() {
    return this.imageData.image.width;
  }; /* getImageWidth */

  
  /******************/
  /* getImageHeight */
  /********************************************************************************************/
  /* @return height (in pixels) of this Red Wing Trading Post image.                          */
  /********************************************************************************************/
  getImageHeight() {
    return this.imageData.image.height;
  }; /* getImageHeight */
  

  /*******************/
  /* getImageQuality */
  /********************************************************************************************/
  /* @return quality   (1..10) of this Red Wing Trading Post image.                           */
  /*         undefined if quality of this image is unknown.                                   */
  /********************************************************************************************/
  getImageQuality() {
    return this.imageData.image.quality;
  }; /* getImageQuality */
  

  /*******************/
  /* getImageAddress */
  /********************************************************************************************/
  /* This method computes the actual address of the single image represented by this          */
  /* RedWingTradingPostLibraryCard.  It will compute this address once; the image will remain */
  /* at this address.                                                                         */
  /* @return the address (URL) if the image, itself or undefined if the URL is not known.     */
  /********************************************************************************************/
  getImageAddress() {
    if (!this.realImageAddress) {
      this.realImageAddress = RWTP_RealImagePrefix + this.imageData.image.filename;
    } /*if*/
    
    return this.realImageAddress;
  }; /* getImageAddress */
  

  /******************/
  /* getObjectWidth */
  /********************************************************************************************/
  /* @return width   (in cm) of the object represented by this Red Wing Trading Post image.   */
  /*         unknown if the width of this object is not known.                                */
  /*                                                                                          */
  /* The return value (width) may be undefined if the width of the actual object is not known.*/
  /********************************************************************************************/
  getObjectWidth() {
    return this.imageData.object.widthCM;
  }; /* getObjectWidth */
  

  /*******************/
  /* getObjectHeight */
  /********************************************************************************************/
  /* @return height (in cm) of the object represented by this Red Wing Trading Post image.    */
  /*                Since only the width of this object is supplied, this method will compute */
  /*                the height.                                                               */
  /*                                                                                          */
  /* The return value (height) may be undefined if one or more of the following conditions    */
  /* are met.  It will return a valid (height)                                                */
  /* iff none of these conditions are met.                                                    */
  /*   (1) the object width represented by this image is undefined.                           */
  /*   (2) the image width is undefined or zero.                                              */
  /*   (3) the image height is undefined or zero.                                             */
  /********************************************************************************************/
  getObjectHeight() {
    var objectWidth = this.getObjectWidth(); 
    if (!objectWidth) return undefined;
    
    var width  = this.getImageWidth ();
    var height = this.getImageHeight();
    if (!width || !height) return undefined;
    
    return ((objectWidth * height) / width);
  }; /* getObjectWidth */
  

  /***********/
  /* getName */
  /********************************************************************************************/
  /* @return name of this image which can be used throughout the Red Wing Trading Post Image  */
  /*              Library.                                                                    */
  /********************************************************************************************/
  getName() {
    return this.imageData.name;
  }; /* getName */
  

  /*****************/
  /* getFileNumber */
  /********************************************************************************************/
  /* @return the unique file number of this RedWingTradingPostLibraryCard object.             */
  /********************************************************************************************/
  getFileNumber() {
    return (this.libraryCardFileNumber);
  }; /* getFileNumber */
  
  
  /************/
  /* setImage */
  /********************************************************************************************/
  /* Accept and store the specific image data for this library card.                          */
  /* Specific image data is represented by an image blob as fetch()ed off the orcadialabs.com */
  /* server.                                                                                  */
  /* @param imageBlob is the fetched image blob.                                              */
  /*                  Supply a value of null to indicate that this (InAnyEvent_LibraryCard)   */
  /*                  contains no image blob.                                                 */
  /*                  The default value is null -- no image blob.                             */
  /********************************************************************************************/
  setImage(imageBlob = null) {
    this.imageBlob = imageBlob;
  }; /* setImage */
  
  
  /************/
  /* getImage */
  /********************************************************************************************/
  /* Provide access to the specific image data held by this library card.                     */
  /* @return the image blob for this library card.                                            */
  /********************************************************************************************/
  getImage(imageBlob = null) {
    return (this.imageBlob);
  }; /* getImage */
  
  
  /********/
  /* CTOR */
  /********************************************************************************************/
  /* Construct this RedWingTradingPostLibraryCard given information ONLY from the Red Wing    */
  /* Trading Post Library.  This RedWingTradingPostLibraryCard WILL represent an extant       */
  /* object from the Red Wing Trading Post library.  It will never not.                       */
  /*                                                                                          */
  /* Since only the RedWingTradingPostLibrarian is the only class accessing or creating       */
  /* RedWingTradingPostLibraryCard's ctor will never test nor verify it.                      */
  /*                                                                                          */
  /* Because it is passed-by-reference, this class will maintain its own deep copy of         */
  /* (imageData).  The calling method, therefore, need not be concerned with its integrity or */
  /* safety from change.                                                                      */
  /*                                                                                          */
  /* @param imageData is a RedWingTradingPostLibrary information record.  This class will not */
  /*                  verify that (imageData) is supplied nor if it is even valid.            */
  /********************************************************************************************/
  constructor(imageData) {

    // build and return copy of (imageData)
    var buildDeepCopy = () => {
      var copy = Object.assign({}, imageData);
      
      if (imageData.image) {
        copy.image = Object.assign({}, imageData.image);
      } /*if*/
      
      if (imageData.object) {
        copy.object = Object.assign({}, imageData.object);
      } /*if*/
      
      return copy;
    }; /* buildDeepCopy */
    
    this.imageData        = buildDeepCopy();
    this.realImageAddress = undefined;
    this.libraryCardFileNumber   = "Card " + (++RedWingTradingPostLibraryCard_FileNumber);
    
    // finally, clear image data:
    this.setImage();
  }; /* CTOR */
};  /* RedWingTradingPostLibraryCard */

