/****************/
/* constants.js */
/**********************************************************************/
/* Constant values for use by Red Wing Trading Post source.           */
/*                                                                    */    
/* Copyright (C) 2020 Orcadia Labs LLC                                */   
/**********************************************************************/

export let RWTP_Constants = {
  /************/
  /* general: */
  /*************/
  titleRWTP                 : 'Red Wing Trading Post',
  
  /**********/
  /* image: */
  /**********/
  image : {
    longestEdge             : 250,                       // all images are 250 pixels on longest axis.
  }, /* image */
  
  /*************/
  /* property: */
  /*************/
  property : {
    property_imageName      : 'imageName',
    property_collectionName : 'imageCollection',
  }, /* property */

}; /* RWTP_Constants */