/***************/
/* RoadSign.js */
/**********************************************************************************************/
/* This page presents the Red Wing Trading Post "road sign" animation                         */   
/*                                                                                            */
/* Various styles for <InOutGoImage> contain colors that rely upon those used in the potter   */
/* image.  These colors are:                                                                  */
/*   #cbc3b3 "Unique Americana" words                                                         */
/*   #3b3d37 words border                                                                     */
/*                                                                                            */
/*   The following colors appear throughout the potter image, they are all some form of near- */
/*   grey.  These colors may be used as need, they all complement the image.                  */
/*   I arranged these colors from lightest to darkest                                         */
/*     #e6e8e5                                                                                */
/*     #dfe1dc                                                                                */
/*     #deded5                                                                                */
/*     #d1d5cf                                                                                */
/*     #d1d3ce                                                                                */
/*     #cbcac5                                                                                */
/*     #898e87                                                                                */
/*     #878d85                                                                                */
/*     #676a61                                                                                */
/*     #64675f                                                                                */
/*     #5f625c                                                                                */
/*     #585b5e                                                                                */
/*     #474b4f                                                                                */
/*     #2b3025                                                                                */
/*     #12120e                                                                                */
/*     #070b03                                                                                */
/*                                                                                            */     
/* Copyright (C) 2019 - 2020 Orcadia Labs LLC                                                 */   
/**********************************************************************************************/
import React  from 'react'; 
import '../App.css';
import {RoadSignAnimationTimer,
        RoadSignAnimationConduit} from './animators/RoadSignAnimationTimer.js'    ;
import {RoadSign_Constants      } from './RoadSignConstants.js'                   ; 
import {OrcadiaLabsJsxIF        } from '../components/OrcadiaLabs_JSXTrix.js'     ;

// all images:
import OrcadiaLabs_Brand          from '../assets/images/orcadialabs_brand.png'   ;
import roadsignF                  from './images/roadsign-fg-vsmall.png'          ;
import roadsignB                  from './images/roadsign-bg-vsmall.png'          ;
import roadsignMenuO              from './images/menusign/menusign-words-o.png'   ; 
import roadsignMenuF              from './images/menusign/menusign-words-rwtp.png';
import roadsignMenuB              from './images/menusign/menusign.png'           ; 
import roadsignIngo               from '../assets/images/ingo.png'                ; 
import roadsignIngoA              from '../assets/images/ingoA.png'               ; 
import roadsignIngoASubtitle      from '../assets/images/ingoA.subtitle.png'      ;
import roadsignIngoB              from '../assets/images/ingoB.png'               ; 
import roadsignOutgoA             from '../assets/images/outgoA.png'              ; 
import roadsignOutgoB             from '../assets/images/outgoB.png'              ; 
import roadSignXit                from '../assets/images/xit.png'                 ;

// all animators:
import {RoadSignTargetAnimator }  from './animators/RoadSignTargetAnimator.js'    ;
import {RoadSignStarAnimator   }  from './animators/RoadSignStarAnimator.js'      ;
import {RoadSignForthAnimator  }  from './animators/RoadSignForthAnimator.js'     ;
import {RoadSignShimmerAnimator}  from './animators/RoadSignShimmerAnimator.js'   ;
import {RoadSignHeartAnimator  }  from './animators/RoadSignHeartAnimator.js'     ;


/*************************/
/* Ingo and Outgo images */
/**********************************************************************************************/
/* Roadsign supports two images (A,B) for both of two screenwidths.                           */                           
/**********************************************************************************************/
let RoadsignInoutgoA    = null;
let RoadsignInoutgoB    = null;
let RoadsignTextDialog  = null;
let SetRoadsignInoutgoA = (inoutgo   ) => { RoadsignInoutgoA   = inoutgo   ; };
let SetRoadsignInoutgoB = (inoutgo   ) => { RoadsignInoutgoB   = inoutgo   ; };
let SetRoadsignText     = (textDialog) => { RoadsignTextDialog = textDialog; };
let GetRoadsignInoutgoA = (          ) => { return (RoadsignInoutgoA  )    ; };
let GetRoadsignInoutgoB = (          ) => { return (RoadsignInoutgoB  )    ; };
let GetRoadsignText     = (          ) => { return (RoadsignTextDialog)    ; };


/******************/
/* InOutGoTextBox */
/**********************************************************************************************/
/* An <InOutGoTextBox> is a component that will display the text dialog element.              */
/**********************************************************************************************/
class InOutGoTextBox extends React.Component {

  
  /***********/
  /* display */
  /********************************************************************************************/
  /* Declare whether to display this (InOutGoTextBox).                                        */
  /* @param displayTextBox should be true to display the box or false if not.                 */
  /********************************************************************************************/
  display(displayTextBox) {
    this.setState({display : (displayTextBox)});
  }; /* display */
  
  
  /**********/
  /* toggle */
  /********************************************************************************************/
  /* Toggle whether to display this (InOutGoTextBox).                                         */
  /********************************************************************************************/
  toggle() {
    this.display(!this.state.display);
  }; /* toggle */
  
  
  /**********/
  /* render */
  /********************************************************************************************/
  /* Draw the InOutGo text box if the state of this (InOutGoTextBox) indicates to do so.      */
  /********************************************************************************************/
  render() {
    let goTextStyles = {
      goText : {
             textAlign       : 'left',
             backgroundColor : '#e6e8e5',   // potter image whitish
             borderStyle     : 'solid',
             borderWidth     : 4,
             borderColor     : '#676a61',   // potter image blackish
             padding         : 8,
             color           : '#070b03',
      }, /* goText */
      goXit : { 
             float             : 'right',
             width             : 48,
             height            : 48,
             marginLeft        :  8,       // used to keep text away from the button
             marginBottom      :  8,       // the text box already applies margin to the top and right
      }, /* goXit */
  
    }; /* goTextStyles */
    
    
    let InOutGoTextBoxScript = "" 
          + "During the last Ice Age, glaciers left large amounts of clay deposts as they receded.  "
          + "One of these large deposits is near the town of Red Wing, Minnesota.  "
          + "Starting in 1872, Red Wing Stoneware Company turned this clay into stoneware jars, churns, jugs and other products a growing naton needed.  "
          + "After decades of production and as the nation changed, this and more companies from Red Wing gradually converted to producing dinnerware, household, and decorative items.  "
          + "All of these products are prized and are eagerly collected today."
        ;
    // render this text box
    return (
      <div id  = {"roadsign-inoutgoA-message"}>
        <OrcadiaLabsJsxIF if={this.state.display}>
          <img id={"roadsign-inoutgoA-message-thumbnail"} src={roadsignIngoASubtitle} 
               alt='Red Wing Informational Image' />
          <div id="roadsign-inoutgoA-message-textbox">
            <div style={goTextStyles.goText}>
              <img style={goTextStyles.goXit} src={roadSignXit}/> 
              <p>{InOutGoTextBoxScript}</p>
            </div>
          </div>
        </OrcadiaLabsJsxIF>
      </div>
    ); /*return*/
  }; /* render */
  

  /********/
  /* CTOR */
  /********************************************************************************************/
  /* Simple Constructor.  No action.                                                          */
  /********************************************************************************************/
  constructor(props) {
    super(props);
    this.state = {display : (false)};
  }; /* CTOR */
  
}; /* InOutGoTextBox */


/****************/
/* InOutGoImage */
/**********************************************************************************************/
/* An <InOutGoImage> is a component that will display one of two images, an image for         */
/* initialization and an image for transition.                                                */
/**********************************************************************************************/
class InOutGoImage extends React.Component {

  /**********/
  /* render */
  /********************************************************************************************/
  /* Build an image with the source attribute as saved in state.                              */
  /********************************************************************************************/
  render() {
    // styles for the InOutGo image
    var inOutGoStyles = {
      zIndex : RoadSign_Constants.depth.inOutGo,
    }; /* inOutGoStyles */
    
    return (
      <img id={this.props.id} src={this.state.imgSrc} 
           style={inOutGoStyles} alt='Red Wing Informational Image' />
    ); /*return*/
  }; /* render */

  
  /***************/
  /* changeImage */
  /********************************************************************************************/
  /* Cause this <InOutGoImage> to use the second image source and rerender itself.            */
  /********************************************************************************************/
  changeImage() {
    this.setState({imgSrc : (this.props.postSrc)});
  }; /* changeImage */
  
  
  /********/
  /* CTOR */
  /********************************************************************************************/
  /* Simple Constructor.  No action.                                                          */
  /********************************************************************************************/
  constructor(props) {
    super(props);
    this.state = {imgSrc : (this.props.preSrc)};
  }; /* CTOR */
  
}; /* InOutGoImage */


/************/
/* RoadSign */
/**********************************************************************************************/
/* Defines the Red Wing Trading Post Road Sign and animations.                                */ 
/* 20.10.23.1333 - Note that the #roadsign is declared twice.  The first one is the non-      */
/*                 clipped version so that its constituent menusign can be rendered below the */
/*                 roadsign on very narrow screens (phones.)  The second's purpose is to      */
/*                 the menusign in the upper-left corner of the Road Sign screen.             */
/**********************************************************************************************/
export class RoadSign extends React.Component { 
  
  render() {
  
    let roadSignStyles = {
      fore : {
             position : 'absolute',
             zIndex   : RoadSign_Constants.depth.roadSign.fore,
      }, /* fore */
      back : {
             zIndex   : RoadSign_Constants.depth.roadSign.back,
      }, /* back */
    }; /* roadSignStyles */

// Issue #018
// Copy of the Forth Animator installation to reinsert after this issue is completed:
//            <RoadSignForthAnimator   animationConduit = {(this.getForthConduit  ())}/>

    return (
      <div id="roadsign-page">
        <div id="roadsign-container">
          <div id='roadsign-menuContainer'>
             <div id='roadsign-menu' onClick={()=>{this.clickMenuSign()}}>
                <img id  = 'roadsign-menu-b' className="roadsign-menu-image"  src={roadsignMenuB} alt="Red Wing Road Sign Menu"/>
                <img id  = 'roadsign-menu-f' className="roadsign-menu-image"  src={roadsignMenuF} alt="Red Wing Road Sign Menu"/>
                <img id  = 'roadsign-menu-o' className="roadsign-menu-image"  src={roadsignMenuO} alt="Red Wing Road Sign Menu"/>
                <RoadSignTargetAnimator animationConduit = {(this.getTargetConduit())}/>
             </div>
          </div>

          <div id="roadsign">
            <img id='roadsign-landing-bg-b' className="roadsign-image" style={roadSignStyles.back} src={roadsignB    } alt='Red Wing Road Sign' />
            <RoadSignShimmerAnimator animationConduit = {(this.getShimmerConduit())}/>
            <RoadSignForthAnimator   animationConduit = {(this.getForthConduit1 ())}/>
            <img id='roadsign-landing-bg-f' className="roadsign-image" style={roadSignStyles.fore} src={roadsignF    } alt='Red Wing Road Sign' />

        
            <RoadSignStarAnimator  animationConduit = {(this.getStarConduit1())}/>
            <RoadSignStarAnimator  animationConduit = {(this.getStarConduit2())}/>
            <a href="https://www.orcadialabs.com" target="_blank" rel="noopener noreferrer">
              <img id='orcadialabs-brand' className='roadsign-brand' src={OrcadiaLabs_Brand} alt='Orcadia Labs, LLC'/>
            </a>          
          </div>
          <div id='inoutgo-image' onClick = {() => {this.clickedButtonA()}}>
            <InOutGoImage id        = {"roadsign-inoutgoA"}
                          preSrc    = {roadsignIngoA} 
                          postSrc   = {roadsignOutgoA} 
                          ref       = {(ingoA) => {SetRoadsignInoutgoA(ingoA);}}
                          />
            <InOutGoTextBox ref = {(textbox) => {SetRoadsignText(textbox);}}/>
          </div>
          
          <div id='inoutgo-image' onClick = {() => {this.clickedButtonB()}}>
            <InOutGoImage id        = {"roadsign-inoutgoB"}
                          preSrc    = {roadsignIngoB} 
                          postSrc   = {roadsignOutgoB} 
                          ref       = {(ingoB) => {SetRoadsignInoutgoB(ingoB);}}/>
          </div>
        </div>
      </div>
    ); /* return */   

  }; /* render */
  
  
  /*******************/
  /* startAnimations */
  /********************************************************************************************/
  /* The Road Sign Target animation started upon rendering the road sign.  This method will   */
  /* facilitate the transation between this target animation and the set of all other road    */
  /* sign animations.                                                                         */
  /********************************************************************************************/
  startAnimations() {
    // stop the target animation, it is no longer purposeful: 
    this.getTargetConduit().stopAnimating();

    // substitute two outgo images for the two ingo images
    GetRoadsignInoutgoA().changeImage();
    GetRoadsignInoutgoB().changeImage();
    
    // start all other animators:
    let animators = this.getAllConduits();
    for (var animus = 0; animus < animators.length; ++animus) {
      animators[animus].startAnimating();
    } /*for*/
  }; /* startAnimations */


  /**********************/
  /* setPerformedAction */
  /* isPerformedAction  */
  /********************************************************************************************/
  /* Action states whether the Road Sign is "in action."  Action is defined as whether        */
  /* animations have started.  This state is boolean meaning that, when animations are        */
  /* started, the  (InOutGoTextBox) will be hidden.                                           */
  /*                                                                                          */
  /* Establish whether action has happened.                                                   */
  /* @param action is a boolean, whether action has happened.                                 */
  /*               by defauct, set that action has not yet been performed.                    */
  /*                                                                                          */
  /* Inquire whether action has happened.                                                     */
  /* @return       whether action has happened.                                               */
  /********************************************************************************************/
  setPerformedAction(action = false) {
    this.state = {actionPerformed : (action)};
//    this.whetherActionPerformed = action;
  }; /* setPerformedAction */
  
  isPerformedAction() {
    return ((this.state) && (this.state.actionPerformed));
//    return (this.whetherActionPerformed);
  }; /* isPerformedAction */

  
  /*******************/
  /* clickedMenuSign */
  /********************************************************************************************/
  /* Clicking the menusign starts the animations if they had not already been.                */
  /* Once only.                                                                               */
  /* Also, since the first click involves changing both buttons A and B, turn off the text    */
  /* dialog so that we can see button A in all cases and on all formats.                      */
  /********************************************************************************************/
  clickMenuSign() {
    if (!this.isPerformedAction()) {
      this.setPerformedAction(true);
      this.startAnimations();
      GetRoadsignText().display(false);
    } /*if*/
  }; /* clickMenuSign */
  
  
  /******************/
  /* clickedButtonA */
  /********************************************************************************************/
  /* Action A is what to do when the "A" InOutGo image is clicked.  Action varies.  The first */
  /* through Nth time it is clicked, show the (InOutGoTextBox).  The (N + 1)th time, proceed  */
  /* to the redwingtradingpost.com website.                                                   */
  /********************************************************************************************/
  clickedButtonA() {
    // on the first click, show the "Unique Americana" text dialog
    if (!this.isPerformedAction()) {
      GetRoadsignText().toggle();
    } /*if*/
    
    // on the not-the-first click, proceed to the redwingtradingpost.com website
    // turn off the animations
    else {
      GetRoadsignText().display(false);
      this.setPerformedAction(true);
      window.location.href = ("https://www.redwingtradingpost.com");
    } /*else*/
  }; /* clickedButtonA */
  
  
  /******************/
  /* clickedButtonB */
  /********************************************************************************************/
  /* Action A is what to do when the "A" InOutGo image is clicked.  Action varies.  The first */
  /* through Nth time it is clicked, show the (InOutGoTextBox).  The (N + 1)th time, proceed  */
  /* to the redwingtradingpost.com website.                                                   */
  /********************************************************************************************/
  clickedButtonB() {
    // on the first click, hide the "Unique Americana" text dialog
    if (!this.isPerformedAction()) {
      GetRoadsignText().display(false);
      this.setPerformedAction(true);
      this.startAnimations();
    } /*if*/
    
    // on the not-the-first click, proceed to the redwingtradingpost.com website
    else {
      window.location.href = ("https://erwcs.org");
    } /*else*/
  }; /* clickedButtonB */
  
  
  /******************/
  /* createConduits */
  /* get*Conduit    */
  /********************************************************************************************/
  /* Establish one communication channel for each known animator.                             */
  /* Also include a facility for returning each specific one.                                 */
  /*                                                                                          */
  /* getAllConduits() will return an array of all known conduits                              */
  /*                  EXCEPT for the target animator.  The target animation is handles        */
  /*                  separately and uniquely.                                                */
  /********************************************************************************************/
  createConduits() {
    this.forthConduit1  = new RoadSignAnimationConduit();
    this.forthConduit2  = new RoadSignAnimationConduit();
    this.heartConduit   = new RoadSignAnimationConduit();
    this.shimmerConduit = new RoadSignAnimationConduit();
    this.starConduit1   = new RoadSignAnimationConduit();
    this.starConduit2   = new RoadSignAnimationConduit();
    this.targetConduit  = new RoadSignAnimationConduit();
    this.titleConduit   = new RoadSignAnimationConduit();
  }; /* createConduits */
  
  getForthConduit1 () { return this.forthConduit1       ;};
  getForthConduit2 () { return this.forthConduit2       ;};
  getHeartConduit  () { return this.heartConduit        ;};
  getShimmerConduit() { return this.shimmerConduit      ;};
  getStarConduit1  () { return this.starConduit1        ;};
  getStarConduit2  () { return this.starConduit2        ;};
  getTargetConduit () { return this.targetConduit       ;};
  getTitleConduit  () { return this.titleConduit        ;};
  getAllConduits   () { return ([
                                this.getForthConduit1(),
                                this.getForthConduit2(),
                                this.getShimmerConduit(),
                                this.getStarConduit1(),
                                this.getStarConduit2(),
//                                 this.getTitleConduit()
                               ])                       ;};
  

  /**************/
  /* Life Cycle */
  /********************************************************************************************/
  /* componentDidMount - start target animation.                                              */
  /********************************************************************************************/
  componentDidMount() {
  }; /* componentDidMount */
  
  
  /********/
  /* CTOR */
  /********************************************************************************************/
  /* placeholder                                                                              */
  /********************************************************************************************/
  constructor(props) {
    super(props);
    this.createConduits();    
    this.setPerformedAction();
  }; /* CTOR */
  
}; /* RoadSign */







