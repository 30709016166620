/************/
/* RWTP_404 */
/**********************************************************************************************/
/* For routing to an unknown or unsupported route.                                            */
/*                                                                                            */    
/* Copyright (C) 2020 Orcadia Labs LLC                                                        */   
/**********************************************************************************************/
// React boilerplate
import React from 'react';
import {Text} from 'react';
import {RedWingTradingPostImage}  from './library/RedWingTradingPostImage.js'              ;
import {RWTP_Constants}           from './constants.js'                                    ;

// MenuSign imports (this stuff gets replaced by a <MEnuSign> component)
import roadsignMenuO              from './roadsign/images/menusign/menusign-words-o.png'   ; 
import roadsignMenuF              from './roadsign/images/menusign/menusign-words-rwtp.png';
import roadsignMenuB              from './roadsign/images/menusign/menusign.png'           ; 
import roadsignMenuAll            from './roadsign/images/menusign/menusign-all.png'       ; 
import roadsignImageIcon          from './roadsign/images/roadsign-icon.png'               ; 


/******************
/* RWTP_404Const */
/**********************************************************************************************/
/**********************************************************************************************/
let RWTP_404Const = {
  menuSign : {
    offsetFromTop   : - 75,         /* how far from top should the 404 page start? */
    width           :  256,         /* scaled width of the menusign image          */
    height          :  235,         /* scaled height of the menusign image         */
  }, /* menuSign */

  image : {                         /* an interjected image                        */
    width           : 256,          /* the natural size of one of the stack images */
    maxWidth        : '40%',        /* on a phone, two images may be side-by-side  */
  }, /* image */
  body : {
    leftRightMargin :   16,         /* comfortable space on left, right sides      */
  }, /* body */
}; /* RWTP_404Const */


/************/
/* RWTP_404 */
/**********************************************************************************************/
/* Defines the Red Wing Trading Post Road Sign and animations.                                */ 
/**********************************************************************************************/
export class RWTP_404 extends React.Component { 
  
  render() {
    
    // p404_styles contains all styling for the 404 page
    var p404_styles = {
          content : {
            display        : 'flex',
            justifyContent : 'space-around',
            flexWrap       : 'wrap',
            padding        :16,
            marginTop      : (RWTP_404Const.menuSign.height + RWTP_404Const.menuSign.offsetFromTop),
          }, /* content */
          block : {
            maxWidth       :  (RWTP_Constants.image.longestEdge + 24)
          }, /* block */
          menusign : {
            position   : 'absolute',
            top        : (RWTP_404Const.menuSign.offsetFromTop),
            left       : (RWTP_404Const.body.leftRightMargin * 2),
            width      : (RWTP_404Const.menuSign.width),
            maxWidth   : "75pw",
            height     : (RWTP_404Const.menuSign.height),
          }, /* menusign */
          roadsignIcon : {
            width      : 250,
            height     : 174,
            margin     :  16,
          }, /* roadsignIcon */
          imageContainer : {
            width      : (RWTP_404Const.image.width),
            maxWidth   : (RWTP_404Const.image.width),
            height     : (RWTP_404Const.image.width),
          },
          emphasizedParagraph : {
            flex            : 1,
            color           : 'red',
            backgroundColor : 'transparent',
            maxWidth        : 256,
            minWidth        : 200,
          }, /* emphasizedParagraph */
          textParagraph : {
            flex            : 1,
            color           : '#000',
            backgroundColor : 'transparent',
            textAlign       : 'left',
            maxWidth        : 256,
            minWidth        : 200,
          }, /* textParagraph */
          emphasizedText : {
            color           : 'red',
            backgroundColor : 'transparent',
            fontSize        : 'larger',
          }, /* emphasizedText */
          background : {
            zIndex : -1,
          }, /* background */
        }; /* p404_styles */
  
  
    return ( 
      <>
        <div style={p404_styles.menusign}>
          <img id  = 'roadsign-menu-all' className="roadsign-menu-image"  src={roadsignMenuAll} alt="Red Wing Road Sign Menu"/>
        </div>

        <div style={p404_styles.content}>      

          <div style={p404_styles.block}>      
            <p>
              <span style={p404_styles.emphasizedText}>Red Wing Trading Post</span>
            </p>
            <p>
              does not support this destination.
            </p>
          </div>
            
          <RedWingTradingPostImage {...{[RWTP_Constants.property.property_imageName] : ('crock16')}} style={p404_styles.imageContainer}  alt="Red Wing Crock" /> 

          <div style={p404_styles.block}>
            <p>
              To discover more about Red Wing.  We are excited to offer the following destinations.  
            </p>
            <p>
              <span style={p404_styles.emphasizedText}>  Now open for exploration</span> are:
            </p>
          </div>
          
          <a href="/roadsign">
          <div style={p404_styles.block}>
            <img style={p404_styles.roadsignIcon} src={roadsignImageIcon} alt="Red Wing Road Sign" />
            <p>
              The Red Wing Trading Post Road Sign is a celebration of the stoneware and pottery companies of Red Wing Minnesota.  
            </p>
            <p>
              It presents a sample of the wide variety of exciting products made by these companies.
            </p>
          </div>
          </a>
          
          <RedWingTradingPostImage {...{[RWTP_Constants.property.property_imageName] : ('figure16'       )}} style={p404_styles.imageContainer}  alt="Red Wing Clock" /> 
          <RedWingTradingPostImage {...{[RWTP_Constants.property.property_imageName] : ('jug30'          )}} style={p404_styles.imageContainer}  alt="Red Wing Jug" /> 
          <RedWingTradingPostImage {...{[RWTP_Constants.property.property_imageName] : ('countrygarden04')}} style={p404_styles.imageContainer}  alt="Red Wing Country Garden Dinnerware" />
          <RedWingTradingPostImage {...{[RWTP_Constants.property.property_imageName] : ('cooler04'       )}} style={p404_styles.imageContainer}  alt="Red Wing Water Cooler" />
 
        </div>


        <div id="mystory-page" style={p404_styles.background}/>
      </>
    ); /*return*/
  } /* render */
  

  /********/
  /* CTOR */
  /********************************************************************************************/
  constructor(props) {
    super(props);
  } /* CTOR */
  
  
} /* RWTP_404 */
