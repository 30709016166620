/************/
/* My Story */
/**********************************************************************************************/
/* Entry point for my story.                                                                  */
/**********************************************************************************************/
import React  from 'react'; 
import '../App.css';
import {RedWingTradingPostLibrarian        } from '../library/RedWingTradingPostLibrarian.js';
import {RedWingTradingPostLibraryCard      } from '../library/RedWingTradingPostLibraryCard.js';
import {RedWingTradingPostLibraryCollection} from 
                                '../library/collections/RedWingTradingPostLibraryCollection.js';
import {OrcadiaLabsJsxIF                   } from '../components/OrcadiaLabs_JSXTrix.js';

// images:
import RWTPski                               from '../assets/images/RWTP_Oval_2b.png';
import RWTP_MyStory                          from './assets/images/mystory2.png';
import RWTP_MyStoryShadow                    from './assets/images/mystory-shadow.png';
import RWCSbrand                             from '../assets/images/RWCSbrand.png';
import OrcadiaLabs_Brand                     from '../assets/images/orcadialabs_brand.png';


/*************/
/* Productst */
/**********************************************************************************************/
/* Import all products from the Red Wing Trading Post library.                                */
/**********************************************************************************************/ 
// import saltGlazeJar from '../library/stacks/crock16.png';
let saltGlazeJar = RedWingTradingPostLibrarian.getCollection().getByName("crock16");


/****************/
/* MyStoryConst */
/**********************************************************************************************/
/* Constants for My Story.  Many consistent styles use these constants.                       */
/**********************************************************************************************/
const MyStoryConst = {
  chunkFactor           :   2,     // chunk-calculating factor, large numbers == more spacing
  cornerBlockSizePixels :  50,     // defines corner layouts for images
  blockWidth            : 300,
}; /* MyStoryConst */

// Split each corner into 'chunks' for sizing and overlap:
const chunkSize = (MyStoryConst.cornerBlockSizePixels / MyStoryConst.chunkFactor);


/*****************/
/* MyStoryStyles */
/**********************************************************************************************/
/* Styles specific for My Story.                                                              */
/**********************************************************************************************/
let MyStoryStyles = {
    headerStyles : {
      headerView : {               // the header view refers to the entire space for the title
        position    : 'fixed',
      }, /* headerView */
      headerImageTitle : {         // header title image styled specifically for page title
        position    : 'absolute',
        top         : 0,
        left        : 0,
        width       : '90%',
      }, /* headerImage */
      headerImage : {              // a header image fits within the (headerView)
        top         : 0,
        left        : 0,
        width       : '90%',
      }, /* headerImage */
      headerBrand : {              // a header image fits within the (headerView)
        position    : 'absolute',
        top         : 0,
        right       : 0,
        width       : '10%',
      }, /* headerImage */
      headerImageShadow : {        // the shadow image should be offset from header for shadow
        position    : 'fixed',
        top         : 24,
        left        : '20%',
        width       : '85%',
      }, /* headerImage */
    }, /* headerStyles */
      

  story : {
    display       : 'flex',
    flexDirection : 'row',
    flexWrap      : 'wrap',
    overFlowY     : 'scroll',
    
    // The outer story container must protect 1/2 of outer images by chunk:
    // A five-chunk product image extends 2.5 chunks beyond outer block.
    // Plus, add a little bit for aesthetic:
    // Plus, adjust on the right side for additional block padding:
    // paddingTop set in viewport width in the css
//    paddingTop        : (16 + (3 * chunkSize)),
    paddingRight      : (32 + (3 * chunkSize)),
    paddingBottom     : (32                  ),
    paddingLeft       : (16                  ),
  }, /* story */
  
   // a single My Story block - outer portion
  outerBlock : {
    padding         : (chunkSize ),
  }, /* block */
  
  // a single My Story block - middle portion
  block : {
    position        : 'relative',
    maxWidth        : MyStoryConst.blockWidth,
    padding         : (chunkSize  ),
    borderRadius    : 45, 
  }, /* block */

   // a single My Story block - inner portion
  innerBlock : {
    padding         : (chunkSize  ),
    justifyContent  : 'flex-start', 
    alignItems      : 'flex-start',
  }, /* block */
  
  // describing a product "After" the block in which it sits:
  redWingAfter : {
    position        : 'absolute',
    top             : 0,
  }, /* redWingAfter */
  
  // specifically for each text block:
  blurb : {
    marginTop  : 0,    // move the text to the top of its block
    marginLeft : 0,    // move the text to the left of its block
  }, /* blurb */
  
        
  figure : {
    display       : 'flex',
    flexDirection : 'column',
  }, /* figure */
  
  signoffStyles : {
    anchor : {
      zIndex : 500,
    }, /* anchor */
        
    rwtp : {
      padding :    50,
      width   :   200,
      
      // used to want to place the anchor links ABOVE the My Story title:
      // zIndex  : 10000,
    }, /* rwtp */
  }, /* signoffStyles */
}; /* MyStoryStyles */


/****************************/
/* MyJoyProductNameProperty */
/**********************************************************************************************/
/* The name of the Red Wing product managed by this (MyJoy) object.                           */
/**********************************************************************************************/
const MyJoyProductNameProperty = "MyJoyProductName";
  


/*********/
/* MyJoy */
/**********************************************************************************************/
/* MyJoy is the abstract representation of one Red Wing product.                              */
/* It will already contain all information required to render one Red Wing product by name.   */
/* Like the Red Wing Trading Post Readsign, the product image will be fit inside an square    */
/* box so that it may be rendered easily without distorting the image.                        */
/**********************************************************************************************/
class MyJoy extends React.Component {


  /***********/
  /* setCard */
  /********************************************************************************************/
  /* Establish the value of the (RedWingTradingPostLibraryCard) for this MyJoy.               */
  /* @param libraryCard is the library card.                                                  */
  /*        If not specified, the library card for this MyJob object will be cleared.         */
  /********************************************************************************************/
  setCard(libraryCard = null) {
    this.myJoyLibraryCard = libraryCard;
  }; /* setCard */

  
  /***********/
  /* getCard */
  /********************************************************************************************/
  /* Establish the value of the (RedWingTradingPostLibraryCard) for this MyJoy.               */ 
  /* @return the library card.                                                                */
  /********************************************************************************************/
  getCard() {
    return (this.myJoyLibraryCard ? this.myJoyLibraryCard : null);
  }; /* setCard */

  
  /**************/
  /* prepareJoy */
  /********************************************************************************************/
  /* Prepare all statistic required so that this (MyJoy) object can be used.                  */
  /* At the end of this method, this (MyJoy) will hld a (imageInBoxPercent) member.           */
  /********************************************************************************************/
  prepareJoy() {
  
    // locate the product within the calculated box:
    var imageWidth  = this.getCard().getImageWidth()
    var imageHeight = this.getCard().getImageHeight();
    this.imageInBoxPercent = {
          width            : 1,
          height           : 1,
          horizontalMargin : 0,
          verticalMargin   : 0,
        }; /* imageInBox */
    
    // calculate width and height of the image within its box
    if (imageWidth > imageHeight) {
      this.imageInBoxPercent.height           = (imageHeight / imageWidth               );
      this.imageInBoxPercent.verticalMargin   = ((1 - this.imageInBoxPercent.height) / 2); 
    } /*if*/
    else {
      this.imageInBoxPercent.width            = (imageWidth / imageHeight               );
      this.imageInBoxPercent.horizontalMargin = ((1 - this.imageInBoxPercent.width) / 2 );  
    } /*else*/
    
  }; /* prepareJoy */
  
  
  /**********/
  /* render */
  /********************************************************************************************/  
  /* Draw the image within its box.                                                           */ 
  /********************************************************************************************/
  render() {
    // calculate initial statistics for a square box:
    // the displacement puts the box accurately centered between all blocks.
    var boxSize         = (chunkSize * 6);                        // 3 margins + 1 padding
    
    //    var boxDisplacement = (0 - (boxSize /2) - (boxSize / 2));   // center box in corner0.
    var boxDisplacement = 0 - (boxSize / 2) - chunkSize;    

    // styles for box and product placement:
    // Note: all <MyJoy> objects will be rendered in front of all text boxes:
    var styles = {
      box : {
        position        : 'absolute',
        top             : (boxDisplacement),
        right           : (boxDisplacement),
        width           : (boxSize        ),
        height          : (boxSize        ),
        backgroundColor : 'transparent',
        zIndex          : 1000,
      },
      image : {
        position : 'absolute',
        top      : ('' + (100 * this.imageInBoxPercent.verticalMargin  ) + '%'),
        left     : ('' + (100 * this.imageInBoxPercent.horizontalMargin) + '%'),
        width    : ('' + (100 * this.imageInBoxPercent.width           ) + '%'),
        height   : ('' + (100 * this.imageInBoxPercent.height          ) + '%'),
      },
    };
    
    return (
      <div style={styles.box}>
        <img style={styles.image} src={this.getCard().getImageAddress()}/>
      </div>
    );
  }; /* render */

  
  /********/
  /* CTOR */
  /********************************************************************************************/
  /* A MyJoy object will already have all information required to render                      */
  /* @prop productName is the name of the Red Wing product managed by this MyJoy object.      */
  /*                   if a product name is not supplied, one will be picked randomly.        */
  /********************************************************************************************/
  constructor(props) {
    super(props);
    
    // prepare the product ready for use, select a random one if necessary:
    this.setCard();
    var productCard = RedWingTradingPostLibrarian.getCollection()
                                                   .getByName(props[MyJoyProductNameProperty]);
    if (!productCard) {
      productCard = RedWingTradingPostLibrarian.getCollection().pick();
    } /*if*/
  
    this.setCard(productCard);
    
    // With a library card in hand, set up all information:
    this.prepareJoy();
  }; /* CTOR */
}; /* MyJoy */


/****************/
/* MyStoryBlock */
/**********************************************************************************************/
/* Shorthand hook to generate one My Story block.                                             */
/* @param props is the properties for this (MyStoryBlock).                                    */
/* @prop  (MyStoryProp_ProductName) the name of the product.  This name must be included      */
/*                                  in the (RedWingTradingPostLibrary).                       */
/*                                  The default value is (null) do not display a product.     */
/* @prop  (MyStoryProp_Text)        the text to display in the My Story block.                */
/*                                  The default value is (null) do not display text (empty.)  */
/**********************************************************************************************/
const MyStoryProp_ProductName = "myStory_product";
const MyStoryProp_Text        = "myStory_text";

let MyStoryBlock = (props) => {
  // ensure this hook has usable/accessable property values:
  var blockProductName = ((props && props[MyStoryProp_ProductName])
                          ? props[MyStoryProp_ProductName]
                          : null);
  var blockText        = ((props && props[MyStoryProp_Text])
                          ? props[MyStoryProp_Text]
                          : ' ');

  return (
    <div style={MyStoryStyles.outerBlock}>
      <div style={MyStoryStyles.block} className="mystory-block">
        <div style={MyStoryStyles.innerBlock}>
          <OrcadiaLabsJsxIF if={blockProductName}>
            <MyJoy {...{[MyJoyProductNameProperty] : (blockProductName)}} />
          </OrcadiaLabsJsxIF>
          <p style={MyStoryStyles.blurb} className='text-common'> 
            {blockText}
          </p>
        </div>
      </div>
    </div>
  ); /*return*/
  
}; /* MyStoryBlock */


/***********/
/* MyStory */
/**********************************************************************************************/
export class MyStory extends React.Component {


  /**********/
  /* render */
  /********************************************************************************************/ 
  /* Draw "My Story "                                                                         */
  /********************************************************************************************/
  render() {
    
    return (
      <>
        <div id="mystory-page"></div>
        
        <div id="mystory-headerBlock" style={MyStoryStyles.headerStyles.headerView}>
          <img style={MyStoryStyles.headerStyles.headerImageTitle} src = {RWTP_MyStory}/>
          <img style={MyStoryStyles.headerStyles.headerBrand} src = {OrcadiaLabs_Brand}/>
        </div>
          
        <div style={MyStoryStyles.story}>
          <div style={{width:'100%'}}>
            <div id="mystory-headerBlock">
              <img style={MyStoryStyles.headerStyles.headerImage      } src = {RWTP_MyStory}/>
            </div>
          </div>
          
          <MyStoryBlock {...{[MyStoryProp_ProductName] : (''                ), [MyStoryProp_Text] : "I grew up on a farm.  With my Grandpa, we worked with the land, we lived on the land and we loved the land."  }} />
          <MyStoryBlock {...{[MyStoryProp_ProductName] : (''                ), [MyStoryProp_Text] : "The land is in my blood."  }} />
          <MyStoryBlock {...{[MyStoryProp_ProductName] : (''                ), [MyStoryProp_Text] : "After moving to southeast Minnesota, my family and I found a small farm but the previous owner didn't have any tools or supplies."  }} />
          <MyStoryBlock {...{[MyStoryProp_ProductName] : (''                ), [MyStoryProp_Text] : "We went to a lot of small farm auctions and found forks and shovels and most of the tools we needed."  }} />
          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('crock16'         ), [MyStoryProp_Text] : "We also saw some old stoneware jars used for storage or pickling or for just about anything we needed around the farmhouse."  }} />
          
          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('crock14'         ), [MyStoryProp_Text] : "Then we noticed that many of these jars were decorated with a red stamp, a red stamp of a wing."  }} />
          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('RWTPRedWing'     ), [MyStoryProp_Text] : "Curious, we discovered that this red wing stamp marked stoneware made in a small town nearby, Red Wing, Minnesota."  }} />
          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('crock19'         ), [MyStoryProp_Text] : " We noticed more jars, some even with different ink stamps, at auctions and picked up a few more."  }} />
          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('beanpot02'       ), [MyStoryProp_Text] : "Some pieces even had advertising from the local area and from far beyond.  Without realizing it, we were collecting."  }} />
          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('RWCSbrand'       ), [MyStoryProp_Text] : "Then we met more collectors and we even joined a group of people who collect Red Wing."  }} />
          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('rayreiss01'      ), [MyStoryProp_Text] : "We found books about collecting Red Wing.  Hungry for more information, I consumed them voraciously."  }} />
          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('butter11'        ), [MyStoryProp_Text] : "The array of products made In Red Wing amazed us."  }} />
          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('countrygarden03' ), [MyStoryProp_Text] : "Red Wing made not only crocks, churns and coolers, they then made dinnerware, household items, decorations and so much more."  }} />
          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('chromoline04'    ), [MyStoryProp_Text] : "We searched for and found all of them, crocks, churns, coolers, beanpots, vases, dinnerware and more.  We did not tire."  }} />
          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('chicken01'       ), [MyStoryProp_Text] : "I learned that I love Red Wing.  I met friends who also love Red Wing.  Millions of people love Red Wing, most of them just don't know it yet"  }} />
          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('greyline06'      ), [MyStoryProp_Text] : 'And so, I discovered my story.'  }} />
          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('buds02'          ), [MyStoryProp_Text] : 'My story is my quest to tell a story, the "Red Wing Story."'  }} />
          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('figure11'        ), [MyStoryProp_Text] : 'The Internet is the greatest promotional tool ever invented.  It reaches across the world.' }} />
          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('cooler04'        ), [MyStoryProp_Text] : 'My friends take better photos or write better copy...  but I know how to promote on the Internet.' }} />
          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('vase063'         ), [MyStoryProp_Text] : "I am not alone in my interest to tell this story.  Together, we can all tell this story.  It will reach across the world."  }} />
          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('jug31'           ), [MyStoryProp_Text] : 'Millions will hear it.'  }} />
          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('churn6'          ), [MyStoryProp_Text] : 'To discover more, the following destinations will guide the curious on an exhilarating and rewarding journey of discovery ...'  }} />
          
          <a style={MyStoryStyles.signoffStyles.anchor} href="http://erwcs.org"> 
            <div style={MyStoryStyles.figure}>
              <img  style={MyStoryStyles.signoffStyles.rwtp} src = {RWCSbrand}/>
              <p>Red Wing Collectors Society</p>
            </div>
          </a>

          <a style={MyStoryStyles.signoffStyles.anchor} href="https://RedWingTradingPost.com">
            <div style={MyStoryStyles.figure}>
              <img  style={MyStoryStyles.signoffStyles.rwtp} src = {RWTPski}/>
              <p>Red Wing Trading Post</p>
            </div>
          </a>
        </div>
      </>
    ); /*return*/
  }; /* render */

  
// A part of My Story that I no longer use:  
//
//          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('butter09'        ), [MyStoryProp_Text] : "This whole time, though, the improbability of our situation consumed me."  }} />
//          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('crock05'         ), [MyStoryProp_Text] : "If I hadn't grown up on a farm or later moved to a farm or that farm was not in southeast Minnesota,"  }} />
//          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('bowl10'          ), [MyStoryProp_Text] : 'or had we not needed to go to small farm auctions, I might likely have never found, nor even heard about, this "Red Wing stuff."'  }} />
//          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('canning02'       ), [MyStoryProp_Text] : "That is where we would be if only our circumstances had been just a little bit different,   ... and so I thought ..."  }} />
//          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('compote10'       ), [MyStoryProp_Text] : "Millions have not yet heard about Red Wing nor have had the chance to.  Millions live far away from Red Wing and wouldn't have heard about it.  Millions more might like to."  }} />
//          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('buds02'          ), [MyStoryProp_Text] : "I was excited but what could I do?  Others have more skills at photography.  Others have more skills in writing copy."}} />
//          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('chromoline04'    ), [MyStoryProp_Text] : "I am an engineer but what could I do?"}} />
//          
//          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('churn07'         ), [MyStoryProp_Text] : "Using the largest promotional medium the world has ever known, I started telling the Red Wing story."  }} />
//          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('figure11'        ), [MyStoryProp_Text] : "Red Wing Trading Post lets me tell this story.  Millions of people could hear it."  }} />
//          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('greyline06'      ), [MyStoryProp_Text] : 'and THAT is my story.  My story is my quest to tell a story, the "Red Wing Story."'  }} />
//          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('flight01'        ), [MyStoryProp_Text] : 'This story is not complete.'  }} />
//          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('crock13'         ), [MyStoryProp_Text] : "Together, we can harness the power of the internet.  Together, we can tell the Red Wing Story and, together, we will find more collectors."}} />
//          <MyStoryBlock {...{[MyStoryProp_ProductName] : ('townandcountry01'), [MyStoryProp_Text] : "I know how to do this work but it requires a team effort to succeed.  This task requires all of us.  Let's get started.  Please contact me."}} />
//
  
  
  /********/
  /* CTOR */
  /********************************************************************************************/
  constructor(props) {
    super(props);
  }; /* CTOR */

}; /* MyStory */





/*****************/
/* Short version */
/**********************************************************************************************/
/* 20.09.04. Starter text anyway.                                                             */
/**********************************************************************************************/
// I grew up on a farm.  With my Grandpa, we worked with, we lived on and we loved the land.
// Land is in my blood.
// After moving to southeast Minnesota, I found a small farm but the previous owner didn't have any tools or supplies.
// We went to small farm auctions and found forks and shovels and most of the tools we needed.  
// We also saw some old stoneware jars used for storage or pickling or for just about anything we needed around the farmhouse.
// Then we noticed that many of these jars were decorated with a red stamp, a red stamp of a wing.
// The red wing stamp marked stoneware made in a small town nearby, Red Wing, Minnesota.
// We noticed more jars at auctions and picked up a few more.  Without realizing it, we were collecting.
// Then we met more collectors and we even joined a group of people who collect Red Wing. 
// The array is products made In Red Wing amazed us.
// We found crocks, churns, coolers.  We found vases and plates and more.
// This whole time, though, the improbability of our situation consumed me.
// If I hadn't grown up on a farm or moved to a farm or that farm were not in southeast Minnesota,
// or had we not needed tools, I might likely have never found, or even heard about, this "red wing stuff."
// Like where we might otherwise have been under different circumstances, 
// millions more have not yet heard about Red Wing, millions more who might like to.
// Using the largest advertising medium the world has ever known, I started telling the Red Wing story.
// redwingtradingpost.com lets me tell this story.  Millions of people could hear it.
// That's my story.  My story is my quest to tell a story, the Red Wing story.
// *********Ooooh, that's telling.


/****************************/
/* Short Version, but wrong */
/**********************************************************************************************/
/* 20.09.03.  This text got away from "my story" and became the story of Red Wing.            */
/*            It's very good stuff, but shit, gotta start over.                               */
/*            And it completely got away from the narrative of improbability.                 */
/**********************************************************************************************/
// Folks who lived on the land, or in the small towns, used these tools.
// To these folks, these tools were oftentimes just stuff around the house, stuff they used, uninteresting stuff.
// But this "stuff" were the tools of a growing America.  
// This "stuff" helped our grandparents live and thrive.
// This "stuff" helped our parents decorate their homes and serve their food.
// "What is this stuff?"
// This stuff is "Red Wing."  Red Wing is not just a town in Minnesota.
// "Red Wing" is a whole array of products made in this town over about a hundred years.
// These products are uniquely American and are exciting to collect.
// To help more people learn about Red Wing, we can use the most effective medium for promotion the world has ever seen.
// So, I developed redwingtradingpost.com to reach out to millions of people.
// Many people can start their journeys of Red Wing discovery and also the joy of collecting it.
//   "Many people" is not just an exaggeration. 
//   "start their journeys" is more than just an opportunity. 
//   "joy of collecting" is not the just opinion of just one, but of many.
// This is a team effort.  Let's go.
// <<< link to root >>>



/******************/
/* Medium Version */
/**********************************************************************************************/
/* 20.09.02.  Not as long as the longer version but still too long.                           */
/**********************************************************************************************/
//The land is in my blood.
//With my Grandpa, I spent time growing up on a farm.  I lived on a farm; I am one with the land.
//After moving to rural Minnesota, then, I very naturally started going to a lot of farm auctions.
//I needed tools, supplies and equipment, but what I found is very profound.
//We like making pickles, so we needed some old jars, stoneware jars are the best.
//On older farms in Minnesota so there are quite a few at old farm auctions.
//We bought a few white ones and we didn't care that they tended to have this stamp on the side, a red wing.
//Curious, I looked them up.  A factory in the area made these jars awhile ago.
//More, this factory had been making these jars for a long time.
//More recently, they switched to making household items, vases, lamps and such.
//They also made some dinnerware.  Were we looking for a set, strong, sturday, heavy, just like my Grandmas'
//We found it: Ceramastone.
//We found crocks, churns, coolers, vases, planters, dinnerware.  We started collecting.
//We found a group of people who also collected, joined, learned some more and collected some more.
//....
//So, we became Red Wing collectors, but I never lost sight of our statistical improbabilities.
//If I had not grown up on a farm, if I had not moved to Minnesota, 
//if I had not found a farm in the southeast corner of Minnesota,
//if I had not gotten around to old farms and found them.
//I would never had learned about Red Wing, the city, the factories, the stoneware, anything else.
//We love it but it was highly unlikely that we would have ever found it.
//Just as unlikely, people across the state, the nation, the world have will never find it.
//So, I aimed to teach to world, even those big city, white collar folks.  Many of them would love to learn it so why not?
//...
//We have the largest and most widely used medium for introduction and promotion in the history of the world, the World Wide Web.
//I have skills in developing websites and in integration and promotion.  This is what I do, this is how I can help.
//I can do the development, but integration and promotion is a group activity.
//Together, we can teach thousands of more people what was, for me, a statistically zero percent chance of ever learning.
//But integration and promotion are also essential components



/******************/
/* Longer Version */
/**********************************************************************************************/
/* 20.09.01.  It is too long for this application but might be of some use in the future      */
/**********************************************************************************************/
//Part of my early life I spent growing up on a farm.
//My grandpa would walk with me through his fields, share how he irrigated (by hand by the way), teach me about life and living and ... listen to me.
//My best times I spent on Grandpa's farm, good times, cherished times.  I grew up not just on it but with it.
//I claim the joy that I feel because land is the blood in my veins.
//
//Then, I chose science and engineering for a career path.
//I looked at positions all over the country but chose a less urban location in Minnesota over a crowded metropolis like Dallas or Phoenix.
//
//But I never lost my love of the land.
//We found a really nice rural location but it was an old farm and we didn't have any tools or supplies.
//We needed forks and barrows and shovels and fencing tools and so forth.
//I started going to farm auctions, lots of farm auctions.  I found a bunch of stuff.
//We also wanted to make pickles and kraut and found some old stoneware jars on the farm.  We bought a few/.
//
//Though not at first, I noticed that these oftentimes old and stained and sometimes chipped jars had the red wing stamps on them.
//I looked around and found that these jars were made locally in Red Wing, Minnesota.  Red Wing's not far away.  Ok, cool.
//
//But they kept popping up so I looked into them more.  I discovered that factories in Red Wing made these cool jars some long time ago and
//made other, even cooler, ones an even longer time ago.  Then they starting making decorative products and dinnerware sets.  
//
//Then we started picking up some things at our farm auctions we had never even noticed before.  We found a few vases and planters
//and found the heaviest, more sturday dinnerware set we've ever seen, love it and started collecting Ceramastone, all patterns.
//
//We discovered Red Wing, the city, the company, the older products, the newer products and the joy of collecting it.
//but I also realized the improbability of my situation.
//
//If my Grandpa was not a farmer, if I had not grown up on a farm, if I had not chosen Minnesota to pursue a career,
//if I had not found a farm of my own, if I had not found a need to attend farm auctions, 
//... I would have never discovered Red Wing.  And, content though in ignorance, I would have never cared.
//





