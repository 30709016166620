/**********************/
/* RWTP_AgoCollection */
/**********************************************************************************************/
/* A Red Wing Trading Post collection which collects all of the Red Wing 'good' images from   */
/* the 2017 version of redwingtradingpost.com.                                                */
/*                                                                                            */
/* Copyright 2020, Orcadia Labs LLS.  All Rights Reserved.                                    */
/**********************************************************************************************/
import {RedWingTradingPostLibraryCollection} from './RedWingTradingPostLibraryCollection.js';


export class RWTP_AgoCollection extends RedWingTradingPostLibraryCollection {

  /**************************/
  /* getKnownCollectionName */
  /********************************************************************************************/
  /* Statically- and globally-accessible name of this collection.  This name may be           */
  /* referenced statically to obtain the name of this specific collection.  This name may     */
  /* also be referenced globally through the class instance method getName().                 */
  /********************************************************************************************/
  static getKnownCollectionName = () => {return ("RWTP_Ago_Collection")};
  
  
  /***********/
  /* getName */
  /********************************************************************************************/
  /* Each RedWingTradingPostLibraryCollection subclass will return its name.                  */
  /* @return the name of this collection.                                                     */
  /********************************************************************************************/
  getName() { return (RWTP_AgoCollection.getKnownCollectionName()); }; 
  
  
  /********/
  /* CTOR */
  /********************************************************************************************/
  /* Record access to this collection.                                                        */
  /* For this objects integrity, this collection will contain the computed the library keys   */
  /********************************************************************************************/
  constructor() {
  
    // In alphabetical order:
    super([
           '_bobwhite01'   ,  '_brittany01'   ,  '_chuckwagon01' ,  '_churn01'      ,  '_churn02'      ,  '_churn03'      ,
           '_churn05'      ,  '_comm198501'   ,  '_comm199001'   ,  '_comm199201'   ,  '_comm199202'   ,  '_comm200301'   ,    
           '_cookie01'     ,  '_cooler01'     ,  '_crock01'      ,  '_crock02'      ,  '_crock03'      ,  '_crock04'      ,       
           '_crock05'      ,  '_crock06'      ,  '_crock07'      ,  '_crock40'      ,  '_crockhazel'   ,  '_fanvase01'    ,     
           '_feeder01'     ,  '_greyline01'   ,  '_gypsy01'      ,  '_jug01'        ,  '_jug02'        ,  '_jug03'        ,         
           '_jug04'        ,  '_jug05'        ,  '_jug06'        ,  '_jug07'        ,  '_jug08'        ,  '_jug09'        ,         
           '_jugpotter'    ,  '_mason02'      ,  '_pitcher01'    ,  '_reed01'       ,  '_sheetmusic01' ,  '_tarts'        ,         
           '_tpotchicken01',  '_tulip'        ,  '_vase08'       ,  '_vase09'       ,  '_vasegreek01'  ,  '_vasegreek02'  ,   
    ]);
  }; /* CTOR */
}; /* RWTP_AgoCollection  */


