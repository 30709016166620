/***************************/
/* RoadSignTargetAnimator */
/**********************************************************************/
/* The animation class for the Road Sign Target.                      */
/*                                                                    */    
/* Copyright (C) 2019 - 2020 Orcadia Labs LLC                                                 */   
/**********************************************************************/
import React  from 'react'; 
import roadsignTargetArrow_1    from '../images/menusign/menusign-target-a1.png'  ;
import roadsignTargetArrow_2    from '../images/menusign/menusign-target-a2.png'  ;
import roadsignTargetArrow_3    from '../images/menusign/menusign-target-a3.png'  ;
import roadsignTargetArrow_4    from '../images/menusign/menusign-target-a4.png'  ;
import {RoadSignAnimator      } from './RoadSignAnimator.js'                      ;
import {OrcadiaLabsJsxIF      } from '../../components/OrcadiaLabs_JSXTrix.js'    ; 
import {RoadSign_Constants    } from '../RoadSignConstants.js'                    ; 
import {RoadSignAnimationTimer} from './RoadSignAnimationTimer'                   ;


///********************************/
///* RoadSignTargetAnimationState */
///**********************************************************************/
///* This Road Sign Target Animation can be in one of three different   */
///* states:                                                            */
///*   not started  - its first rendering has not yet happened.         */
///*   started      - it is in the process of rendering.                */
///*   not finished - it is in the process of finishing- one more frame */
///*   finished     - it is done animating and will not restart.        */
///**********************************************************************/
//let RoadSignTargetAnimationState = {
//  targetAnimationNotStarted  : 0,
//  targetAnimationStarted     : 1,
//  targetAnimationNotFinished : 2,
//  targetAnimationFinished    : 3
//}; /* RoadSignTargetAnimationState */


export class RoadSignTargetAnimator extends RoadSignAnimationTimer {
  
  
  /*********************/
  /* getAnimationSpeed */
  /********************************************************************/
  /* Obtain the speed of this particular animation.  If not otherwise */
  /* specified, this speed will be the declared common intra-speed    */
  /* for animations.                                                  */
  /* Individual animators should override this method to provide a    */
  /* custom-tailored speed value.                                     */
  /* @return a number which will represent the number of milliseconds */
  /*         in-between frame updates for a particular animation.     */
  /********************************************************************/
  getAnimationSpeed() {
    return ( 1000 );
  } /* getAnimationSpeed */

  
  /*********************/
  /* getAnimationDelay */
  /********************************************************************/
  /* Obtain the number of milliseconds before this target animation   */
  /* will primarily render after it is first started.                 */ 
  /* The road sign target animation should delay before starting to   */
  /* give the view enough time to click to roadsign menu before she   */
  /* is bothered by this animation.                                   */   
  /* @return the number described above.                              */
  /********************************************************************/
  getAnimationDelay() {
    return 4 * RoadSign_Constants.animationDelay.interAnimation;
  } /* getAnimationDelay */


//  /***************/
//  /* isAnimating */
//  /********************************************************************/
//  /* A RoadSignTargetAnimator will continue to animate until the      */
//  /* stopTargetAnimator() method is invoked.                          */
//  /* @return whether this animator can continue to animate.           */ 
//  /********************************************************************/
//  isAnimating() {
//    return ((RoadSignTargetAnimationState.targetAnimationStarted     == this.targetAnimationState) ||
//            (RoadSignTargetAnimationState.targetAnimationNotFinished == this.targetAnimationState));
//  }; /* isAnimating */
//  
//
//  /*****************/
//  /* stopAnimating */
//  /********************************************************************/
//  /* The Road Sign Target Animator should cease its animation.        */
//  /* Additionally, it will clean up its renderings through one more   */
//  /* call to renderFrame().                                           */              
//  /********************************************************************/
//  stopAnimating() {
//    super.stopAnimating();
//    this.targetAnimationState = RoadSignTargetAnimationState.targetAnimationNotFinished;
//  }; /* stopAnimating */
  

  /***************/
  /* renderFrame */
  /********************************************************************/
  /* Each RoadSignAnimator instance will implement the renderFrame()  */
  /* method to provide its special animation semantic.                */
  /* By default, no content will be produced, override this method.   */ 
  /* @param frameNumber is the 1-based number of the frame to render. */
  /*                                                                  */
  /* Independent of the (frameNumber) to render, this class also      */ 
  /* knows its own rendering state.  A new rendering is allowed iff   */
  /* the current render state is anything but 'finished'.             */
  /*                                                                  */
  /* A target animator manages four different states (frames) of the  */    
  /* target animation:                                                */
  /*   1 - display nothing.                                           */
  /*   2 - display arrow target image 1                               */
  /*   3 - display arrow target image 1, 2                            */
  /*   4 - display arrow target image 1, 2, 3                         */
  /*   5 - display arrow target image 1, 2, 3, 4                      */
  /********************************************************************/
  renderFrame(frameNumber) {
    // first, if we're done animating, don't do anything.
    if (0 == frameNumber) {
      return (<div></div>); // no init at this time.
    }
    if (!this.isAnimating()) { 
      return null;
    }
    
    // first, calculate the frame state as defined above.             */
    var thisFrameNumber = ((arguments.length > 0) ? (frameNumber) : 1);
    var frameState = 1 + ((thisFrameNumber - 1) % 5);

    return (
      <div>
        <OrcadiaLabsJsxIF if={(2 <= frameState)}>
          <img className="roadsign-menu-image"  src={roadsignTargetArrow_1} alt='Red Wing Arrow'/>
        </OrcadiaLabsJsxIF>
        <OrcadiaLabsJsxIF if={(3 <= frameState)}>
          <img className="roadsign-menu-image"  src={roadsignTargetArrow_2} alt='Red Wing Arrow'/>
        </OrcadiaLabsJsxIF>
        <OrcadiaLabsJsxIF if={(4 <= frameState)}>
          <img className="roadsign-menu-image"  src={roadsignTargetArrow_3} alt='Red Wing Arrow'/>
        </OrcadiaLabsJsxIF>
        <OrcadiaLabsJsxIF if={(5 <= frameState)}>
          <img className="roadsign-menu-image"  src={roadsignTargetArrow_4} alt='Red Wing Arrow'/>
        </OrcadiaLabsJsxIF>
      </div>
    ); /*return*/
  }; /* renderFrame */
  

  /**************/
  /* Life Cycle */
  /********************************************************************/
  /* componentDidMount - start target animation.                      */
  /********************************************************************/
  componentDidMount() {
    this.startAnimating();
  }; /* componentDidMount */
  
  
  /********/
  /* CTOR */
  /********************************************************************/
  /* Do everything necessary to ensure that this                      */
  /* RoadSignTargetAnimator is ready to animate.                      */
  /* @param props hold properties.                                    */
  /********************************************************************/
  constructor(props) {
    super(props);
  }; /* CTOR */
};  /* RoadSignTargetAnimator */



