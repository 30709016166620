/****************************************/
/* RedWingTradingPostLibraryConveyer.js */
/**********************************************************************************************/
/* A (RedWingTradingPostLibraryConveyer) is able to maintain a queue of                       */
/* (RedWingTradingPostLibraryCard)s asynchronously yet provide access to them synchronously.  */
/*                                                                                            */    
/* Copyright (C) 2020 Orcadia Labs LLC                                                        */   
/**********************************************************************************************/
import {RedWingTradingPostLibrary    } from './RedWingTradingPostLibrary.js'     ;
import {RedWingTradingPostLibrarian  } from './RedWingTradingPostLibrarian.js'   ;
import {RedWingTradingPostLibraryCard} from './RedWingTradingPostLibraryCard.js' ;
import RedWingTradingPostDefaultImage  from '../assets/images/RWTP_Oval_2b.png'  ; 


// Import mutex support from open-source package:
import {Mutex} from 'async-mutex';


/******************************************/
/* RedWingTradingPostLibraryConveyerMutex */
/**********************************************************************************************/
/* The use of mutex function supports the integrity of all data.                              */
/**********************************************************************************************/
let RedWingTradingPostLibraryConveyerMutex = new Mutex();


/*******************************************/
/* RedWingTradingPostLibraryConveyer_Const */
/**********************************************************************************************/
/* maxLength       - The (RedWingTradingPostLibraryConveyer) can contain, at most, this       */
/*                   number of (RedWingTradingPost_libraryCard)'s                             */
/* attemptsPerFill - the Conveyer will fill, at most, this number of Llibrary Cards per       */
/*                   iteration.                                                               */
/**********************************************************************************************/
const RedWingTradingPostLibraryConveyer_Const = {
    maxLength       : 10,
    attemptsPerFill :  2,
}; /* RedWingTradingPostLibraryConveyer_Const */


/**********************************************/
/* RedWingTradingPostLibraryConveyer_Conveyer */
/**********************************************************************************************/
/* The actual conveyer object.  This global variable is not exposed to eliminate the          */
/* possibility that it might be modified/accessed outside of the scope of this conveyer's     */
/* locking mechanism.                                                                         */
/**********************************************************************************************/
let   RedWingTradingPostLibraryConveyer_Conveyer = new Array();


/*************************************************************/
/* RedWingTradingPostLibraryConveyer_DefaultLibraryCard      */
/* RedWingTradingPostLibraryConveyer_DefaultLibraryCardName  */
/* RedWingTradingPostLibraryConveyer_DefaultLibraryCardImage */
/**********************************************************************************************/
/* The conveyer will deliver this default library card in any case where it may be not able   */
/* to deliver a library card on the conveyer.  The conveyer initializes this default library  */
/* card upon its initial creation and initialization.                                         */
/* This default library card is selected by name, this name is provided here.                 */
/**********************************************************************************************/
let   RedWingTradingPostLibraryConveyer_DefaultLibraryCard      = null;
let   RedWingTradingPostLibraryConveyer_DefaultLibraryCardName  = "RWTPSkiOval";
let   RedWingTradingPostLibraryConveyer_DefaultLibraryCardImage = RedWingTradingPostDefaultImage;


/********************************************************/
/* RedWingTradingPostLibraryConveyer_ComposeLibraryCard */
/**********************************************************************************************/
/* Each (RedWingTradingPostLibraryCard) on the (RedWingTradingPostLibraryConveyer) has        */
/* preloaded image data.  This action happens asynchronously and only on one, particular      */
/* library card.                                                                              */
/* This function is asynchronous and will resolve only after the specified library card has   */
/* been preloaded with its specific image data.                                               */
/* This function will resolve a library card, guaranteed.  If there is a problem, it will     */
/* resolve the default library card but it will resolve.                                      */
/*                                                                                            */
/* @param   name is the key of the library card to prepare, the default is to pick a library  */
/*               card at random.                                                              */
/* @return       a Promise that will resolve or reject                                        */
/* @resolve      when this method has successfully created, initialized, preloaded and saved  */
/*               a (RedWingTradingPostLibraryCard) object.  The payload will be this library  */
/*               card.                                                                        */
/**********************************************************************************************/
let RedWingTradingPostLibraryConveyer_ComposeLibraryCard = async (name = null) => {
  return (new Promise((resolve, reject = null) => {
            // prepare one library card even if it will eventually be not used:
            // use (key) if it is provided or pick an item at random if it is not.
            var libraryCard = null;
            if (name) {
              // select the library card by name:
              libraryCard = RedWingTradingPostLibrarian.getCollection().getByName(name);
            } /*if*/
            else {
              // select the library card stochastically, ensure it is usable:
              while (!libraryCard) {
                libraryCard = RedWingTradingPostLibrarian.getCollection().pick();
                if (!RedWingTradingPostLibrarian.IsUsableLibraryCard(libraryCard)) {
                 // this is not a usable library card, pick another:
                  libraryCard = null;
                } /*if*/
              } /*while*/
            } /*else*/
  
            // (libraryCard) is a (RedWingTradingPostLibrarCard) with all of the
            // initial information needed to download it
            // Do not use CORS, all the Conveyer needs is a raw copy of the image:
            var libraryCardImageAddress = libraryCard.getImageAddress(); 
            fetch(libraryCardImageAddress, 
                  {
                    mode: 'no-cors' 
                  })
                 .then((fetched) => {
                        fetched.blob()
                               .then((image) => {
                                       libraryCard.setImage(image);
                                       resolve(libraryCard);
                                    })
                      })
                 .catch((error) => {
                         resolve(RedWingTradingPostLibraryConveyer_DefaultLibraryCard);
                      });
  })); /*return*/
}; /* RedWingTradingPostLibraryConveyer_ComposeLibraryCard */


/*****************************************************/
/* RedWingTradingPostLibraryConveyer_AddComposedCard */
/**********************************************************************************************/
/* Fill the conveyer with full intent but with limitations.                                   */
/* Method RedWingTradingPostLibraryConveyer.FillConveyer() lists all limitations and final    */
/* conditions.                                                                                */
/* This method will resolve() after it has added one, fuly usable and composed library card   */
/* to this conveyer.                                                                          */  
/* Note: this method is will not work unless it is mutexed.  This method is mutexed by        */
/* RedWingTradingPostLibraryConveyer.Fill() and should be called from only this source.       */
/*                                                                                            */
/* @return  a Promise that will resolve or reject                                             */
/* @resolve when this method has successfully created, initialized, preloaded, composed and   */
/*          saved one usable (RedWingTradingPostLibraryCard) object.  Each library card, as   */
/*          well as the conveyer, will satisfy all conditions and limitations.  This method   */
/*          carries a payload of the singe library card.  However, callers should not use     */
/*          this payload.  This and all other library cards on this conveyer should be        */
/*          subsequently accessed by using RedWingTradingPostLibraryConveyer.Pick().          */
/* @reject  when the conveyer is full, no more library cards may be added to it.              */
/**********************************************************************************************/
let RedWingTradingPostLibraryConveyer_AddComposedCard = async () => {
    return (new Promise((resolve, reject) => {
             if (RedWingTradingPostLibraryConveyer_Conveyer.length
                                >=  RedWingTradingPostLibraryConveyer_Const.maxLength) {
               // conveyer is full
               reject();
             } /*if*/

             // compose one library card.
             // One library card will be composed and resolved,
             // ComposeLibraryCard() will not reject, so no reason to handle it here:
             RedWingTradingPostLibraryConveyer_ComposeLibraryCard()
                 .then((libraryCard) =>{
                          // have a fully loaded and prepared library card.  Save and continue:
                          RedWingTradingPostLibraryConveyer_Conveyer.push(libraryCard);
                          resolve(libraryCard);
                      });
    })); /*return*/
    
}; /* RedWingTradingPostLibraryConveyer_AddComposedCard */


/**************************************/
/* RedWingTradingPostLibraryConveyer */
/**********************************************************************************************/
/* The (RedWingTradingPostLibraryConveyer_Conveyer) runs asynchronously as is the class */
/* that runs asynchronously to fill the (RedWingTradingPostLibraryConveyer_Conveyer).         */
/* The picker will run asynchronously until the conveyer is filled.  Once filled, the picker  */
/* will resolve.  The owner of the picker then has the option to restart it.                  */
/**********************************************************************************************/
export class RedWingTradingPostLibraryConveyer {


  /********/
  /* Pick */
  /********************************************************************************************/
  /* Request one (RedWingTradingPostLibraryCard) from this conveyer.                          */
  /* This method WILL resolve one library card, even if that library card is the default.     */
  /*                                                                                          */
  /* @return  a Promise that will resolve or reject.                                          */
  /* @resolve this conveyer has picked a library card.  The resolve payload is the            */
  /*          picked ((RedWingTradingPostLibraryCard).                                        */
  /*          If Pick() finds any trouble, it will return the default library card.           */
  /* @reject  At this time (20.08.17) every possible, known input combination will result in  */
  /*          a resolve().  This method will not reject().                                    */
  /********************************************************************************************/
  static async Pick() {
    return (new Promise((resolve, reject) => {
    
      let pickAction = (releaseMutex) => {
      
        // This picker is ready to pick from this conveyer.
        // Pop the top of the conveyer stack and return a deep copy of that popped library card.
        // Start by using the default library card in case this picker finds a problem
        // accessing the conveyer, itself, or its contents.
        var returnableLibraryCard = RedWingTradingPostLibraryConveyer_DefaultLibraryCard;
      
        // Pop the top library card off the conveyer and make a deep copy of the library card object.      
        // If the conveyer is empty, return the default library card instead:
        var conveyedLibraryCard = null;
        if (RedWingTradingPostLibraryConveyer_Conveyer.length > 0) {
          conveyedLibraryCard = RedWingTradingPostLibraryConveyer_Conveyer.shift();
        } /*if*/

        if (conveyedLibraryCard) {
          returnableLibraryCard = conveyedLibraryCard;
        } /*if*/

        // all library cards on the conveyer are usable, resolve (returnableLibraryCard)
        // Kick off an asynchronous Fill() operation to replace this returned library card
        // no need to respond to this Fill() operation, just let it go.
        if (releaseMutex) releaseMutex();
        RedWingTradingPostLibraryConveyer.Fill();
        resolve(returnableLibraryCard);
      }; /* pickAction */
      
      // Wait until the conveyer can be used: 
      RedWingTradingPostLibraryConveyerMutex
          .acquire()
          .then   ((releaseMutex       ) => {
                     // grant access:
                     pickAction(releaseMutex);
                   }) /*then*/
          .catch  ((error  ) => {
                     // The conveyer expects no error condition, but handle in any case:
                    resolve(RedWingTradingPostLibraryConveyer_DefaultLibraryCard);
                  }); /* catch */

    })); /*return*/  
  }; /* Pick */


  /*********/
  /*  Fill */
  /********************************************************************************************/
  /* When directed to fill the conveyer, this method will select new library card objects.    */
  /* Once it has finished work, the convery will be more full and this method will resolve.   */
  /* This method will resolve for any of these (3) reasons:                                   */
  /*   (1) it finished its work filling the conveyer                                          */
  /*   (2) it reached its maximum number of attempts while attempting to fill the conveyer.   */
  /*                                                                                          */
  /* 20.08.23 At this time, the Conveyer will fill zero, one or two slots for each invocation.*/
  /* The whole of this Fill operation is mutexed with equivalent priority to the Pick()       */
  /* action.  Filling more than two slots is possible for the future but this method will not */
  /* attempt more than twice at this time.                                                    */
  /*                                                                                          */
  /* @return  a Promise that will resolve.                                                    */
  /* @resolve for any of the above two reasons.  Regardless of the reason, this method will   */
  /*          be done when it resolves.  resolve() carries no payload.                        */
  /********************************************************************************************/
  static async Fill() {
    return (new Promise((resolve, reject = null) => {
    
      // if there is room on the conveyer then pick a new library card:
      // in case of network failure, attempt twice to increase the chance of one fill.

      // attempt to load the conveyer to full capacity.
      // due to the asynchronous nature of this loading process, this implementation
      // cannot be iterative, it must be recursive.
      // Note: the Compose() method will resolve() when one card was added and it will
      //       reject() when the conveyer is full.
      let fillAction = (releaseMutex, numberOfAttempts) => {
        // check if this method has reached its maximum number of attempts:
        if ((numberOfAttempts <= 2) &&  
            (RedWingTradingPostLibraryConveyer_Conveyer.length 
               < RedWingTradingPostLibraryConveyer_Const.maxLength)) {
          // have permission to fill and have space to fill:
          RedWingTradingPostLibraryConveyer_AddComposedCard()
            .then((composedLibraryCard) => {
                    // no action to complete for this (composedLibraryCard),
                    // it is already on the conveyer
                    // recurse if possible:
                    fillAction(releaseMutex, (numberOfAttempts + 1));
                 });
        } /*if*/
        else {
          // this Fill() operation has concluded for one of the two documented reasons:
          if (releaseMutex) releaseMutex();
        } /*else*/
      }; /* fillAction */
    
      // Wait until the conveyer can be used: 
      // Try a predetermined number of times.
      //   Each iteration is asynchronous, so each iteration will line up:
      // When the Conveyer fills up entirely, additional attempts to fill it farther will fail:
      // Also, failover immediately if there is no work to do so mutex queue doesn't grow.
      if (RedWingTradingPostLibraryConveyer_Conveyer.length 
          < RedWingTradingPostLibraryConveyer_Const.maxLength) {

        for (var fillAttempt = 0; 
                 fillAttempt < RedWingTradingPostLibraryConveyer_Const.attemptsPerFill;
               ++fillAttempt) {
          RedWingTradingPostLibraryConveyerMutex
              .acquire()
              .then   ((releaseMutex       ) => {
                         // grant access:
                         fillAction(releaseMutex, 1);
                       }) /*then*/
              .catch  ((error  ) => {
                         reject(error);
                      }); /* catch */
        } /*for*/
      } /*if*/
      
    })); /*return*/
  }; /* Fill */


  /*********************/
  /* ConstructConveyer */
  /********************************************************************************************/
  /* Perform all actions required upon construction of this conveyer.                         */
  /* This method is synchronous but it calls an asynchronous method.                          */
  /* This method will return immediately but its effect may happen sometime later.            */
  /* Access to all data is handled via mutex, so no failure condition or concern is warranted */
  /********************************************************************************************/
  static ConstructConveyer() {
    // Construct this Conveyer only once.  If it has been already constructed, just quit.
    if (!RedWingTradingPostLibraryConveyer_DefaultLibraryCard) {
      RedWingTradingPostLibraryConveyer_DefaultLibraryCard
        = RedWingTradingPostLibrarian.getCollection().getByName(RedWingTradingPostLibraryConveyer_DefaultLibraryCardName);
      RedWingTradingPostLibraryConveyer_DefaultLibraryCard.setImage(RedWingTradingPostDefaultImage);
    } /*if*/
  }; /* ConstructConveyer */

  
}; /* RedWingTradingPostLibraryConveyer_Conveyer */



