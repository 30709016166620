/**************************/
/* RoadSignAnimationTimer */
/**********************************************************************************************/
/* Provides a consistent wrapper for various animations to ensure that they each              */
/* consistently rerender.                                                                     */
/*                                                                                            */     
/* Copyright (C) 2019 - 2020 Orcadia Labs LLC                                                 */   
/**********************************************************************************************/
import React  from 'react'; 
import {RoadSign_Constants} from '../RoadSignConstants.js'; 
import {RoadSignAnimator  } from './RoadSignAnimator.js'  ; 


/* A RoadSignAnimationConduit contains a media for communication      */
/* between a RoadSignAnimationTimer, a RoadSignAnimator and the owner */
/* of the timer, like the RoadSign class.                             */
export class RoadSignAnimationConduit {
  /* Sole location for maintaining animation status:                  */
  /* startAnimating - set the animating flag and start animator.      */
  /* isAnimating    - return where animator is currently animating.   */
  /* stopAnimating  - set flag to stop, animation will stop next time */
  /*                  it requests (isAnimating().)                    */
  /* register       - register an animator with this conduit.         */
  /*                  Registered animator will start rendering when   */
  /*                  the startAnimating() method is invoked.         */      
  isAnimating    (        ) { return (!!this.animatorAnimating)   };
  stopAnimating  (        ) { this.animatorAnimating = false;     };
  register       (animator) { this.registeredAnimator = animator; };
  startAnimating (        ) { 
    this.animatorAnimating = true;                /* turn on animation*/
    if (this.registeredAnimator) {                /* if is an animator*/
      var thisAnimator = this.registeredAnimator; /* start an animator*/
      this.registeredAnimator = null;             /* once only.       */
      
      // start the animation after initialization:
      thisAnimator.performInterFrameWork()
                  .then(() => {
                     thisAnimator.startAnimating(); /* animate!       */
                   });
    }; /*if*/
  };  /* startAnimating */
}; /* RoadSignAnimationConduit */


export class RoadSignAnimationTimer extends RoadSignAnimator {


  /******************/
  /* setFrameNumber */
  /******************************************************************************************/
  /* set the number of the current frame.  All frame numbers other than zero (or if         */
  /* (frameNumber) is not supplied.  This method will request a rerendering.                */
  /*                                                                                        */
  /* @parm frameNumber is the number of the animation frame to render                       */
  /*                   Optional: if not supplied, the current frame number will be zero.    */
  /*                   Any animator can treat frame zero as a point before animation starts */
  /*                   it may use this frame to reset its own state.                        */
  /******************************************************************************************/
  setFrameNumber(frameNumber) {
    var frameToSet = 0;
    if ((arguments.length > 0) && (frameNumber != 0)) 
      // brand new and valid frame number:
      this.setState({frameNumber : (frameNumber)});
    else // initialization:
      this.state = ({frameNumber : (0)});
  }; /* setFrameNumber */
  
  
  /******************/
  /* getFrameNumber */
  /******************************************************************************************/
  /* @return the number of the animation frame to render.  Zero means to render no frame,   */
  /*         may be used for initialization.                                                */
  /******************************************************************************************/
  getFrameNumber() {
    if ((this.state) && (this.state.frameNumber)) return this.state.frameNumber;
    return 0;  /* default, initialization */
  } /* getFrameNumber */
  
  
  /*****************/
  /* timeAnimator */
  /******************************************************************************************/
  /* perpetually continuous loop to drive the known animation until stopTimer() has been    */
  /* called.                                                                                */
  /******************************************************************************************/
  timeAnimator() {
    // keep animating until that time when the stopAnimating() method is called.
    // generate one frame past this method call so that the animator can clean up.
    this.setFrameNumber(this.getFrameNumber() + 1);
  }; /* timeAnimator */
  
  
  /******************/
  /* startAnimating */
  /******************************************************************************************/
  /* start animating this animator.  The superclass has a startAnimating method, but        */
  /* override it here to apply additional work.                                             */
  /******************************************************************************************/
  startAnimating() {
    super.startAnimating();          /* perform general work:         */
    
    // actually start the animation sequence:
    this.initializeAnimation()
        .then((() => {
          setTimeout((() => { 
              this.timeAnimator()}),  
                                this.getAnimationDelay());
        }));
  }; /* startAnimating */
  
  
  /***********************/
  /* initializeAnimation */
  /******************************************************************************************/
  /* This opportunity allows a particular animator to perform any initialization actions    */
  /* prior to the animation starting.                                                       */
  /* Note that this methodis asynchronous, it returns a Promise.                            */
  /* By default, this initializeAnimation() method performs no work.  If no work is still   */
  /* required by any animator, then it need not override this method.                       */
  /* @return  a Promise that can be resolve()d or reject()ed.                               */
  /* @resolve when this asynchronous processing is over.  No payload.                       */
  /******************************************************************************************/
  async initializeAnimation() {
    return (new Promise((resolve, reject) => {
      resolve();
    }));
  }; /* initializeAnimation */

  
  /**********/
  /* render */
  /********************************************************************************************/
  /* The rendering of an animation timer involves no more than rendering the next frame of    */
  /* the animator.  Since this (RoadSignAnimationTimer) is a subclass of (RoadSignAnimator),  */
  /* this class is guaranteed to have a render() method (by default, rendering nothing.  When */
  /* a specific road sign animator class is subclasses this time in the inheritance tree, it  */
  /* will support a renderFrame() method to render a single frame.  Call it renderFrame() is  */
  /* always available since it is declared in this superclass.                                */
  /********************************************************************************************/
  render() {
    if (!this.isAnimating()) return null;
    
    // timeAnimator
    // The function to call after the time runs
    var timeAnimator = () => {
      this.performInterFrameWork()
          .then(() => {
             this.timeAnimator();
           }); /* then */
    }; /* timeAnimator */
    
    
    // animating, generate this frame and schedule the next:
    setTimeout(timeAnimator, this.getAnimationSpeed());     // after this delay
    
    return (<>{this.renderFrame(this.getFrameNumber())}</>);
  }; /* render */
  

  /*************************/
  /* performInterframeWork */
  /********************************************************************************************/
  /* Asynchronously perform any work that must be completed between frames.  This work may be */
  /* completed asynchronously and could involve other asynchronous actions like downloading   */
  /* files or images.                                                                         */
  /* By default, this method performs no work.                                                */
  /********************************************************************************************/
  async performInterFrameWork() {
    return (new Promise((resolve, reject) => {
             resolve();
            } /* Promise */
    )); /*return*/      
  }; /* performInterFrameWork */
  
  
  /********/
  /* CTOR */
  /********************************************************************************************/
  /* Start the indicated animation.                                                           */
  /* @prop RoadSign_Constants.property.animation id's animation.                              */
  /********************************************************************************************/
  constructor(props) {
    super(props);
    this.setFrameNumber();
  }; /* CTOR */
  
};  /* RoadSignAnimationTimer */


