/***************************************/
/* RedWingTradingPostImageLibrarian.js */
/**********************************************************************************************/
/* Single location for managing all images for Red Wing Trading Post.                         */
/*                                                                                            */    
/* Copyright (C) 2019 - 2020 Orcadia Labs LLC                                                 */   
/**********************************************************************************************/
import {RedWingTradingPostLibraryCard } from './RedWingTradingPostLibraryCard.js' ;
import {RWTP_AllCollection            } from './collections/RWTP_AllCollection.js';
import {RWTP_AgoCollection            } from './collections/RWTP_AgoCollection.js';


/*************************************/
/* installAllKnownLibraryCollections */
/* AllKnownLibraryCollections        */
/**********************************************************************************************/
/* One-time stop for ensuring that this RedWingTradingPostLibrarian is absolutely and fully   */
/* aware of every library collection.                                                         */
/*                                                                                            */
/* Update this method with the creation of every new RedWingTradingPostLibraryCollection      */
/* instantiation.                                                                             */
/**********************************************************************************************/
let installAllKnownLibraryCollections = () => {
  if (!AllKnownLibraryCollections) {
    AllKnownLibraryCollections = [];
    AllKnownLibraryCollections.push(new RWTP_AllCollection());
    AllKnownLibraryCollections.push(new RWTP_AgoCollection());
  } /*if*/
};  /* insstallCollections */
let AllKnownLibraryCollections = undefined;


/*******************************/
/* RedWingTradingPostLibrarian */
/**********************************************************************************************/
/* This class offers various methods for accessing images that are managed by Red Wing        */
/* Trading Post.                                                                              */
/**********************************************************************************************/
export class RedWingTradingPostLibrarian  {


  /*************************************/
  /* PickRedWingTradingPostLibraryCard */
  /********************************************************************************************/
  /* Stochastically pick one image known to the Red Wing Trading Image Library and return an  */
  /* object that represents it.  This function will NOT return direct access to this library  */
  /* image.                                                                                   */
  /*                                                                                          */
  /* All of the relevant information and function is held within a single                     */
  /* (RedWingTradingPostLibrary_Collection).  This function is a convenience so that the      */
  /* caller need not know all of the specific access details or possible problems.            */
  /*                                                                                          */
  /* @return (RedWingTradingPostLibraryCard) representing the image.  This particular image   */
  /*         may be the actual one requested or one chosen stochastically as per the above    */
  /*         descriptions.                                                                    */
  /********************************************************************************************/
  static PickRedWingTradingPostLibraryCard = () => { 
    return RedWingTradingPostLibrarian.getCollection().pick();
  }; /* PickRedWingTradingPostLibraryCard */


  /************************************/
  /* GetRedWingTradingPostLibraryCard */
  /********************************************************************************************/
  /* See RedWingTradingPostLibraryCard.
  /* Retrieve one RedWgingTradingPostLibraryCard by nameStocastically pick one image known to the Red Wing Trading Image Library and return an   */
  /* object that represents it.  This function will NOT return direct access to this library  */
  /* image.                                                                                   */
  /*                                                                                          */
  /* All of the relevant information and function is held within a single                     */
  /* (RedWingTradingPostLibrary_Collection).  This function is a convenience so that the      */
  /* caller need not know all of the specific access details or possible problems.            */
  /*                                                                                          */
  /* @return (RedWingTradingPostLibraryCard) representing the image.  This particular image   */
  /*         may be the actual one requested or one chosen stochastically as per the above    */
  /*         descriptions.                                                                    */
  /********************************************************************************************/
  static PickRedWingTradingPostLibraryCard = () => { 
    return RedWingTradingPostLibrarian.getCollection().pick();
  }; /* PickRedWingTradingPostLibraryCard */


  /******************/
  /* findCollection */
  /********************************************************************************************/
  /* Search for a RedWingTradingPostLibraryCollection object of the given (collectionName).   */
  /* If not found, return undefined.                                                          */
  /* @param  collectionName is the name of the collection to retrieve                         */
  /* @return the named collection as defined above.                                           */
  /********************************************************************************************/
  static findCollection() {
    return null;        // this method is not yet defined.
  }; /* findCollection */
  
  
  /*****************/
  /* getCollection */
  /********************************************************************************************/
  /* Obtain access to a known RedWingTradingPostLibraryCollection.  If this                   */
  /* (RedWingTradingPostLibrarian does not recognize this name as representing any known      */
  /* collection, it will return the single collection of all library images.                  */  
  /* @param  collectionName is the name of the collection to retrieve                         */
  /* @return                the named collection as defined above.                            */
  /********************************************************************************************/
  static getCollection = (...collectionName) => {
  
    // search for a collection of the given name or default.
    // this method will never return undefined.
    let findCollection = (collectionName) => {
      var defaultCollectionName = RWTP_AllCollection.getKnownCollectionName();
      var defaultCollection     = undefined;
      
      for (var collection = 0; collection < AllKnownLibraryCollections.length; ++collection) {
        // save the default collection when found:
        if (AllKnownLibraryCollections[collection].getName() === RWTP_AllCollection.getKnownCollectionName()) {
          defaultCollection = AllKnownLibraryCollections[collection];
        } /*if*/      
        
        // save the target collection if found:
        if (AllKnownLibraryCollections[collection].getName() === collectionName) {
          return (AllKnownLibraryCollections[collection]);
        } /*if*/
      } /*for*/
        
      /* default: */
      return (defaultCollection);
    }; /* findCollection */
    
    // establish all working data:
    installAllKnownLibraryCollections();
    var targetCollectionName = undefined;
    
    // if (collectionName) is supplied, install the list of known collections: 
    if ((collectionName.length > 0) && (collectionName[0])) {
      targetCollectionName = collectionName[0];
    } /*if*/
        
    // The RedWingTradingPostLibrarian has found a collection, return it
    return (findCollection(targetCollectionName));
  }; /* pickRedWingTradingPostLibraryImage */

  
  /***********************/
  /* IsUsableLibraryCard */
  /********************************************************************************************/
  /* This method provides a general check to very if a, particular, library card is usable.   */
  /* The (RedWingTradingPostLibrary) does contain a variety of unusable library elements.     */
  /* Many of the library objects may be placeholders or generally not usable within a         */
  /* specific context.                                                                        */
  /*                                                                                          */
  /* The (RedWingTradingPostLibrarian) maintains the following rules for determining whether  */
  /* a particular (RedWingLibraryCard) is usable:                                             */
  /*   (1) The library card represents an image that has a specified number of pixels width.  */
  /*   (2) The library card represents an image that has a specified number of pixels height. */
  /********************************************************************************************/
  static IsUsableLibraryCard(libraryCard) {
    var ok = ((libraryCard                 )  &&
              (libraryCard.getImageWidth ())  &&
              (libraryCard.getImageHeight()));
    
    return ok;
  }; /* IsUsableLibraryCard */

              
  /********/
  /* CTOR */
  /********************************************************************************************/
  /* constructor.  Perform any static operation required of the image library as managed by   */
  /* (RedWingTradingPostLibrarian).                                                           */
  /* (1) create static instantiations of all collections.  This (RedWingTradingPostLibrarian) */
  /*     can provide each of these instantiations as real objects for subsequent use.         */
  /*     See getCollection() for details.                                                     */
  /********************************************************************************************/
  constructor() {
    this.knownLibraryCollections =  {};
    this.knownLibraryCollections[RWTP_AllCollection.collectionName] = new RWTP_AllCollection();
    this.knownLibraryCollections[RWTP_AgoCollection.collectionName] = new RWTP_AgoCollection();
  }; /* CTOR */
}; /* RedWingTradingPostLibrarian */



