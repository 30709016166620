/*************************************/
/* RedWingTradingPostImageLibrary.js */
/**********************************************************************************************/
/* Single location for describing all images for Red Wing Trading Post.                       */
/*                                                                                            */    
/* To maintain the integrity of this library, this library will not grant access to either    */
/* itself nor any image owned by it.                                                          */
/* Use one of these methods to gain access to images of the library.                          */
/*                                                                                            */    
/* Copyright (C) 2019 - 2020 Orcadia Labs LLC                                                 */   
/**********************************************************************************************/
import {RedWingTradingPostLibraryCard} from './RedWingTradingPostLibraryCard.js';


/*****************************/
/* RedWingTradingPostLibrary */
/**********************************************************************************************/
/* This RedWingTradingPostLibrary object contains and maintains all images of the Red Wing    */
/* Trading Post image library.                                                                */           
/*                                                                                            */
/* LIBRARY:                                                                                   */
/* Semantic meanings for each sub-object:                                                     */
/*   key   the same as the key for the object, used to circularly reference this library      */
/*         object.                                                                            */
/*                                                                                            */
/*   image information about the image of this library object:                                */
/*         width    the width in pixels of this image                                         */
/*         height   the height in pixels of this image                                        */
/*         quality  a value from 1 to 10, 1 == poorest, 10 == best                            */
/*                  See below for an informal quality rating system.                          */
/*         fileName is the filename of this image.  All images are located at the same URL    */
/*                  and directory.  Each individual image may, perhaps, be stored in a        */
/*                  specific subdirectory.                                                    */
/*                                                                                            */
/*   object information about the physical object represented by this image object:           */
/*          widthCM is the centimeter width of this physical object.                          */
/*          * If this number is whole (26) this measurement is approximate only and rounds to */
/*            this whole number.                                                              */
/*          * If this number is real (19.3) this is exactly the width measurement to the      */
/*            nearest millimeter.  Even if the decimal portion is zero (5.0) this number is   */
/*            the precise measurement.                                                        */
/*                                                                                            */
/* UNDEFINED                                                                                  */
/* Many values in this library may be (undefined).  If not defined, then the specific         */
/* information is merely unavailable.  Values which may NOT be undefined are associated with  */
/* the following keys:                                                                        */
/*    key            - merely the name of the same key of this stack object.                  */
/*    image.width    - easily recovered from the actual image.                                */
/*                     This value may be undefined ONLY if (image.filename) is undefined.     */
/*    image.height   - easily recovered from the actual image.                                */
/*                     This value may be undefined ONLY if (image.filename) is undefined.     */
/*                                                                                            */
/* QUALITY                                                                                    */
/* Each library object image may be assigned a quality value from:                            */
/*     undefined == This library does not hold an image of this item (rare.)                  */
/*             1 == The image is the poorest possible quality.  Everything is awful with it.  */
/*            10 == The image is of the highest possible quality.  Nothing is wrong with it.  */
/* Every object with an unavailable image will have an image quality of zero.                 */
/* Every object with an available image will have an image quality greater than zero.         */
/*                                                                                            */
/* When evaluating the quality of an image, start with a value of 10 and deduct points for    */
/* any of the following flaws.  Additional points may be discretionarily deducted given image */
/* flaws not listed here.                                                                     */
/*     * 1-3 points if the vertical perspective of the object is not straight-on.             */
/*     * 1-3 points if the horizontal perspective of the object is not straight-on.           */
/*     * 1-4 points for when the image does not present the object optimally.  Deduct         */
/*                  points for reflections, items (like tablecloths, trash) in-the-way,       */
/*                  items which are not fully in-view, etc.                                   */
/*     * 1-3 points for camera flaws like blurs, grains, camera flash or overhead lighting    */
/*                  spots, inaccurate color representation, etc.                              */
/*     * 1 point    for camera resolution.  Subtract one point if the picture was taken by    */
/*                  a snapshot camera, zero if taken by a high-res or professional camera.    */
/*                  Subtract the point if the picture is fuzzy.                               */
/*     * 2-4 points for bad carving jobs.  For instance, the Corel background eraser was used */
/*                  to carve early images.  This tool leaves edges fuzzy and not              */
/*                  Later images were carved manually, are more well-defined and clean.       */ 
/*     * 1-2 points for image size.  Very small (pixel) images are undesirable.  Images which */
/*                  have a dimension of 250+ pixels are highly prefered.                      */ 
/*                                                                                            */
/**********************************************************************************************/
export const RedWingTradingPostLibrary = {

    /****************/
    /* Contemporary */
    /******************************************************************************************/
    /* The following Red Wing Trading Post Library images correspond to the more modern       */
    /* images.  I built these images after becoming more familiar with Corel Painshop and     */
    /* techniques of image creating.  As such, these images are of a significant higher       */
    /* quality than the "Ago" images at the bottom of this library.                           */
    /******************************************************************************************/

//  key                    name                             image's PNG information: dimensions (pixels,) image quality and filename                          actual object information
//                                                                                                                                                            width of actual object in centimeters
    RWCSbrand           : {key : 'RWCSbrand'           , image : {width : 474, height : 170, quality :  9, filename :  'RWCSbrand.png'           ,}, object : {widthCM : undefined},},
    RWTPRedWing         : {key : 'RWTPRedWing'         , image : {width : 500, height : 429, quality :  9, filename :  'RWTPRedWing.png'         ,}, object : {widthCM : undefined},},
    RWTPSkiOval         : {key : 'RWTPSkiOval'         , image : {width : 520, height : 327, quality : 10, filename :  'RWTPSkiOval.png'         ,}, object : {widthCM : undefined},},
    
    ashreceiver01       : {key : 'ashreceiver01'       , image : {width : 125, height : 250, quality :  5, filename :  'ashreceiver01.png'       ,}, object : {widthCM : undefined},},
    ashreceiver02       : {key : 'ashreceiver02'       , image : {width : 124, height : 250, quality :  6, filename :  'ashreceiver02.png'       ,}, object : {widthCM : undefined},},
    ashreceiver03       : {key : 'ashreceiver03'       , image : {width : 127, height : 250, quality :  5, filename :  'ashreceiver03.png'       ,}, object : {widthCM : undefined},},
    ashreceiver04       : {key : 'ashreceiver04'       , image : {width : 127, height : 250, quality :  7, filename :  'ashreceiver04.png'       ,}, object : {widthCM : undefined},},
    ashreceiver05       : {key : 'ashreceiver05'       , image : {width : 125, height : 250, quality :  6, filename :  'ashreceiver05.png'       ,}, object : {widthCM : undefined},},
    ashtray01           : {key : 'ashtray01'           , image : {width : 250, height : 137, quality :  6, filename :  'ashtray01.png'           ,}, object : {widthCM : undefined},},
    ashtray02           : {key : 'ashtray02'           , image : {width : 250, height : 231, quality :  5, filename :  'ashtray02.png'           ,}, object : {widthCM :  21  },},
    ashtray03           : {key : 'ashtray03'           , image : {width : 250, height : 222, quality :  5, filename :  'ashtray03.png'           ,}, object : {widthCM :  22  },},
    ashtray04           : {key : 'ashtray04'           , image : {width : 187, height : 250, quality :  7, filename :  'ashtray04.png'           ,}, object : {widthCM :  15.6},},
    ashtray05           : {key : 'ashtray05'           , image : {width : 192, height : 250, quality :  6, filename :  'ashtray05.png'           ,}, object : {widthCM :  15.6},},
    ashtray06           : {key : 'ashtray06'           , image : {width : 190, height : 250, quality :  7, filename :  'ashtray06.png'           ,}, object : {widthCM :  15.6},},
    ashtray07           : {key : 'ashtray07'           , image : {width : 183, height : 250, quality :  7, filename :  'ashtray07.png'           ,}, object : {widthCM :  15.6},},
    ashtray08           : {key : 'ashtray08'           , image : {width : 250, height : 109, quality :  4, filename :  'ashtray08.png'           ,}, object : {widthCM :  25.1},},
    ashtray09           : {key : 'ashtray09'           , image : {width : 250, height :  92, quality :  4, filename :  'ashtray09.png'           ,}, object : {widthCM :  28.6},},
    ashtray10           : {key : 'ashtray10'           , image : {width : 250, height : 158, quality :  4, filename :  'ashtray10.png'           ,}, object : {widthCM :  18  },},
    ashtray11           : {key : 'ashtray11'           , image : {width : 250, height :  65, quality :  4, filename :  'ashtray11.png'           ,}, object : {widthCM :  24.8},},
    ashtray12           : {key : 'ashtray12'           , image : {width : 250, height : 102, quality :  4, filename :  'ashtray12.png'           ,}, object : {widthCM :  20.0},},
    ashtray13           : {key : 'ashtray13'           , image : {width : 250, height : 250, quality :  3, filename :  'ashtray13.png'           ,}, object : {widthCM :  29  },},
    ashtray13a          : {key : 'ashtray13a'          , image : {width : 243, height : 250, quality :  3, filename :  'ashtray13a.png'          ,}, object : {widthCM :  14  },},
    ashtray13b          : {key : 'ashtray13b'          , image : {width : 241, height : 250, quality :  3, filename :  'ashtray13b.png'          ,}, object : {widthCM :  14  },},
    ashtray13c          : {key : 'ashtray13c'          , image : {width : 234, height : 250, quality :  3, filename :  'ashtray13c.png'          ,}, object : {widthCM :  14  },},
    ashtray13d          : {key : 'ashtray13d'          , image : {width : 250, height : 248, quality :  3, filename :  'ashtray13d.png'          ,}, object : {widthCM :  14  },},
    athena01            : {key : 'athena01'            , image : {width : 177, height : 250, quality :  7, filename :  'athena01.png'            ,}, object : {widthCM : undefined},},
    athena02            : {key : 'athena02'            , image : {width : 210, height : 250, quality :  5, filename :  'athena02.png'            ,}, object : {widthCM : undefined},},
    athena03            : {key : 'athena03'            , image : {width : 250, height : 207, quality :  4, filename :  'athena03.png'            ,}, object : {widthCM : undefined},},
    athena04            : {key : 'athena04'            , image : {width : 250, height : 171, quality :  2, filename :  'athena04.png'            ,}, object : {widthCM : undefined},},
    athena05            : {key : 'athena05'            , image : {width : 250, height : 218, quality :  8, filename :  'athena05.png'            ,}, object : {widthCM : undefined},},
    banjo01             : {key : 'banjo01'             , image : {width : 104, height : 250, quality :  7, filename :  'banjo01.png'             ,}, object : {widthCM : undefined},},
    barrel              : {key : 'barrel'              , image : {width : 235, height : 250, quality :  4, filename :  'barrel.png'              ,}, object : {widthCM : undefined},},
    beanpot01           : {key : 'beanpot01'           , image : {width : 250, height : 213, quality :  5, filename :  'beanpot01.png'           ,}, object : {widthCM : undefined},},
    beanpot02           : {key : 'beanpot02'           , image : {width : 250, height : 244, quality :  8, filename :  'beanpot02.png'           ,}, object : {widthCM : undefined},},
    beater01            : {key : 'beater01'            , image : {width : 222, height : 250, quality :  7, filename :  'beater01.png'            ,}, object : {widthCM : undefined},},
    beater02            : {key : 'beater02'            , image : {width : 236, height : 250, quality :  7, filename :  'beater02.png'            ,}, object : {widthCM : undefined},},
    beater03            : {key : 'beater03'            , image : {width : 250, height : 249, quality :  6, filename :  'beater03.png'            ,}, object : {widthCM : undefined},},
    birchbark01         : {key : 'birchbark01'         , image : {width : 250, height :  77, quality :  6, filename :  'birchbark01.png'         ,}, object : {widthCM : undefined},},
    birdbath01          : {key : 'birdbath01'          , image : {width : 181, height : 250, quality :  5, filename :  'birdbath01.png'          ,}, object : {widthCM : undefined},},
    birdbath02          : {key : 'birdbath02'          , image : {width : 188, height : 250, quality :  5, filename :  'birdbath02.png'          ,}, object : {widthCM : undefined},},
    bobwhite01          : {key : 'bobwhite01'          , image : {width : 250, height : 184, quality :  6, filename :  'bobwhite01.png'          ,}, object : {widthCM : undefined},},
    bobwhite02          : {key : 'bobwhite02'          , image : {width : 165, height : 250, quality :  6, filename :  'bobwhite02.png'          ,}, object : {widthCM : undefined},},
    bobwhite03          : {key : 'bobwhite03'          , image : {width : 107, height : 250, quality :  7, filename :  'bobwhite03.png'          ,}, object : {widthCM : undefined},},
    bobwhite04          : {key : 'bobwhite04'          , image : {width :  83, height : 250, quality :  8, filename :  'bobwhite04.png'          ,}, object : {widthCM : undefined},},
    bookend01           : {key : 'bookend01'           , image : {width : 250, height : 154, quality :  6, filename :  'bookend01.png'           ,}, object : {widthCM : undefined},},
    bookend02           : {key : 'bookend02'           , image : {width : 197, height : 250, quality :  7, filename :  'bookend02.png'           ,}, object : {widthCM : undefined},},
    bowl01              : {key : 'bowl01'              , image : {width : 250, height : 188, quality :  5, filename :  'bowl01.png'              ,}, object : {widthCM : undefined},},
    bowl02              : {key : 'bowl02'              , image : {width : 250, height : 129, quality :  5, filename :  'bowl02.png'              ,}, object : {widthCM : 21},},
    bowl03              : {key : 'bowl03'              , image : {width : 250, height :  82, quality :  6, filename :  'bowl03.png'              ,}, object : {widthCM : 25},},
    bowl04              : {key : 'bowl04'              , image : {width : 250, height : 170, quality :  6, filename :  'bowl04.png'              ,}, object : {widthCM : 18},},
    bowl05              : {key : 'bowl05'              , image : {width : 250, height : 116, quality :  6, filename :  'bowl05.png'              ,}, object : {widthCM : undefined},},
    bowl06              : {key : 'bowl06'              , image : {width : 250, height : 136, quality :  6, filename :  'bowl06.png'              ,}, object : {widthCM : undefined},},
    bowl07              : {key : 'bowl07'              , image : {width : 250, height : 134, quality :  7, filename :  'bowl07.png'              ,}, object : {widthCM : undefined},},
    bowl08              : {key : 'bowl08'              , image : {width : 250, height : 119, quality :  6, filename :  'bowl08.png'              ,}, object : {widthCM : undefined},},
    bowl09              : {key : 'bowl09'              , image : {width : 250, height :  58, quality :  7, filename :  'bowl09.png'              ,}, object : {widthCM : undefined},},
    bowl10              : {key : 'bowl10'              , image : {width : 250, height : 159, quality :  6, filename :  'bowl10.png'              ,}, object : {widthCM : undefined},},
    bowl11              : {key : 'bowl11'              , image : {width : 250, height : 141, quality :  6, filename :  'bowl11.png'              ,}, object : {widthCM : undefined},},
    bowl12              : {key : 'bowl12'              , image : {width : 250, height : 216, quality :  5, filename :  'bowl12.png'              ,}, object : {widthCM : undefined},},
    bowl13              : {key : 'bowl13'              , image : {width : 250, height : 159, quality :  4, filename :  'bowl13.png'              ,}, object : {widthCM : undefined},},
    bowl14              : {key : 'bowl14'              , image : {width : 242, height : 250, quality :  8, filename :  'bowl14.png'              ,}, object : {widthCM : undefined},},
    bowl15              : {key : 'bowl15'              , image : {width : 250, height : 147, quality :  5, filename :  'bowl15.png'              ,}, object : {widthCM : undefined},},
    bowl16              : {key : 'bowl16'              , image : {width : 250, height : 110, quality :  6, filename :  'bowl16.png'              ,}, object : {widthCM : undefined},},
    bowl17              : {key : 'bowl17'              , image : {width : 250, height : 132, quality :  9, filename :  'bowl17.png'              ,}, object : {widthCM : undefined},},
    bowl18              : {key : 'bowl18'              , image : {width : 250, height : 152, quality :  8, filename :  'bowl18.png'              ,}, object : {widthCM : undefined},},
    brennerberg         : {key : 'brennerberg'         , image : {width : 181, height : 250, quality :  5, filename :  'brennerberg.png'         ,}, object : {widthCM : undefined},},
    brittany02          : {key : 'brittany02'          , image : {width : 250, height : 111, quality :  6, filename :  'brittany02.png'          ,}, object : {widthCM : undefined},},
    brittany03          : {key : 'brittany03'          , image : {width : 195, height : 250, quality :  7, filename :  'brittany03.png'          ,}, object : {widthCM : undefined},},
    brittany03a         : {key : 'brittany03a'         , image : {width : 195, height : 250, quality :  7, filename :  'brittany03a.png'         ,}, object : {widthCM : undefined},},
    brittany04          : {key : 'brittany04'          , image : {width : 250, height : 117, quality :  6, filename :  'brittany04.png'          ,}, object : {widthCM : undefined},},
    buds01              : {key : 'buds01'              , image : {width : 250, height : 180, quality :  4, filename :  'buds01.png'              ,}, object : {widthCM : undefined},},
    buds02              : {key : 'buds02'              , image : {width : 157, height : 250, quality :  6, filename :  'buds02.png'              ,}, object : {widthCM : undefined},},
    butter01            : {key : 'butter01'            , image : {width : 250, height : 189, quality :  5, filename :  'butter01.png'            ,}, object : {widthCM : undefined},},
    butter02            : {key : 'butter02'            , image : {width : 250, height : 215, quality :  5, filename :  'butter02.png'            ,}, object : {widthCM : undefined},},
    butter03            : {key : 'butter03'            , image : {width : 250, height : 210, quality :  5, filename :  'butter03.png'            ,}, object : {widthCM : undefined},},
    butter04            : {key : 'butter04'            , image : {width : 250, height : 245, quality :  6, filename :  'butter04.png'            ,}, object : {widthCM : undefined},},
    butter05            : {key : 'butter05'            , image : {width : 200, height : 250, quality :  7, filename :  'butter05.png'            ,}, object : {widthCM : undefined},},
    butter06            : {key : 'butter06'            , image : {width : 250, height : 240, quality :  6, filename :  'butter06.png'            ,}, object : {widthCM : undefined},},
    butter07            : {key : 'butter07'            , image : {width : 250, height : 161, quality :  7, filename :  'butter07.png'            ,}, object : {widthCM : undefined},},
    butter08            : {key : 'butter08'            , image : {width : 190, height : 250, quality :  5, filename :  'butter08.png'            ,}, object : {widthCM : undefined},},
    butter09            : {key : 'butter09'            , image : {width : 225, height : 250, quality :  6, filename :  'butter09.png'            ,}, object : {widthCM : undefined},},
    butter10            : {key : 'butter10'            , image : {width : 208, height : 250, quality :  6, filename :  'butter10.png'            ,}, object : {widthCM : undefined},},
    butter11            : {key : 'butter11'            , image : {width : 250, height : 235, quality :  4, filename :  'butter11.png'            ,}, object : {widthCM : undefined},},
    butter12            : {key : 'butter12'            , image : {width : 250, height : 203, quality :  7, filename :  'butter12.png'            ,}, object : {widthCM : undefined},},
    candle01            : {key : 'candle01'            , image : {width : 250, height : 176, quality :  4, filename :  'candle01.png'            ,}, object : {widthCM : undefined},},
    candle02            : {key : 'candle02'            , image : {width : 250, height : 103, quality :  5, filename :  'candle02.png'            ,}, object : {widthCM : undefined},},
    candle03            : {key : 'candle03'            , image : {width : 142, height : 250, quality :  7, filename :  'candle03.png'            ,}, object : {widthCM : undefined},},
    candle04            : {key : 'candle04'            , image : {width : 250, height :  75, quality :  4, filename :  'candle04.png'            ,}, object : {widthCM : undefined},},
    candle05            : {key : 'candle05'            , image : {width : 250, height : 147, quality :  4, filename :  'candle05.png'            ,}, object : {widthCM : undefined},},
    candle06            : {key : 'candle06'            , image : {width : 250, height : 206, quality :  5, filename :  'candle06.png'            ,}, object : {widthCM : undefined},},
    candle07            : {key : 'candle07'            , image : {width : 250, height :  57, quality :  6, filename :  'candle07.png'            ,}, object : {widthCM : 30},},
    candle07a           : {key : 'candle07a'           , image : {width : 250, height : 112, quality :  6, filename :  'candle07a.png'           ,}, object : {widthCM : undefined},},
    candle08            : {key : 'candle08'            , image : {width : 250, height : 207, quality :  6, filename :  'candle08.png'            ,}, object : {widthCM : undefined},}, // notes may say width is 14.5 cm
    candle09            : {key : 'candle09'            , image : {width : 250, height : 249, quality :  5, filename :  'candle09.png'            ,}, object : {widthCM : 9.5},},
    candle10            : {key : 'candle10'            , image : {width : 250, height : 184, quality :  4, filename :  'candle10.png'            ,}, object : {widthCM : 22},},
    candle11            : {key : 'candle11'            , image : {width : 250, height : 216, quality :  6, filename :  'candle11.png'            ,}, object : {widthCM : 16.6},},
    candle12            : {key : 'candle12'            , image : {width : 238, height : 250, quality :  6, filename :  'candle12.png'            ,}, object : {widthCM : undefined},},
    candle12a           : {key : 'candle12a'           , image : {width : 106, height : 250, quality :  6, filename :  'candle12a.png'           ,}, object : {widthCM : undefined},},
    candle13            : {key : 'candle13'            , image : {width : 250, height : 181, quality :  4, filename :  'candle13.png'            ,}, object : {widthCM : undefined},},
    candle14            : {key : 'candle14'            , image : {width : 192, height : 250, quality :  8, filename :  'candle14.png'            ,}, object : {widthCM : undefined},},
    candle15            : {key : 'candle15'            , image : {width : 250, height : 125, quality :  7, filename :  'candle15.png'            ,}, object : {widthCM : undefined},},
    candle16            : {key : 'candle16'            , image : {width : 138, height : 250, quality :  7, filename :  'candle16.png'            ,}, object : {widthCM : undefined},},
    canning01           : {key : 'canning01'           , image : {width : 176, height : 250, quality :  3, filename :  'canning01.png'           ,}, object : {widthCM : undefined},},
    canning02           : {key : 'canning02'           , image : {width : 149, height : 250, quality :  5, filename :  'canning02.png'           ,}, object : {widthCM : undefined},},
    canning03           : {key : 'canning03'           , image : {width : 144, height : 250, quality :  3, filename :  'canning03.png'           ,}, object : {widthCM : undefined},},
    caprice01           : {key : 'caprice01'           , image : {width : 250, height : 226, quality :  7, filename :  'caprice01.png'           ,}, object : {widthCM : undefined},},
    cattail             : {key : 'cattail'             , image : {width : 250, height : 140, quality :  5, filename :  'cattail.png'             ,}, object : {widthCM : undefined},},
    centerpiece01       : {key : 'centerpiece01'       , image : {width : 250, height : 200, quality :  6, filename :  'centerpiece01.png'       ,}, object : {widthCM : undefined},},
    ceramastone01       : {key : 'ceramastone01'       , image : {width : 259, height : 250, quality :  7, filename :  'ceramastone01.png'       ,}, object : {widthCM : undefined},},
    ceramastone02       : {key : 'ceramastone02'       , image : {width : 250, height :  87, quality :  7, filename :  'ceramastone02.png'       ,}, object : {widthCM : undefined},},
    charstonebleu01     : {key : 'charstonebleu01'     , image : {width : 250, height : 184, quality :  8, filename :  'charstonebleu01.png'     ,}, object : {widthCM : undefined},},
    charstonebleu02     : {key : 'charstonebleu02'     , image : {width : 146, height : 250, quality :  5, filename :  'charstonebleu02.png'     ,}, object : {widthCM : undefined},},
    cherryband01        : {key : 'cherryband01'        , image : {width : 194, height : 250, quality :  7, filename :  'cherryband01.png'        ,}, object : {widthCM : undefined},},
    cherub              : {key : 'cherub'              , image : {width : 156, height : 250, quality :  4, filename :  'cherub.png'              ,}, object : {widthCM : undefined},},
    chevron01           : {key : 'chevron01'           , image : {width : 250, height : 250, quality :  6, filename :  'chevron01.png'           ,}, object : {widthCM : 25.4},},
    chevron02           : {key : 'chevron02'           , image : {width : 250, height : 192, quality :  6, filename :  'chevron02.png'           ,}, object : {widthCM : 19.1},},
    chevron03           : {key : 'chevron03'           , image : {width : 134, height : 250, quality :  5, filename :  'chevron03.png'           ,}, object : {widthCM : undefined},},
    chevron04           : {key : 'chevron04'           , image : {width : 135, height : 250, quality :  5, filename :  'chevron04.png'           ,}, object : {widthCM : undefined},},
    chicken01           : {key : 'chicken01'           , image : {width : 194, height : 250, quality :  7, filename :  'chicken01.png'           ,}, object : {widthCM : undefined},},
    chromoline01        : {key : 'chromoline01'        , image : {width :  94, height : 250, quality :  4, filename :  'chromoline01.png'        ,}, object : {widthCM : 12.2},},
    chromoline02        : {key : 'chromoline02'        , image : {width : 250, height : 132, quality :  5, filename :  'chromoline02.png'        ,}, object : {widthCM : undefined},},
    chromoline03        : {key : 'chromoline03'        , image : {width : 104, height : 250, quality :  5, filename :  'chromoline03.png'        ,}, object : {widthCM : undefined},},
    chromoline04        : {key : 'chromoline04'        , image : {width :  70, height : 250, quality :  4, filename :  'chromoline04.png'        ,}, object : {widthCM : undefined},},
    chromoline05        : {key : 'chromoline05'        , image : {width : 250, height : 155, quality :  5, filename :  'chromoline05.png'        ,}, object : {widthCM : undefined},},
    chrysanthemum01     : {key : 'chrysanthemum01'     , image : {width : 250, height : 248, quality :  4, filename :  'chrysanthemum01.png'     ,}, object : {widthCM : undefined},},
    churn6              : {key : 'churn6'              , image : {width : 147, height : 250, quality :  6, filename :  'churn6.png'              ,}, object : {widthCM : undefined},},
    churn07             : {key : 'churn07'             , image : {width : 156, height : 250, quality :  6, filename :  'churn07.png'             ,}, object : {widthCM : undefined},},
    churn08             : {key : 'churn08'             , image : {width : 137, height : 250, quality :  5, filename :  'churn08.png'             ,}, object : {widthCM : undefined},},
    churn09             : {key : 'churn09'             , image : {width : 128, height : 250, quality :  6, filename :  'churn09.png'             ,}, object : {widthCM : undefined},},
    churn10             : {key : 'churn10'             , image : {width : 164, height : 250, quality :  6, filename :  'churn10.png'             ,}, object : {widthCM : undefined},},
    churn11             : {key : 'churn11'             , image : {width : 173, height : 250, quality :  5, filename :  'churn11.png'             ,}, object : {widthCM : undefined},},
    churn12             : {key : 'churn12'             , image : {width : 148, height : 250, quality :  6, filename :  'churn12.png'             ,}, object : {widthCM : undefined},},
    churn13             : {key : 'churn13'             , image : {width : 163, height : 250, quality :  7, filename :  'churn13.png'             ,}, object : {widthCM : undefined},},
    churnsg5            : {key : 'churnsg5'            , image : {width : 155, height : 250, quality :  4, filename :  'churnsg5.png'            ,}, object : {widthCM : undefined},},
    compote01           : {key : 'compote01'           , image : {width : 243, height : 250, quality :  6, filename :  'compote01.png'           ,}, object : {widthCM : undefined},},
    compote02           : {key : 'compote02'           , image : {width : 250, height : 153, quality :  7, filename :  'compote02.png'           ,}, object : {widthCM : undefined},},
    compote03           : {key : 'compote03'           , image : {width : 250, height : 154, quality :  6, filename :  'compote03.png'           ,}, object : {widthCM : 20.5},},
    compote04           : {key : 'compote04'           , image : {width : 250, height : 159, quality :  4, filename :  'compote04.png'           ,}, object : {widthCM : 14.7},},
    compote05           : {key : 'compote05'           , image : {width : 250, height : 216, quality :  5, filename :  'compote05.png'           ,}, object : {widthCM : undefined},},
    compote06           : {key : 'compote06'           , image : {width : 250, height : 148, quality :  6, filename :  'compote06.png'           ,}, object : {widthCM : 20},},
    compote07           : {key : 'compote07'           , image : {width : 250, height : 150, quality :  6, filename :  'compote07.png'           ,}, object : {widthCM : 34},},
    compote08           : {key : 'compote08'           , image : {width : 250, height : 117, quality :  6, filename :  'compote08.png'           ,}, object : {widthCM : 25.8},},
    compote09           : {key : 'compote09'           , image : {width : 250, height : 160, quality :  5, filename :  'compote09.png'           ,}, object : {widthCM : undefined},},
    compote10           : {key : 'compote09'           , image : {width : 250, height : 171, quality :  4, filename :  'compote10.png'           ,}, object : {widthCM : undefined},},
    console01           : {key : 'console01'           , image : {width : 250, height :  75, quality :  9, filename :  'console01.png'           ,}, object : {widthCM : undefined},},
    cookie01            : {key : 'cookie01'            , image : {width : 199, height : 250, quality :  8, filename :  'cookie01.png'            ,}, object : {widthCM : undefined},},
    cookie02            : {key : 'cookie02'            , image : {width : 170, height : 250, quality :  5, filename :  'cookie02.png'            ,}, object : {widthCM : undefined},},
    cookie03            : {key : 'cookie03'            , image : {width : 247, height : 250, quality :  7, filename :  'cookie03.png'            ,}, object : {widthCM : undefined},},
    cooler01            : {key : 'cooler01'            , image : {width : 168, height : 250, quality :  6, filename :  'cooler01.png'            ,}, object : {widthCM : undefined},},
    cooler02            : {key : 'cooler02'            , image : {width : 184, height : 250, quality :  5, filename :  'cooler02.png'            ,}, object : {widthCM : undefined},},
    cooler03            : {key : 'cooler03'            , image : {width : 160, height : 250, quality :  8, filename :  'cooler03.png'            ,}, object : {widthCM : undefined},},
    cooler04            : {key : 'cooler04'            , image : {width : 157, height : 250, quality :  6, filename :  'cooler04.png'            ,}, object : {widthCM : undefined},},
    cooler05            : {key : 'cooler05'            , image : {width : 204, height : 250, quality :  6, filename :  'cooler05.png'            ,}, object : {widthCM : undefined},},
    cooler06            : {key : 'cooler06'            , image : {width : 169, height : 250, quality :  6, filename :  'cooler06.png'            ,}, object : {widthCM : undefined},},
    cooler07            : {key : 'cooler07'            , image : {width : 211, height : 250, quality :  7, filename :  'cooler07.png'            ,}, object : {widthCM : undefined},},
    countrygarden01     : {key : 'countrygarden01'     , image : {width : 250, height : 168, quality :  4, filename :  'countrygarden01.png'     ,}, object : {widthCM : undefined},},
    countrygarden02     : {key : 'countrygarden02'     , image : {width : 170, height : 250, quality :  6, filename :  'countrygarden02.png'     ,}, object : {widthCM : 15},},
    countrygarden03     : {key : 'countrygarden03'     , image : {width : 250, height : 250, quality :  8, filename :  'countrygarden03.png'     ,}, object : {widthCM : 20.1},},
    countrygarden04     : {key : 'countrygarden04'     , image : {width : 250, height : 199, quality :  6, filename :  'countrygarden04.png'     ,}, object : {widthCM : 38.3},},
    crazyrhythm01       : {key : 'crazyrhythm01'       , image : {width : 250, height : 223, quality :  6, filename :  'crazyrhythm01.png'       ,}, object : {widthCM : undefined},},
    crazyrhythm02       : {key : 'crazyrhythm02'       , image : {width : 250, height : 173, quality :  6, filename :  'crazyrhythm02.png'       ,}, object : {widthCM : undefined},},
    crock01             : {key : 'crock01'             , image : {width : 219, height : 250, quality :  6, filename :  'crock01.png'             ,}, object : {widthCM : undefined},},
    crock02             : {key : 'crock02'             , image : {width : 216, height : 250, quality :  6, filename :  'crock02.png'             ,}, object : {widthCM : undefined},},
    crock03             : {key : 'crock03'             , image : {width : 223, height : 250, quality :  6, filename :  'crock03.png'             ,}, object : {widthCM : undefined},},
    crock04             : {key : 'crock04'             , image : {width : 208, height : 250, quality :  5, filename :  'crock04.png'             ,}, object : {widthCM : undefined},},
    crock5              : {key : 'crock5'              , image : {width : 225, height : 250, quality :  5, filename :  'crock5.png'              ,}, object : {widthCM : undefined},},
    crock05             : {key : 'crock05'             , image : {width : 203, height : 250, quality :  7, filename :  'crock05.png'             ,}, object : {widthCM : undefined},},
    crock06             : {key : 'crock06'             , image : {width : 198, height : 250, quality :  7, filename :  'crock06.png'             ,}, object : {widthCM : undefined},},
    crock07             : {key : 'crock07'             , image : {width : 187, height : 250, quality :  6, filename :  'crock07.png'             ,}, object : {widthCM : undefined},},
    crock08             : {key : 'crock08'             , image : {width : 187, height : 250, quality :  5, filename :  'crock08.png'             ,}, object : {widthCM : undefined},},
    crock09             : {key : 'crock09'             , image : {width : 199, height : 250, quality :  4, filename :  'crock09.png'             ,}, object : {widthCM : undefined},},
    crock10             : {key : 'crock10'             , image : {width : 186, height : 250, quality :  8, filename :  'crock10.png'             ,}, object : {widthCM : undefined},},
    crock10sg           : {key : 'crock10sg'           , image : {width : 205, height : 250, quality :  5, filename :  'crock10sg.png'           ,}, object : {widthCM : undefined},},
    crock11             : {key : 'crock11'             , image : {width : 204, height : 250, quality :  7, filename :  'crock11.png'             ,}, object : {widthCM : undefined},},
    crock12             : {key : 'crock12'             , image : {width : 225, height : 250, quality :  5, filename :  'crock12.png'             ,}, object : {widthCM : undefined},},
    crock13             : {key : 'crock13'             , image : {width : 233, height : 250, quality :  6, filename :  'crock13.png'             ,}, object : {widthCM : undefined},},
    crock14             : {key : 'crock14'             , image : {width : 203, height : 250, quality :  7, filename :  'crock14.png'             ,}, object : {widthCM : undefined},},
    crock15             : {key : 'crock15'             , image : {width : 209, height : 250, quality :  5, filename :  'crock15.png'             ,}, object : {widthCM : undefined},},
    crock16             : {key : 'crock16'             , image : {width : 183, height : 250, quality :  7, filename :  'crock16.png'             ,}, object : {widthCM : undefined},},
    crock17             : {key : 'crock17'             , image : {width : 184, height : 250, quality :  6, filename :  'crock17.png'             ,}, object : {widthCM : undefined},},
    crock18             : {key : 'crock18'             , image : {width : 212, height : 250, quality :  5, filename :  'crock18.png'             ,}, object : {widthCM : undefined},},
    crock19             : {key : 'crock19'             , image : {width : 181, height : 250, quality :  7, filename :  'crock19.png'             ,}, object : {widthCM : undefined},},
    crock20             : {key : 'crock20'             , image : {width : 179, height : 250, quality :  6, filename :  'crock20.png'             ,}, object : {widthCM : undefined},},
    crock21             : {key : 'crock21'             , image : {width : 192, height : 250, quality :  7, filename :  'crock21.png'             ,}, object : {widthCM : undefined},},
    crock22             : {key : 'crock22'             , image : {width : 229, height : 250, quality :  7, filename :  'crock22.png'             ,}, object : {widthCM : undefined},},
    crock23             : {key : 'crock23'             , image : {width : 198, height : 250, quality :  7, filename :  'crock23.png'             ,}, object : {widthCM : undefined},},
    crock41             : {key : 'crock41'             , image : {width : 181, height : 250, quality :  4, filename :  'crock41.png'             ,}, object : {widthCM : undefined},},
    crock42             : {key : 'crock42'             , image : {width : 188, height : 250, quality :  5, filename :  'crock42.png'             ,}, object : {widthCM : undefined},},
    crock43             : {key : 'crock43'             , image : {width : 219, height : 250, quality :  6, filename :  'crock43.png'             ,}, object : {widthCM : undefined},},
    crock44             : {key : 'crock44'             , image : {width : 203, height : 250, quality :  8, filename :  'crock44.png'             ,}, object : {widthCM : undefined},},
    crock45             : {key : 'crock45'             , image : {width : 249, height : 250, quality :  6, filename :  'crock45.png'             ,}, object : {widthCM : undefined},},
    crock46             : {key : 'crock46'             , image : {width : 202, height : 250, quality :  6, filename :  'crock46.png'             ,}, object : {widthCM : undefined},},
    crock47             : {key : 'crock47'             , image : {width : 222, height : 250, quality :  7, filename :  'crock47.png'             ,}, object : {widthCM : undefined},},
    crock48             : {key : 'crock48'             , image : {width : 187, height : 250, quality :  8, filename :  'crock48.png'             ,}, object : {widthCM : undefined},},
    crock49             : {key : 'crock49'             , image : {width : 202, height : 250, quality :  4, filename :  'crock49.png'             ,}, object : {widthCM : undefined},},
    crockevans          : {key : 'crockevans'          , image : {width : 216, height : 250, quality :  6, filename :  'crockevans.png'          ,}, object : {widthCM : undefined},},
    crockwing5          : {key : 'crockwing5'          , image : {width : 232, height : 250, quality :  6, filename :  'crockwing5.png'          ,}, object : {widthCM : undefined},},
    daisychain01        : {key : 'daisychain01'        , image : {width : 185, height : 250, quality :  4, filename :  'daisychain01.png'        ,}, object : {widthCM : undefined},},
    daisychain02        : {key : 'daisychain02'        , image : {width : 250, height : 250, quality :  6, filename :  'daisychain02.png'        ,}, object : {widthCM : undefined},},
    daisychain03        : {key : 'daisychain03'        , image : {width : 240, height : 250, quality :  4, filename :  'daisychain03.png'        ,}, object : {widthCM : undefined},},
    daisychain04        : {key : 'daisychain04'        , image : {width : 247, height : 250, quality :  5, filename :  'daisychain04.png'        ,}, object : {widthCM : undefined},},
    daisychain05        : {key : 'daisychain05'        , image : {width : 198, height : 250, quality :  5, filename :  'daisychain05.png'        ,}, object : {widthCM : undefined},},
    daisychain06        : {key : 'daisychain06'        , image : {width : 250, height : 143, quality :  6, filename :  'daisychain06.png'        ,}, object : {widthCM : undefined},},
    daisychain07        : {key : 'daisychain07'        , image : {width : 250, height : 158, quality :  7, filename :  'daisychain07.png'        ,}, object : {widthCM : undefined},},
    desert01            : {key : 'desert01'            , image : {width : 250, height : 175, quality :  6, filename :  'desert01.png'            ,}, object : {widthCM : undefined},},
    desert02            : {key : 'desert02'            , image : {width : 250, height : 151, quality :  4, filename :  'desert02.png'            ,}, object : {widthCM : undefined},},
    desert03            : {key : 'desert03'            , image : {width : 250, height : 212, quality :  5, filename :  'desert03.png'            ,}, object : {widthCM : undefined},},
    elephant            : {key : 'elephant'            , image : {width : 250, height : 216, quality :  3, filename :  'elephant.png'            ,}, object : {widthCM : undefined},},
    fantasy01           : {key : 'fantasy01'           , image : {width : 138, height : 250, quality :  5, filename :  'fantasy01.png'           ,}, object : {widthCM : undefined},},
    figure01            : {key : 'figure01'            , image : {width :  82, height : 250, quality :  6, filename :  'figure01.png'            ,}, object : {widthCM : undefined},},
    figure03            : {key : 'figure03'            , image : {width : 250, height : 133, quality :  6, filename :  'figure03.png'            ,}, object : {widthCM : undefined},},
    figure04            : {key : 'figure04'            , image : {width : 100, height : 250, quality :  4, filename :  'figure04.png'            ,}, object : {widthCM : undefined},},
    figure05            : {key : 'figure05'            , image : {width : 216, height : 250, quality :  5, filename :  'figure05.png'            ,}, object : {widthCM : undefined},},
    figure06            : {key : 'figure06'            , image : {width : 250, height : 248, quality :  8, filename :  'figure06.png'            ,}, object : {widthCM : undefined},},
    figure07            : {key : 'figure07'            , image : {width : 239, height : 250, quality :  5, filename :  'figure07.png'            ,}, object : {widthCM : undefined},},
    figure08            : {key : 'figure08'            , image : {width : 201, height : 250, quality :  7, filename :  'figure08.png'            ,}, object : {widthCM : undefined},},
    figure09            : {key : 'figure09'            , image : {width : 195, height : 250, quality :  7, filename :  'figure09.png'            ,}, object : {widthCM : undefined},},
    figure10            : {key : 'figure10'            , image : {width :  99, height : 250, quality :  5, filename :  'figure10.png'            ,}, object : {widthCM : undefined},},
    figure11            : {key : 'figure11'            , image : {width :  95, height : 250, quality :  6, filename :  'figure11.png'            ,}, object : {widthCM : undefined},},
    figure12            : {key : 'figure12'            , image : {width :  50, height : 250, quality :  7, filename :  'figure12.png'            ,}, object : {widthCM : undefined},},
    figure13            : {key : 'figure13'            , image : {width :  80, height : 250, quality :  7, filename :  'figure13.png'            ,}, object : {widthCM : undefined},},
    figure14            : {key : 'figure14'            , image : {width :  96, height : 250, quality :  6, filename :  'figure14.png'            ,}, object : {widthCM : undefined},},
    figure15            : {key : 'figure15'            , image : {width :  97, height : 250, quality :  8, filename :  'figure15.png'            ,}, object : {widthCM : undefined},},
    figure16            : {key : 'figure16'            , image : {width : 203, height : 250, quality :  7, filename :  'figure16.png'            ,}, object : {widthCM : undefined},},
    figure17            : {key : 'figure17'            , image : {width : 179, height : 250, quality :  6, filename :  'figure17.png'            ,}, object : {widthCM : undefined},},
    figure18            : {key : 'figure18'            , image : {width : 178, height : 250, quality :  6, filename :  'figure18.png'            ,}, object : {widthCM : undefined},},
    figure19            : {key : 'figure19'            , image : {width : 132, height : 250, quality :  8, filename :  'figure19.png'            ,}, object : {widthCM : undefined},},
    figure20            : {key : 'figure20'            , image : {width :  53, height : 250, quality :  6, filename :  'figure20.png'            ,}, object : {widthCM : undefined},},
    filter15            : {key : 'filter15'            , image : {width : 161, height : 250, quality :  4, filename :  'filter15.png'            ,}, object : {widthCM : undefined},},
    flight01            : {key : 'flight01'            , image : {width : 249, height : 250, quality :  8, filename :  'flight01.png'            ,}, object : {widthCM : undefined},},
    floorvase           : {key : 'floorvase'           , image : {width : 195, height : 250, quality :  4, filename :  'floorvase.png'           ,}, object : {widthCM : undefined},},
    fondoso01           : {key : 'fondoso01'           , image : {width : 250, height : 247, quality :  7, filename :  'fondoso01.png'           ,}, object : {widthCM : 23.7},},
    fruit01             : {key : 'fruit01'             , image : {width : 250, height : 249, quality :  6, filename :  'fruit01.png'             ,}, object : {widthCM : undefined},},
    greek1              : {key : 'greek1'              , image : {width : 156, height : 250, quality :  6, filename :  'greek1.png'              ,}, object : {widthCM : undefined},},
    greek2              : {key : 'greek2'              , image : {width : 151, height : 250, quality :  5, filename :  'greek2.png'              ,}, object : {widthCM : undefined},},
    greenvase           : {key : 'greenvase'           , image : {width : 166, height : 250, quality :  5, filename :  'greenvase.png'           ,}, object : {widthCM : undefined},},
    greyline01          : {key : 'greyline01'          , image : {width : 200, height : 250, quality :  6, filename :  'greyline01.png'          ,}, object : {widthCM : undefined},},
    greyline02          : {key : 'greyline02'          , image : {width : 250, height : 194, quality :  4, filename :  'greyline02.png'          ,}, object : {widthCM : undefined},},
    greyline02a         : {key : 'greyline02a'         , image : {width : 144, height : 250, quality :  4, filename :  'greyline02a.png'         ,}, object : {widthCM : undefined},},
    greyline03          : {key : 'greyline03'          , image : {width : 250, height : 249, quality :  9, filename :  'greyline03.png'          ,}, object : {widthCM : undefined},},
    greyline04          : {key : 'greyline04'          , image : {width : 250, height : 148, quality :  8, filename :  'greyline04.png'          ,}, object : {widthCM : undefined},},
    greyline05          : {key : 'greyline05'          , image : {width : 208, height : 250, quality :  6, filename :  'greyline05.png'          ,}, object : {widthCM : undefined},},
    greyline06          : {key : 'greyline06'          , image : {width : 197, height : 250, quality :  7, filename :  'greyline06.png'          ,}, object : {widthCM : undefined},},
    greyline07          : {key : 'greyline07'          , image : {width : 209, height : 250, quality :  7, filename :  'greyline07.png'          ,}, object : {widthCM : undefined},},
    gypsytrail01        : {key : 'gypsytrail01'        , image : {width : 250, height : 228, quality :  7, filename :  'gypsytrail01.png'        ,}, object : {widthCM : undefined},},
    hamms01             : {key : 'hamms01'             , image : {width : 250, height : 213, quality :  8, filename :  'hamms01.png'             ,}, object : {widthCM : undefined},},
    hankscraft01        : {key : 'hankscraft01'        , image : {width : 177, height : 250, quality :  7, filename :  'hankscraft01.png'        ,}, object : {widthCM : 16},},
    hankscraft02        : {key : 'hankscraft02'        , image : {width : 176, height : 250, quality :  7, filename :  'hankscraft02.png'        ,}, object : {widthCM : 16},},
    harvest01           : {key : 'harvest01'           , image : {width : 149, height : 250, quality :  7, filename :  'harvest01.png'           ,}, object : {widthCM : undefined},},
    hearthside01        : {key : 'hearthside01'        , image : {width : 220, height : 250, quality :  8, filename :  'hearthside01.png'        ,}, object : {widthCM : 13.1},},
    hearthside02        : {key : 'hearthside02'        , image : {width : 128, height : 250, quality :  6, filename :  'hearthside02.png'        ,}, object : {widthCM : 16},},
    hotel01             : {key : 'hotel01'             , image : {width : 250, height : 181, quality :  7, filename :  'hotel01.png'             ,}, object : {widthCM : undefined},},
    icewater            : {key : 'icewater'            , image : {width : 174, height : 250, quality :  5, filename :  'icewater.png'            ,}, object : {widthCM : undefined},},
    iris02              : {key : 'iris02'              , image : {width : 250, height : 250, quality :  7, filename :  'iris02.png'              ,}, object : {widthCM : undefined},},
    jug04               : {key : 'jug04'               , image : {width : 193, height : 250, quality :  5, filename :  'jug04.png'               ,}, object : {widthCM : 18},},
    jug05               : {key : 'jug05'               , image : {width : 149, height : 250, quality :  5, filename :  'jug05.png'               ,}, object : {widthCM : 15},},
    jug06               : {key : 'jug06'               , image : {width : 136, height : 250, quality :  6, filename :  'jug06.png'               ,}, object : {widthCM : undefined},},
    jug07               : {key : 'jug07'               , image : {width : 152, height : 250, quality :  6, filename :  'jug07.png'               ,}, object : {widthCM : undefined},},
    jug08               : {key : 'jug08'               , image : {width : 165, height : 250, quality :  6, filename :  'jug08.png'               ,}, object : {widthCM : undefined},},
    jug09               : {key : 'jug09'               , image : {width : 156, height : 250, quality :  6, filename :  'jug09.png'               ,}, object : {widthCM : undefined},},
    jug10               : {key : 'jug10'               , image : {width : 123, height : 250, quality :  6, filename :  'jug10.png'               ,}, object : {widthCM : undefined},},
    jug11               : {key : 'jug11'               , image : {width : 133, height : 250, quality :  7, filename :  'jug11.png'               ,}, object : {widthCM : undefined},},
    jug12               : {key : 'jug12'               , image : {width : 181, height : 250, quality :  6, filename :  'jug12.png'               ,}, object : {widthCM : undefined},},
    jug13               : {key : 'jug13'               , image : {width : 154, height : 250, quality :  7, filename :  'jug13.png'               ,}, object : {widthCM : undefined},},
    jug14               : {key : 'jug14'               , image : {width : 146, height : 250, quality :  7, filename :  'jug14.png'               ,}, object : {widthCM : undefined},},
    jug15               : {key : 'jug15'               , image : {width : 154, height : 250, quality :  8, filename :  'jug15.png'               ,}, object : {widthCM : undefined},},
    jug16               : {key : 'jug16'               , image : {width : 148, height : 250, quality :  8, filename :  'jug16.png'               ,}, object : {widthCM : undefined},},
    jug17               : {key : 'jug17'               , image : {width : 149, height : 250, quality :  7, filename :  'jug17.png'               ,}, object : {widthCM : undefined},},
    jug18               : {key : 'jug18'               , image : {width : 162, height : 250, quality :  5, filename :  'jug18.png'               ,}, object : {widthCM : undefined},},
    jug19               : {key : 'jug19'               , image : {width : 171, height : 250, quality :  6, filename :  'jug19.png'               ,}, object : {widthCM : undefined},},
    jug20               : {key : 'jug20'               , image : {width : 158, height : 250, quality :  4, filename :  'jug20.png'               ,}, object : {widthCM : undefined},},
    jug21               : {key : 'jug21'               , image : {width : 154, height : 250, quality :  5, filename :  'jug21.png'               ,}, object : {widthCM : undefined},},
    jug22               : {key : 'jug22'               , image : {width : 150, height : 250, quality :  7, filename :  'jug22.png'               ,}, object : {widthCM : undefined},},
    jug23               : {key : 'jug23'               , image : {width : 132, height : 250, quality :  7, filename :  'jug23.png'               ,}, object : {widthCM : undefined},},
    jug24               : {key : 'jug24'               , image : {width : 182, height : 250, quality :  6, filename :  'jug24.png'               ,}, object : {widthCM : undefined},}, 
    jug25               : {key : 'jug25'               , image : {width : 158, height : 250, quality :  3, filename :  'jug25.png'               ,}, object : {widthCM : undefined},},
    jug26               : {key : 'jug26'               , image : {width : 158, height : 250, quality :  5, filename :  'jug26.png'               ,}, object : {widthCM : undefined},},
    jug27               : {key : 'jug27'               , image : {width : 137, height : 250, quality :  7, filename :  'jug27.png'               ,}, object : {widthCM : undefined},},
    jug28               : {key : 'jug28'               , image : {width : 182, height : 250, quality :  6, filename :  'jug28.png'               ,}, object : {widthCM : undefined},},
    jug29               : {key : 'jug29'               , image : {width : 172, height : 250, quality :  6, filename :  'jug29.png'               ,}, object : {widthCM : undefined},},
    jug30               : {key : 'jug30'               , image : {width : 156, height : 250, quality :  6, filename :  'jug30.png'               ,}, object : {widthCM : undefined},},
    jug31               : {key : 'jug31'               , image : {width : 150, height : 250, quality :  8, filename :  'jug31.png'               ,}, object : {widthCM : undefined},},
    jug32               : {key : 'jug32'               , image : {width : 147, height : 250, quality :  6, filename :  'jug32.png'               ,}, object : {widthCM : undefined},},
    jug33               : {key : 'jug33'               , image : {width : 165, height : 250, quality :  5, filename :  'jug33.png'               ,}, object : {widthCM : undefined},},
    jug34               : {key : 'jug34'               , image : {width : 160, height : 250, quality :  8, filename :  'jug34.png'               ,}, object : {widthCM : undefined},},
    jug35               : {key : 'jug35'               , image : {width : 135, height : 250, quality :  6, filename :  'jug35.png'               ,}, object : {widthCM : undefined},},
    jug36               : {key : 'jug36'               , image : {width : 153, height : 250, quality :  6, filename :  'jug36.png'               ,}, object : {widthCM : undefined},},
    jug37               : {key : 'jug37'               , image : {width : 156, height : 250, quality :  5, filename :  'jug37.png'               ,}, object : {widthCM : undefined},},
    jug38               : {key : 'jug38'               , image : {width : 146, height : 250, quality :  5, filename :  'jug38.png'               ,}, object : {widthCM : undefined},},
    jug39               : {key : 'jug39'               , image : {width : 134, height : 250, quality :  6, filename :  'jug39.png'               ,}, object : {widthCM : undefined},},
    jug40               : {key : 'jug40'               , image : {width : 154, height : 250, quality :  8, filename :  'jug40.png'               ,}, object : {widthCM : undefined},},
    jug41               : {key : 'jug41'               , image : {width : 160, height : 250, quality :  4, filename :  'jug41.png'               ,}, object : {widthCM : undefined},},
    jugcal              : {key : 'jugcal'              , image : {width : 167, height : 250, quality :  4, filename :  'jugcal.png'              ,}, object : {widthCM : undefined},},
    jugnapa             : {key : 'jugnapa'             , image : {width : 159, height : 250, quality :  5, filename :  'jugnapa.png'             ,}, object : {widthCM : undefined},},
    kashmir01           : {key : 'kashmir01'           , image : {width : 250, height : 249, quality :  7, filename :  'kashmir01.png'           ,}, object : {widthCM : 18.8},},
    kermis01            : {key : 'kermis01'            , image : {width : 250, height : 247, quality :  4, filename :  'kermis01.png'            ,}, object : {widthCM : undefined},},
    lamp1               : {key : 'lamp1'               , image : {width : 193, height : 250, quality :  6, filename :  'lamp1.png'               ,}, object : {widthCM : undefined},},
    lamp02              : {key : 'lamp02'              , image : {width : 129, height : 250, quality :  8, filename :  'lamp02.png'              ,}, object : {widthCM : undefined},},
    lanterns01          : {key : 'lanterns01'          , image : {width : 250, height : 250, quality :  6, filename :  'lanterns01.png'          ,}, object : {widthCM : 26.4},},
    lanterns02          : {key : 'lanterns02'          , image : {width : 250, height : 136, quality :  7, filename :  'lanterns02.png'          ,}, object : {widthCM : undefined},},
    lanterns03          : {key : 'lanterns03'          , image : {width : 250, height : 249, quality :  8, filename :  'lanterns03.png'          ,}, object : {widthCM : undefined},},
    lanterns04          : {key : 'lanterns04'          , image : {width : 250, height : 249, quality :  6, filename :  'lanterns04.png'          ,}, object : {widthCM : undefined},},
    lanterns05          : {key : 'lanterns05'          , image : {width : 250, height : 149, quality :  7, filename :  'lanterns05.png'          ,}, object : {widthCM : undefined},},
    leafmagic01         : {key : 'leafmagic01'         , image : {width : 250, height : 250, quality :  6, filename :  'lexington01.png'         ,}, object : {widthCM : undefined},},
    lexington01         : {key : 'lexington01'         , image : {width : 250, height : 249, quality :  6, filename :  'lexington01.png'         ,}, object : {widthCM : undefined},},
    lid02               : {key : 'lid02'               , image : {width : 250, height : 217, quality :  4, filename :  'lexington01.png'         ,}, object : {widthCM : undefined},},
    lotus01             : {key : 'lotus01'             , image : {width : 167, height : 250, quality :  3, filename :  'lotus01.png'             ,}, object : {widthCM : undefined},},
    magnoliavase        : {key : 'magnoliavase'        , image : {width : 188, height : 250, quality :  6, filename :  'magnoliavase.png'        ,}, object : {widthCM : undefined},},
    mason1              : {key : 'mason1'              , image : {width : 167, height : 250, quality :  6, filename :  'mason1.png'              ,}, object : {widthCM : undefined},},
    mason3              : {key : 'mason3'              , image : {width : 129, height : 250, quality :  9, filename :  'mason3.png'              ,}, object : {widthCM : undefined},},
    mccormick           : {key : 'mccormick'           , image : {width : 106, height : 250, quality :  3, filename :  'mccormick.png'           ,}, object : {widthCM : undefined},},
    mediterrania01      : {key : 'mediterrania01'      , image : {width : 250, height : 135, quality :  7, filename :  'mediterrania01.png'      ,}, object : {widthCM : undefined},},
    mediterrania02      : {key : 'mediterrania02'      , image : {width : 250, height : 250, quality :  8, filename :  'mediterrania02.png'      ,}, object : {widthCM : undefined},},
    merrileaf01         : {key : 'merrileaf01'         , image : {width : 250, height : 135, quality :  5, filename :  'merrileaf01.png'         ,}, object : {widthCM : 30},},
    morningglory01      : {key : 'morningglory01'      , image : {width : 250, height : 250, quality :  5, filename :  'morningglory01.png'      ,}, object : {widthCM : undefined},},
    napajug             : {key : 'napajug'             , image : {width : 159, height : 250, quality :  6, filename :  'napajug.png'             ,}, object : {widthCM : undefined},},
    nassau01            : {key : 'nassau01'            , image : {width : 169, height : 250, quality :  3, filename :  'nassau01.png'            ,}, object : {widthCM : undefined},},
    nokomisvase         : {key : 'nokomisvase'         , image : {width : 113, height : 250, quality :  8, filename :  'nokomisvase.png'         ,}, object : {widthCM : undefined},},
    normandy01          : {key : 'normandy01'          , image : {width : 250, height : 239, quality :  4, filename :  'normandy01.png'          ,}, object : {widthCM : undefined},},
    normandy02          : {key : 'normandy02'          , image : {width : 250, height : 115, quality :  5, filename :  'normandy02.png'          ,}, object : {widthCM : undefined},},
    normandy03          : {key : 'normandy03'          , image : {width : 249, height : 250, quality :  8, filename :  'normandy03.png'          ,}, object : {widthCM : undefined},},
    normandy04          : {key : 'normandy04'          , image : {width : 250, height : 249, quality :  9, filename :  'normandy04.png'          ,}, object : {widthCM : undefined},},
    orleans01           : {key : 'orleans01'           , image : {width : 250, height : 127, quality :  8, filename :  'orleans01.png'           ,}, object : {widthCM : undefined},},
    orleans02           : {key : 'orleans02'           , image : {width : 245, height : 250, quality :  6, filename :  'orleans02.png'           ,}, object : {widthCM : undefined},},
    orleans03           : {key : 'orleans03'           , image : {width : 250, height : 158, quality :  7, filename :  'orleans03.png'           ,}, object : {widthCM : undefined},},
    orleans04           : {key : 'orleans04'           , image : {width : 247, height : 250, quality :  7, filename :  'orleans04.png'           ,}, object : {widthCM : undefined},},
    orleans05           : {key : 'orleans05'           , image : {width : 250, height : 117, quality :  8, filename :  'orleans05.png'           ,}, object : {widthCM : undefined},},
    pearcookie          : {key : 'pearcookie'          , image : {width : 176, height : 250, quality :  3, filename :  'pearcookie.png'          ,}, object : {widthCM : undefined},},
    pepe01              : {key : 'pepe01'              , image : {width : 250, height : 250, quality :  6, filename :  'pepe01.png'              ,}, object : {widthCM : undefined},},
    piano01             : {key : 'piano01'             , image : {width : 250, height : 135, quality :  4, filename :  'piano01.png'             ,}, object : {widthCM : undefined},},
    pinkspice01         : {key : 'pinkspice01'         , image : {width : 250, height : 135, quality :  6, filename :  'pinkspice01.png'         ,}, object : {widthCM : undefined},},
    pinkspice02         : {key : 'pinkspice02'         , image : {width : 250, height : 250, quality :  8, filename :  'pinkspice02.png'         ,}, object : {widthCM : undefined},},
    pitcher01           : {key : 'pitcher01'           , image : {width : 194, height : 250, quality :  6, filename :  'pitcher01.png'           ,}, object : {widthCM : undefined},},
    pitcher04           : {key : 'pitcher04'           , image : {width : 166, height : 250, quality :  7, filename :  'pitcher04.png'           ,}, object : {widthCM : undefined},},
    pitcher05           : {key : 'pitcher05'           , image : {width : 176, height : 250, quality :  7, filename :  'pitcher05.png'           ,}, object : {widthCM : undefined},},
    pitcher06           : {key : 'pitcher06'           , image : {width : 133, height : 250, quality :  8, filename :  'pitcher06.png'           ,}, object : {widthCM : undefined},},
    pitcher07           : {key : 'pitcher07'           , image : {width : 250, height : 248, quality :  3, filename :  'pitcher07.png'           ,}, object : {widthCM : undefined},},
    pitcher08           : {key : 'pitcher08'           , image : {width : 216, height : 250, quality :  7, filename :  'pitcher08.png'           ,}, object : {widthCM : undefined},},
    pitcher09           : {key : 'pitcher09'           , image : {width : 145, height : 250, quality :  4, filename :  'pitcher09.png'           ,}, object : {widthCM : undefined},},
    pitcher10           : {key : 'pitcher10'           , image : {width : 229, height : 250, quality :  7, filename :  'pitcher10.png'           ,}, object : {widthCM : undefined},},
    pitcher11           : {key : 'pitcher11'           , image : {width : 200, height : 250, quality :  7, filename :  'pitcher11.png'           ,}, object : {widthCM : undefined},},
    pitcher12           : {key : 'pitcher12'           , image : {width : 147, height : 250, quality :  7, filename :  'pitcher12.png'           ,}, object : {widthCM : undefined},},
    pitcher13           : {key : 'pitcher13'           , image : {width : 158, height : 250, quality :  7, filename :  'pitcher13.png'           ,}, object : {widthCM : undefined},},
    pitcher14           : {key : 'pitcher14'           , image : {width : 250, height : 228, quality :  8, filename :  'pitcher14.png'           ,}, object : {widthCM : undefined},},
    pitcherball         : {key : 'pitcherball'         , image : {width : 250, height : 215, quality :  3, filename :  'pitcherball.png'         ,}, object : {widthCM : undefined},},
    pitchergrapes       : {key : 'pitchergrapes'       , image : {width : 200, height : 250, quality :  4, filename :  'pitchergrapes.png'       ,}, object : {widthCM : undefined},},
    plain01             : {key : 'plain01'             , image : {width : 250, height : 167, quality :  6, filename :  'plain01.png'             ,}, object : {widthCM : 13.2},},
    plain02             : {key : 'plain02'             , image : {width : 165, height : 250, quality :  6, filename :  'plain02.png'             ,}, object : {widthCM : 7.5},},
    plain03             : {key : 'plain03'             , image : {width : 250, height : 182, quality :  5, filename :  'plain03.png'             ,}, object : {widthCM : 9.8},},
    plain04             : {key : 'plain04'             , image : {width : 250, height : 172, quality :  6, filename :  'plain04.png'             ,}, object : {widthCM : undefined},},
    plain05             : {key : 'plain05'             , image : {width : 250, height : 232, quality :  5, filename :  'plain05.png'             ,}, object : {widthCM : undefined},},
    plain06             : {key : 'plain06'             , image : {width : 169, height : 250, quality :  6, filename :  'plain06.png'             ,}, object : {widthCM : undefined},},
    plain07             : {key : 'plain07'             , image : {width : 190, height : 250, quality :  6, filename :  'plain07.png'             ,}, object : {widthCM : undefined},},
    plain08             : {key : 'plain08'             , image : {width : 227, height : 250, quality :  4, filename :  'plain08.png'             ,}, object : {widthCM : undefined},},
    plain09             : {key : 'plain09'             , image : {width : 250, height : 250, quality :  7, filename :  'plain09.png'             ,}, object : {widthCM : undefined},},
    plain10             : {key : 'plain10'             , image : {width : 220, height : 250, quality :  6, filename :  'plain10.png'             ,}, object : {widthCM : undefined},},
    plain11             : {key : 'plain11'             , image : {width : 250, height : 157, quality :  7, filename :  'plain11.png'             ,}, object : {widthCM : undefined},},
    plain12             : {key : 'plain12'             , image : {width : 250, height : 129, quality :  7, filename :  'plain12.png'             ,}, object : {widthCM : undefined},},
    plain13             : {key : 'plain13'             , image : {width : 250, height : 225, quality :  6, filename :  'plain13.png'             ,}, object : {widthCM : undefined},},
    plain14             : {key : 'plain14'             , image : {width : 250, height : 247, quality :  5, filename :  'plain14.png'             ,}, object : {widthCM : undefined},},
    plain15             : {key : 'plain15'             , image : {width : 189, height : 250, quality :  6, filename :  'plain15.png'             ,}, object : {widthCM : undefined},},
    plain16             : {key : 'plain16'             , image : {width : 250, height : 120, quality :  7, filename :  'plain16.png'             ,}, object : {widthCM : undefined},},
    plain16a            : {key : 'plain16a'            , image : {width : 242, height : 250, quality :  6, filename :  'plain16a.png'            ,}, object : {widthCM : undefined},},
    plain16b            : {key : 'plain16b'            , image : {width : 239, height : 250, quality :  7, filename :  'plain16b.png'            ,}, object : {widthCM : undefined},},
    plain17             : {key : 'plain17'             , image : {width : 250, height : 146, quality :  6, filename :  'plain17.png'             ,}, object : {widthCM : undefined},},
    plain18             : {key : 'plain18'             , image : {width : 250, height : 170, quality :  5, filename :  'plain18.png'             ,}, object : {widthCM : undefined},},
    plain19             : {key : 'plain19'             , image : {width : 250, height : 211, quality :  5, filename :  'plain19.png'             ,}, object : {widthCM : undefined},},
    plain20             : {key : 'plain20'             , image : {width : 250, height : 150, quality :  7, filename :  'plain20.png'             ,}, object : {widthCM : undefined},},
    plain21             : {key : 'plain21'             , image : {width : 250, height : 124, quality :  6, filename :  'plain21.png'             ,}, object : {widthCM : undefined},},
    plain22             : {key : 'plain22'             , image : {width : 250, height : 175, quality :  8, filename :  'plain22.png'             ,}, object : {widthCM : undefined},},
    plain23             : {key : 'plain23'             , image : {width : 225, height : 250, quality :  5, filename :  'plain23.png'             ,}, object : {widthCM : undefined},},
    plain24             : {key : 'plain24'             , image : {width : 250, height : 202, quality :  6, filename :  'plain24.png'             ,}, object : {widthCM : undefined},},
    planter01           : {key : 'planter01'           , image : {width : 250, height : 105, quality :  7, filename :  'planter01.png'           ,}, object : {widthCM : undefined},},
    planter02           : {key : 'planter02'           , image : {width : 250, height : 137, quality :  6, filename :  'planter02.png'           ,}, object : {widthCM : undefined},},
    planter03           : {key : 'planter03'           , image : {width : 247, height : 250, quality :  4, filename :  'planter03.png'           ,}, object : {widthCM : undefined},},
    planter04           : {key : 'planter04'           , image : {width : 179, height : 250, quality :  7, filename :  'planter04.png'           ,}, object : {widthCM : undefined},},
    planter05           : {key : 'planter05'           , image : {width : 233, height : 250, quality :  6, filename :  'planter05.png'           ,}, object : {widthCM : 12.5},},
    planter06           : {key : 'planter06'           , image : {width : 250, height : 244, quality :  6, filename :  'planter06.png'           ,}, object : {widthCM : 14.5},},
    planter07           : {key : 'planter07'           , image : {width : 250, height :  82, quality :  6, filename :  'planter07.png'           ,}, object : {widthCM : 26.5},},
    planter08           : {key : 'planter08'           , image : {width : 250, height :  86, quality :  6, filename :  'planter08.png'           ,}, object : {widthCM : 35},},
    planter09           : {key : 'planter09'           , image : {width : 226, height : 250, quality :  6, filename :  'planter09.png'           ,}, object : {widthCM : 12},},
    planter10           : {key : 'planter10'           , image : {width : 250, height : 210, quality :  9, filename :  'planter10.png'           ,}, object : {widthCM : 21},},
    planter11           : {key : 'planter11'           , image : {width : 250, height : 242, quality :  5, filename :  'planter11.png'           ,}, object : {widthCM : 13.5},},
    planter12           : {key : 'planter12'           , image : {width : 250, height : 136, quality :  7, filename :  'planter12.png'           ,}, object : {widthCM : 28.5},},
    planter13           : {key : 'planter13'           , image : {width : 250, height : 108, quality :  5, filename :  'planter13.png'           ,}, object : {widthCM : 30.5},},
    planter14           : {key : 'planter14'           , image : {width : 250, height : 142, quality :  7, filename :  'planter14.png'           ,}, object : {widthCM : 16.5},},
    planter15           : {key : 'planter15'           , image : {width : 250, height : 125, quality :  7, filename :  'planter15.png'           ,}, object : {widthCM : 19.5},},
    planter16           : {key : 'planter16'           , image : {width : 250, height : 110, quality :  8, filename :  'planter16.png'           ,}, object : {widthCM : 30.5},},
    planter17           : {key : 'planter17'           , image : {width : 250, height : 196, quality :  6, filename :  'planter17.png'           ,}, object : {widthCM : 19},},
    planter18           : {key : 'planter18'           , image : {width : 227, height : 250, quality :  6, filename :  'planter18.png'           ,}, object : {widthCM : 14.5},},
    planter19           : {key : 'planter19'           , image : {width : 250, height :  80, quality :  7, filename :  'planter19.png'           ,}, object : {widthCM : 35.8},},
    planter20           : {key : 'planter20'           , image : {width : 250, height : 215, quality :  7, filename :  'planter20.png'           ,}, object : {widthCM : 23},},
    planter21           : {key : 'planter21'           , image : {width : 191, height : 250, quality :  8, filename :  'planter21.png'           ,}, object : {widthCM : 13.3},},
    planter22           : {key : 'planter22'           , image : {width : 250, height : 196, quality :  5, filename :  'planter22.png'           ,}, object : {widthCM : undefined},},
    planter23           : {key : 'planter23'           , image : {width : 232, height : 250, quality :  5, filename :  'planter23.png'           ,}, object : {widthCM : undefined},},
    planter24           : {key : 'planter24'           , image : {width : 250, height : 227, quality :  7, filename :  'planter24.png'           ,}, object : {widthCM : undefined},},
    planter25           : {key : 'planter25'           , image : {width : 250, height : 119, quality :  8, filename :  'planter25.png'           ,}, object : {widthCM : undefined},},
    planter26           : {key : 'planter26'           , image : {width : 226, height : 250, quality :  7, filename :  'planter26.png'           ,}, object : {widthCM : undefined},},
    planter27           : {key : 'planter27'           , image : {width : 250, height : 196, quality :  8, filename :  'planter27.png'           ,}, object : {widthCM : undefined},},
    planter28           : {key : 'planter28'           , image : {width : 250, height : 104, quality :  7, filename :  'planter28.png'           ,}, object : {widthCM : undefined},},
    planter29           : {key : 'planter29'           , image : {width : 169, height : 250, quality :  5, filename :  'planter29.png'           ,}, object : {widthCM : undefined},},
    planter30           : {key : 'planter30'           , image : {width : 250, height : 178, quality :  6, filename :  'planter30.png'           ,}, object : {widthCM : undefined},},
    planter31           : {key : 'planter31'           , image : {width : 250, height : 221, quality :  5, filename :  'planter31.png'           ,}, object : {widthCM : undefined},},
    planter32           : {key : 'planter32'           , image : {width : 250, height : 179, quality :  5, filename :  'planter32.png'           ,}, object : {widthCM : undefined},},
    planter33           : {key : 'planter33'           , image : {width : 185, height : 250, quality :  3, filename :  'planter33.png'           ,}, object : {widthCM : undefined},},
    planter34           : {key : 'planter34'           , image : {width : 250, height : 100, quality :  9, filename :  'planter34.png'           ,}, object : {widthCM : undefined},},
    planter35           : {key : 'planter35'           , image : {width : 235, height : 250, quality :  7, filename :  'planter35.png'           ,}, object : {widthCM : undefined},},
    planter36           : {key : 'planter36'           , image : {width : 250, height : 114, quality :  4, filename :  'planter36.png'           ,}, object : {widthCM : undefined},},
    planter37           : {key : 'planter37'           , image : {width : 250, height : 168, quality :  7, filename :  'planter37.png'           ,}, object : {widthCM : undefined},},
    planter38           : {key : 'planter38'           , image : {width : 250, height : 208, quality :  9, filename :  'planter38.png'           ,}, object : {widthCM : undefined},},
    planter39           : {key : 'planter39'           , image : {width : 250, height : 131, quality :  7, filename :  'planter39.png'           ,}, object : {widthCM : undefined},},
    planter40           : {key : 'planter40'           , image : {width : 250, height :  98, quality :  6, filename :  'planter40.png'           ,}, object : {widthCM : undefined},},
    planter41           : {key : 'planter41'           , image : {width : 170, height : 250, quality :  7, filename :  'planter41.png'           ,}, object : {widthCM : undefined},},
    planter42           : {key : 'planter42'           , image : {width : 186, height : 250, quality :  8, filename :  'planter42.png'           ,}, object : {widthCM : undefined},},
    planter43           : {key : 'planter43'           , image : {width : 231, height : 250, quality :  6, filename :  'planter43.png'           ,}, object : {widthCM : undefined},},
    planter44           : {key : 'planter44'           , image : {width : 250, height : 199, quality :  5, filename :  'planter44.png'           ,}, object : {widthCM : undefined},},
    planter45           : {key : 'planter45'           , image : {width : 250, height : 112, quality :  7, filename :  'planter45.png'           ,}, object : {widthCM : undefined},},
    planter46           : {key : 'planter46'           , image : {width : 219, height : 250, quality :  6, filename :  'planter46.png'           ,}, object : {widthCM : undefined},},
    planter47           : {key : 'planter47'           , image : {width : 239, height : 250, quality :  6, filename :  'planter47.png'           ,}, object : {widthCM : undefined},},
    planter48           : {key : 'planter48'           , image : {width : 250, height : 155, quality :  5, filename :  'planter48.png'           ,}, object : {widthCM : undefined},},
    planter49           : {key : 'planter49'           , image : {width : 250, height : 197, quality :  6, filename :  'planter49.png'           ,}, object : {widthCM : undefined},},
    planter50           : {key : 'planter50'           , image : {width : 192, height : 250, quality :  6, filename :  'planter50.png'           ,}, object : {widthCM : undefined},},
    planter51           : {key : 'planter51'           , image : {width : 230, height : 250, quality :  7, filename :  'planter51.png'           ,}, object : {widthCM : undefined},},
    plumblossom01       : {key : 'plumblossom01'       , image : {width : 250, height : 232, quality :  7, filename :  'plumblossom01.png'       ,}, object : {widthCM : undefined},},
    potterjug           : {key : 'potterjug'           , image : {width : 151, height : 250, quality :  5, filename :  'potterjug.png'           ,}, object : {widthCM : undefined},},
    prismatique01       : {key : 'prismatique01'       , image : {width : 120, height : 250, quality :  5, filename :  'prismatique01.png'       ,}, object : {widthCM : undefined},},
    provincial01        : {key : 'provincial01'        , image : {width : 250, height : 138, quality :  6, filename :  'provincial01.png'        ,}, object : {widthCM : 32.5},},
    provincial02        : {key : 'provincial02'        , image : {width : 250, height :  69, quality :  8, filename :  'provincial02.png'        ,}, object : {widthCM : undefined},},
    randomharvest01     : {key : 'randomharvest01'     , image : {width : 126, height : 250, quality :  6, filename :  'randomharvest01.png'     ,}, object : {widthCM : 16},},
    rayreiss01          : {key : 'rayreiss01'          , image : {width : 189, height : 250, quality : 10, filename :  'rayreiss01.png'          ,}, object : {widthCM : 23.5     },},
    redwingrose01       : {key : 'redwingrose01'       , image : {width : 124, height : 250, quality :  4, filename :  'redwingrose01.png'       ,}, object : {widthCM : 16},},
    reed01              : {key : 'reed01'              , image : {width : 250, height : 249, quality :  7, filename :  'reed01.png'              ,}, object : {widthCM : 21.5},},
    reed02              : {key : 'reed02'              , image : {width : 250, height : 250, quality :  6, filename :  'reed02.png'              ,}, object : {widthCM : 9},},
    reed03              : {key : 'reed03'              , image : {width : 250, height : 195, quality :  5, filename :  'reed03.png'              ,}, object : {widthCM : 8.6},},
    reed04              : {key : 'reed04'              , image : {width : 250, height : 214, quality :  6, filename :  'reed04.png'              ,}, object : {widthCM : 7.7},},
    reed05              : {key : 'reed05'              , image : {width : 250, height : 143, quality :  7, filename :  'reed05.png'              ,}, object : {widthCM : 14.7},},
    reed06              : {key : 'reed06'              , image : {width : 229, height : 250, quality :  6, filename :  'reed06.png'              ,}, object : {widthCM : undefined},},
    reed07              : {key : 'reed07'              , image : {width : 250, height : 250, quality :  6, filename :  'reed07.png'              ,}, object : {widthCM : undefined},},
    reed08              : {key : 'reed08'              , image : {width : 244, height : 250, quality :  5, filename :  'reed08.png'              ,}, object : {widthCM : undefined},},
    reed09              : {key : 'reed09'              , image : {width : 250, height : 126, quality :  7, filename :  'reed09.png'              ,}, object : {widthCM : undefined},},
    roundup02           : {key : 'roundup02'           , image : {width : 250, height : 246, quality :  7, filename :  'roundup02.png'           ,}, object : {widthCM : undefined},},
    roundup03           : {key : 'roundup03'           , image : {width : 177, height : 250, quality :  8, filename :  'roundup03.png'           ,}, object : {widthCM : undefined},},
    roundup04           : {key : 'roundup04'           , image : {width : 250, height : 246, quality :  7, filename :  'roundup04.png'           ,}, object : {widthCM : undefined},},
    sandjar01           : {key : 'sandjar01'           , image : {width : 215, height : 250, quality :  6, filename :  'sandjar01.png'           ,}, object : {widthCM : undefined},},
    sandjar02           : {key : 'sandjar02'           , image : {width : 216, height : 250, quality :  6, filename :  'sandjar02.png'           ,}, object : {widthCM : undefined},},
    sandjar03           : {key : 'sandjar03'           , image : {width : 165, height : 250, quality :  8, filename :  'sandjar03.png'           ,}, object : {widthCM : undefined},},
    sewerpipe01         : {key : 'sewerpipe01'         , image : {width : 250, height : 239, quality :  3, filename :  'sewerpipe01.png'         ,}, object : {widthCM : undefined},},
    sewerpipe02         : {key : 'sewerpipe02'         , image : {width :  85, height : 250, quality :  5, filename :  'sewerpipe02.png'         ,}, object : {widthCM : undefined},},
    sleepyeye           : {key : 'sleepyeye'           , image : {width : 179, height : 250, quality :  6, filename :  'sleepyeye.png'           ,}, object : {widthCM : undefined},},
    smartset01          : {key : 'smartset01'          , image : {width :  95, height : 250, quality :  8, filename :  'smartset01.png'          ,}, object : {widthCM : undefined},},
    smartset02          : {key : 'smartset02'          , image : {width : 128, height : 250, quality :  8, filename :  'smartset02.png'          ,}, object : {widthCM : undefined},},
    springsong01        : {key : 'springsong01'        , image : {width : 249, height : 250, quality :  7, filename :  'springsong01.png'        ,}, object : {widthCM : undefined},},
    springsong02        : {key : 'springsong02'        , image : {width : 250, height : 245, quality :  8, filename :  'springsong02.png'        ,}, object : {widthCM : undefined},},
    streamlined01       : {key : 'streamlined01'       , image : {width : 244, height : 250, quality :  4, filename :  'streamlined01.png'       ,}, object : {widthCM : undefined},},
    sundial             : {key : 'sundial'             , image : {width :  90, height : 250, quality :  5, filename :  'sundial.png'             ,}, object : {widthCM : undefined},},
    swedenhouse01       : {key : 'swedenhouse01'       , image : {width : 250, height : 250, quality :  6, filename :  'swedenhouse01.png'       ,}, object : {widthCM : undefined},},
    tampico01           : {key : 'tampico01'           , image : {width : 250, height :  96, quality : undefined, filename :  'tampico01.png'           ,}, object : {widthCM : undefined},},
    teapot01            : {key : 'teapot01'            , image : {width : 250, height : 236, quality : undefined, filename :  'teapot01.png'            ,}, object : {widthCM : undefined},},
    textura             : {key : 'textura'             , image : {width : 250, height : 119, quality : undefined, filename :  'textura.png'             ,}, object : {widthCM : undefined},},
    tiptoe01            : {key : 'tiptoe01'            , image : {width : 213, height : 250, quality : undefined, filename :  'tiptoe01.png'            ,}, object : {widthCM : 14},},
    tiptoe02            : {key : 'tiptoe02'            , image : {width : 250, height : 138, quality : undefined, filename :  'tiptoe02.png'            ,}, object : {widthCM : 16.6},},
    tiptoe03            : {key : 'tiptoe03'            , image : {width : 250, height : 250, quality : undefined, filename :  'tiptoe03.png'            ,}, object : {widthCM : undefined},},
    townandcountry01    : {key : 'townandcountry01'    , image : {width : 250, height : 233, quality : 5        , filename :  'townandcountry01.png'    ,}, object : {widthCM : 13},},
    townandcountry02    : {key : 'townandcountry02'    , image : {width : 183, height : 250, quality : undefined, filename :  'townandcountry02.png'    ,}, object : {widthCM : undefined},},
    townandcountry03    : {key : 'townandcountry03'    , image : {width : 250, height : 208, quality : undefined, filename :  'townandcountry03.png'    ,}, object : {widthCM : undefined},},
    townandcountry03a   : {key : 'townandcountry03a'   , image : {width : 150, height : 250, quality : undefined, filename :  'townandcountry03a.png'   ,}, object : {widthCM : undefined},},
    townandcountry03b   : {key : 'townandcountry03b'   , image : {width : 170, height : 250, quality : undefined, filename :  'townandcountry03b.png'   ,}, object : {widthCM : undefined},},
    tradervic           : {key : 'tradervic'           , image : {width : 194, height : 250, quality : undefined, filename :  'tradervic.png'           ,}, object : {widthCM : undefined},},
    trivet01            : {key : 'trivet01'            , image : {width : 248, height : 250, quality : undefined, filename :  'trivet01.png'            ,}, object : {widthCM : 16.9},},
    turtledove01        : {key : 'turtledove01'        , image : {width : 250, height : 249, quality : undefined, filename :  'turtledove01.png'        ,}, object : {widthCM : undefined},},
    umbrella01          : {key : 'umbrella01'          , image : {width : 154, height : 250, quality : undefined, filename :  'umbrella01.png'          ,}, object : {widthCM : undefined},},
    umbrella02          : {key : 'umbrella02'          , image : {width : 114, height : 250, quality : undefined, filename :  'umbrella02.png'          ,}, object : {widthCM : undefined},},
    urn01               : {key : 'urn01'               , image : {width : 108, height : 250, quality : undefined, filename :  'urn01.png'               ,}, object : {widthCM : undefined},},
    urn02               : {key : 'urn02'               , image : {width : 228, height : 250, quality : 6        , filename :  'urn02.png'               ,}, object : {widthCM : undefined},},
    urn03               : {key : 'urn03'               , image : {width : 228, height : 250, quality : 6        , filename :  'urn03.png'               ,}, object : {widthCM : undefined},},
    vase1               : {key : 'vase1'               , image : {width :  97, height : 250, quality : undefined, filename :  'vase1.png'               ,}, object : {widthCM : undefined},},
    vase012             : {key : 'vase012'             , image : {width : 115, height : 250, quality : undefined, filename :  'vase012.png'             ,}, object : {widthCM : undefined},},
    vase013             : {key : 'vase013'             , image : {width : 114, height : 250, quality : undefined, filename :  'vase013.png'             ,}, object : {widthCM : undefined},},
    vase016             : {key : 'vase016'             , image : {width : 185, height : 250, quality : undefined, filename :  'vase016.png'             ,}, object : {widthCM : undefined},},
    vase017             : {key : 'vase017'             , image : {width : 156, height : 250, quality : undefined, filename :  'vase017.png'             ,}, object : {widthCM : undefined},},
    vase018             : {key : 'vase018'             , image : {width : 215, height : 250, quality : undefined, filename :  'vase018.png'             ,}, object : {widthCM : undefined},},
    vase019             : {key : 'vase019'             , image : {width : 172, height : 250, quality : undefined, filename :  'vase019.png'             ,}, object : {widthCM : undefined},},
    vase020             : {key : 'vase020'             , image : {width : 213, height : 250, quality : undefined, filename :  'vase020.png'             ,}, object : {widthCM : undefined},},
    vase022             : {key : 'vase022'             , image : {width : 171, height : 250, quality : undefined, filename :  'vase022.png'             ,}, object : {widthCM : undefined},},
    vase023             : {key : 'vase023'             , image : {width : 113, height : 250, quality : undefined, filename :  'vase023.png'             ,}, object : {widthCM : undefined},},
    vase024             : {key : 'vase024'             , image : {width : 210, height : 250, quality : undefined, filename :  'vase024.png'             ,}, object : {widthCM : undefined},},
    vase025             : {key : 'vase025'             , image : {width : 188, height : 250, quality : undefined, filename :  'vase025.png'             ,}, object : {widthCM : undefined},},
    vase026             : {key : 'vase026'             , image : {width : 250, height : 153, quality : undefined, filename :  'vase026.png'             ,}, object : {widthCM : undefined},},
    vase027             : {key : 'vase027'             , image : {width : 239, height : 250, quality : undefined, filename :  'vase027.png'             ,}, object : {widthCM : undefined},},
    vase028             : {key : 'vase028'             , image : {width : 199, height : 250, quality : undefined, filename :  'vase028.png'             ,}, object : {widthCM : undefined},},
    vase029             : {key : 'vase029'             , image : {width : 244, height : 250, quality : undefined, filename :  'vase029.png'             ,}, object : {widthCM : undefined},},
    vase030             : {key : 'vase030'             , image : {width : 154, height : 250, quality : undefined, filename :  'vase030.png'             ,}, object : {widthCM : undefined},},
    vase031             : {key : 'vase031'             , image : {width : 232, height : 250, quality : undefined, filename :  'vase031.png'             ,}, object : {widthCM : undefined},},
    vase032             : {key : 'vase032'             , image : {width : 106, height : 250, quality : undefined, filename :  'vase032.png'             ,}, object : {widthCM : undefined},},
    vase033             : {key : 'vase033'             , image : {width : 250, height : 203, quality : undefined, filename :  'vase033.png'             ,}, object : {widthCM : undefined},},
    vase034             : {key : 'vase034'             , image : {width : 209, height : 250, quality : undefined, filename :  'vase034.png'             ,}, object : {widthCM : undefined},},
    vase035             : {key : 'vase035'             , image : {width : 202, height : 250, quality : undefined, filename :  'vase035.png'             ,}, object : {widthCM : undefined},},
    vase036             : {key : 'vase036'             , image : {width : 189, height : 250, quality : undefined, filename :  'vase036.png'             ,}, object : {widthCM : undefined},},
    vase037             : {key : 'vase037'             , image : {width : 135, height : 250, quality : undefined, filename :  'vase037.png'             ,}, object : {widthCM : 13},},
    vase038             : {key : 'vase038'             , image : {width : 211, height : 250, quality : undefined, filename :  'vase038.png'             ,}, object : {widthCM : 14.5},},
    vase039             : {key : 'vase039'             , image : {width : 186, height : 250, quality : undefined, filename :  'vase039.png'             ,}, object : {widthCM : 14.5},},
    vase040             : {key : 'vase040'             , image : {width : 250, height : 225, quality : undefined, filename :  'vase040.png'             ,}, object : {widthCM : 21},},
    vase041             : {key : 'vase041'             , image : {width : 242, height : 250, quality : undefined, filename :  'vase041.png'             ,}, object : {widthCM : 22.5},},
    vase042             : {key : 'vase042'             , image : {width : 161, height : 250, quality : undefined, filename :  'vase042.png'             ,}, object : {widthCM : 14.5},},
    vase043             : {key : 'vase043'             , image : {width : 250, height : 238, quality : undefined, filename :  'vase043.png'             ,}, object : {widthCM : 16},},
    vase044             : {key : 'vase044'             , image : {width : 101, height : 250, quality : undefined, filename :  'vase044.png'             ,}, object : {widthCM : 26},},
    vase045             : {key : 'vase045'             , image : {width : 200, height : 250, quality : undefined, filename :  'vase045.png'             ,}, object : {widthCM : undefined},},  // in notes, I may have not recorded vase45 width
    vase046             : {key : 'vase046'             , image : {width : 184, height : 250, quality : undefined, filename :  'vase046.png'             ,}, object : {widthCM : 14},},
    vase047             : {key : 'vase047'             , image : {width : 145, height : 250, quality : undefined, filename :  'vase047.png'             ,}, object : {widthCM : 17},},
    vase048             : {key : 'vase048'             , image : {width : 250, height : 249, quality : undefined, filename :  'vase048.png'             ,}, object : {widthCM : 22},},
    vase049             : {key : 'vase049'             , image : {width : 120, height : 250, quality : undefined, filename :  'vase049.png'             ,}, object : {widthCM : 11.5},},
    vase050             : {key : 'vase050'             , image : {width : 250, height : 238, quality : undefined, filename :  'vase050.png'             ,}, object : {widthCM : 17.5},},
    vase052             : {key : 'vase052'             , image : {width : 185, height : 250, quality : undefined, filename :  'vase052.png'             ,}, object : {widthCM : 20},},
    vase053             : {key : 'vase053'             , image : {width : 130, height : 250, quality : undefined, filename :  'vase053.png'             ,}, object : {widthCM : undefined},},
    vase054             : {key : 'vase054'             , image : {width : 146, height : 250, quality : undefined, filename :  'vase054.png'             ,}, object : {widthCM : undefined},},
    vase055             : {key : 'vase055'             , image : {width : 153, height : 250, quality : undefined, filename :  'vase055.png'             ,}, object : {widthCM : 18},},
    vase056             : {key : 'vase056'             , image : {width : 205, height : 250, quality : undefined, filename :  'vase056.png'             ,}, object : {widthCM : 17.5},},
    vase057             : {key : 'vase057'             , image : {width : 250, height : 238, quality : undefined, filename :  'vase057.png'             ,}, object : {widthCM : 18},},
    vase058             : {key : 'vase058'             , image : {width : 181, height : 250, quality : undefined, filename :  'vase058.png'             ,}, object : {widthCM : 20},},
    vase059             : {key : 'vase059'             , image : {width : 148, height : 250, quality : undefined, filename :  'vase059.png'             ,}, object : {widthCM : 17.5},},
    vase060             : {key : 'vase060'             , image : {width : 154, height : 250, quality : undefined, filename :  'vase060.png'             ,}, object : {widthCM : undefined},},
    vase061             : {key : 'vase061'             , image : {width :  92, height : 250, quality : undefined, filename :  'vase061.png'             ,}, object : {widthCM : 9.7},},
    vase062             : {key : 'vase062'             , image : {width :  97, height : 250, quality : undefined, filename :  'vase062.png'             ,}, object : {widthCM : 9.7},},
    vase063             : {key : 'vase063'             , image : {width : 230, height : 250, quality : undefined, filename :  'vase063.png'             ,}, object : {widthCM : 19.5},},
    vase064             : {key : 'vase064'             , image : {width : 155, height : 250, quality : undefined, filename :  'vase064.png'             ,}, object : {widthCM : undefined},},
    vase065             : {key : 'vase065'             , image : {width :  95, height : 250, quality : undefined, filename :  'vase065.png'             ,}, object : {widthCM : undefined},},
    vase066             : {key : 'vase066'             , image : {width :  71, height : 250, quality : undefined, filename :  'vase066.png'             ,}, object : {widthCM : undefined},},
    vase067             : {key : 'vase067'             , image : {width : 140, height : 250, quality : undefined, filename :  'vase067.png'             ,}, object : {widthCM : undefined},},
    vase068             : {key : 'vase068'             , image : {width : 156, height : 250, quality : undefined, filename :  'vase068.png'             ,}, object : {widthCM : undefined},},
    vase069             : {key : 'vase069'             , image : {width : 168, height : 250, quality : undefined, filename :  'vase069.png'             ,}, object : {widthCM : undefined},},
    vase070             : {key : 'vase070'             , image : {width : 150, height : 250, quality : undefined, filename :  'vase070.png'             ,}, object : {widthCM : undefined},},
    vase071             : {key : 'vase071'             , image : {width : 190, height : 250, quality : undefined, filename :  'vase071.png'             ,}, object : {widthCM : undefined},},
    vase072             : {key : 'vase072'             , image : {width : 184, height : 250, quality : undefined, filename :  'vase072.png'             ,}, object : {widthCM : undefined},},
    vase073             : {key : 'vase073'             , image : {width : 148, height : 250, quality : undefined, filename :  'vase073.png'             ,}, object : {widthCM : undefined},},
    vase074             : {key : 'vase074'             , image : {width : 250, height : 246, quality : undefined, filename :  'vase074.png'             ,}, object : {widthCM : undefined},},
    vase075             : {key : 'vase075'             , image : {width : 116, height : 250, quality : undefined, filename :  'vase075.png'             ,}, object : {widthCM : undefined},},
    vase076             : {key : 'vase076'             , image : {width : 169, height : 250, quality : undefined, filename :  'vase076.png'             ,}, object : {widthCM : undefined},},
    vase077             : {key : 'vase077'             , image : {width : 159, height : 250, quality : undefined, filename :  'vase077.png'             ,}, object : {widthCM : undefined},},
    vase078             : {key : 'vase078'             , image : {width : 119, height : 250, quality : undefined, filename :  'vase078.png'             ,}, object : {widthCM : undefined},},
    vase079             : {key : 'vase079'             , image : {width : 144, height : 250, quality : undefined, filename :  'vase079.png'             ,}, object : {widthCM : undefined},},
    vase080             : {key : 'vase080'             , image : {width : 242, height : 250, quality : undefined, filename :  'vase080.png'             ,}, object : {widthCM : undefined},},
    vase081             : {key : 'vase081'             , image : {width : 240, height : 250, quality : undefined, filename :  'vase081.png'             ,}, object : {widthCM : undefined},},
    vase082             : {key : 'vase082'             , image : {width : 126, height : 250, quality : undefined, filename :  'vase082.png'             ,}, object : {widthCM : undefined},},
    vase083             : {key : 'vase083'             , image : {width : 102, height : 250, quality : undefined, filename :  'vase083.png'             ,}, object : {widthCM : undefined},},
    vase084             : {key : 'vase084'             , image : {width : 186, height : 250, quality : undefined, filename :  'vase084.png'             ,}, object : {widthCM : undefined},},
    vase085             : {key : 'vase085'             , image : {width :  93, height : 250, quality : undefined, filename :  'vase085.png'             ,}, object : {widthCM : undefined},},
    vase086             : {key : 'vase086'             , image : {width : 242, height : 250, quality : undefined, filename :  'vase086.png'             ,}, object : {widthCM : undefined},},
    vase087             : {key : 'vase087'             , image : {width : 136, height : 250, quality : undefined, filename :  'vase087.png'             ,}, object : {widthCM : undefined},},
    vase088             : {key : 'vase088'             , image : {width : 172, height : 250, quality : undefined, filename :  'vase088.png'             ,}, object : {widthCM : undefined},},
    vase089             : {key : 'vase089'             , image : {width : 162, height : 250, quality : undefined, filename :  'vase089.png'             ,}, object : {widthCM : undefined},},
    vase090             : {key : 'vase090'             , image : {width : 173, height : 250, quality : undefined, filename :  'vase090.png'             ,}, object : {widthCM : undefined},},
    vase091             : {key : 'vase091'             , image : {width : 250, height : 246, quality : undefined, filename :  'vase091.png'             ,}, object : {widthCM : undefined},},
    vase092             : {key : 'vase092'             , image : {width : 137, height : 250, quality : undefined, filename :  'vase092.png'             ,}, object : {widthCM : undefined},},
    vase093             : {key : 'vase093'             , image : {width : 200, height : 250, quality : undefined, filename :  'vase093.png'             ,}, object : {widthCM : undefined},},
    vase094             : {key : 'vase094'             , image : {width : 209, height : 250, quality : undefined, filename :  'vase094.png'             ,}, object : {widthCM : undefined},},
    vase095             : {key : 'vase095'             , image : {width : 148, height : 250, quality : undefined, filename :  'vase095.png'             ,}, object : {widthCM : undefined},},
    vase096             : {key : 'vase096'             , image : {width : 161, height : 250, quality : undefined, filename :  'vase096.png'             ,}, object : {widthCM : undefined},},
    vase097             : {key : 'vase097'             , image : {width : 120, height : 250, quality : undefined, filename :  'vase097.png'             ,}, object : {widthCM : undefined},},
    vase098             : {key : 'vase098'             , image : {width : 126, height : 250, quality : undefined, filename :  'vase098.png'             ,}, object : {widthCM : undefined},},
    vase099             : {key : 'vase099'             , image : {width : 250, height : 237, quality : undefined, filename :  'vase099.png'             ,}, object : {widthCM : undefined},},
    vase100             : {key : 'vase100'             , image : {width : 230, height : 250, quality : undefined, filename :  'vase100.png'             ,}, object : {widthCM : undefined},},
    vase101             : {key : 'vase101'             , image : {width : 243, height : 250, quality : undefined, filename :  'vase101.png'             ,}, object : {widthCM : undefined},},
    vase102             : {key : 'vase102'             , image : {width : 116, height : 250, quality : undefined, filename :  'vase102.png'             ,}, object : {widthCM : undefined},},
    vase103             : {key : 'vase103'             , image : {width : 115, height : 250, quality : undefined, filename :  'vase103.png'             ,}, object : {widthCM : undefined},},
    vase104             : {key : 'vase104'             , image : {width : 115, height : 250, quality : undefined, filename :  'vase104.png'             ,}, object : {widthCM : undefined},},
    vase105             : {key : 'vase105'             , image : {width : 119, height : 250, quality : undefined, filename :  'vase105.png'             ,}, object : {widthCM : undefined},},
    vase106             : {key : 'vase106'             , image : {width : 114, height : 250, quality : undefined, filename :  'vase106.png'             ,}, object : {widthCM : undefined},},
    vase107             : {key : 'vase107'             , image : {width : 117, height : 250, quality : undefined, filename :  'vase107.png'             ,}, object : {widthCM : undefined},},
    vase108             : {key : 'vase108'             , image : {width : 113, height : 250, quality : undefined, filename :  'vase108.png'             ,}, object : {widthCM : undefined},},
    vase109             : {key : 'vase109'             , image : {width :  82, height : 250, quality : undefined, filename :  'vase109.png'             ,}, object : {widthCM : undefined},},
    vase110             : {key : 'vase110'             , image : {width : 165, height : 250, quality : undefined, filename :  'vase110.png'             ,}, object : {widthCM : undefined},},
    vase111             : {key : 'vase111'             , image : {width : 175, height : 250, quality : undefined, filename :  'vase111.png'             ,}, object : {widthCM : undefined},},
    vase112             : {key : 'vase112'             , image : {width : 171, height : 250, quality : undefined, filename :  'vase112.png'             ,}, object : {widthCM : undefined},},
    vase113             : {key : 'vase113'             , image : {width : 176, height : 250, quality : undefined, filename :  'vase113.png'             ,}, object : {widthCM : undefined},},
    vase114             : {key : 'vase114'             , image : {width : 171, height : 250, quality : undefined, filename :  'vase114.png'             ,}, object : {widthCM : undefined},},
    vase115             : {key : 'vase115'             , image : {width : 170, height : 250, quality : undefined, filename :  'vase115.png'             ,}, object : {widthCM : undefined},},
    vase116             : {key : 'vase116'             , image : {width : 168, height : 250, quality : undefined, filename :  'vase116.png'             ,}, object : {widthCM : undefined},},
    vase117             : {key : 'vase117'             , image : {width : 173, height : 250, quality : undefined, filename :  'vase117.png'             ,}, object : {widthCM : undefined},},
    vase118             : {key : 'vase118'             , image : {width : 170, height : 250, quality : undefined, filename :  'vase118.png'             ,}, object : {widthCM : undefined},},
    vase119             : {key : 'vase119'             , image : {width : 144, height : 250, quality : undefined, filename :  'vase119.png'             ,}, object : {widthCM : undefined},},
    vase120             : {key : 'vase120'             , image : {width : 166, height : 250, quality : undefined, filename :  'vase120.png'             ,}, object : {widthCM : undefined},},
    vase121             : {key : 'vase120'             , image : {width : 109, height : 250, quality : undefined, filename :  'vase121.png'             ,}, object : {widthCM : undefined},},
    vase122             : {key : 'vase120'             , image : {width : 109, height : 250, quality : undefined, filename :  'vase122.png'             ,}, object : {widthCM : undefined},},
    vase123             : {key : 'vase120'             , image : {width :  69, height : 250, quality : undefined, filename :  'vase123.png'             ,}, object : {widthCM : undefined},},
    vase124             : {key : 'vase120'             , image : {width : 124, height : 250, quality : undefined, filename :  'vase124.png'             ,}, object : {widthCM : undefined},},
    vase125             : {key : 'vase120'             , image : {width : 148, height : 250, quality : undefined, filename :  'vase125.png'             ,}, object : {widthCM : undefined},},
    vase126             : {key : 'vase120'             , image : {width :  87, height : 250, quality : undefined, filename :  'vase126.png'             ,}, object : {widthCM : undefined},},
    vase127             : {key : 'vase120'             , image : {width : 190, height : 250, quality : undefined, filename :  'vase127.png'             ,}, object : {widthCM : undefined},},
    vase128             : {key : 'vase120'             , image : {width : 127, height : 250, quality : undefined, filename :  'vase128.png'             ,}, object : {widthCM : undefined},},
    vase129             : {key : 'vase120'             , image : {width : 146, height : 250, quality : undefined, filename :  'vase129.png'             ,}, object : {widthCM : undefined},},
    vase130             : {key : 'vase120'             , image : {width : 113, height : 250, quality : undefined, filename :  'vase130.png'             ,}, object : {widthCM : undefined},},
    vase131             : {key : 'vase120'             , image : {width : 146, height : 250, quality : undefined, filename :  'vase131.png'             ,}, object : {widthCM : undefined},},
    vase132             : {key : 'vase120'             , image : {width : 159, height : 250, quality : undefined, filename :  'vase132.png'             ,}, object : {widthCM : undefined},},
    vase133             : {key : 'vase133'             , image : {width : 179, height : 250, quality : undefined, filename :  'vase133.png'             ,}, object : {widthCM : undefined},},
    vase134             : {key : 'vase134'             , image : {width : 164, height : 250, quality : undefined, filename :  'vase134.png'             ,}, object : {widthCM : undefined},},
    vaseapple           : {key : 'vaseapple'           , image : {width : 245, height : 250, quality : undefined, filename :  'vaseapple.png'           ,}, object : {widthCM : undefined},}, 
    violin01            : {key : 'violin01'            , image : {width : 122, height : 250, quality : undefined, filename :  'violin01.png'            ,}, object : {widthCM : undefined},}, 
    violin02            : {key : 'violin02'            , image : {width : 111, height : 250, quality : undefined, filename :  'violin02.png'            ,}, object : {widthCM : undefined},}, 
    whiteandturquoise01 : {key : 'whiteandturquoise01' , image : {width : 250, height : 119, quality : undefined, filename :  'whiteandturquoise01.png' ,}, object : {widthCM : undefined},}, 
    willowwind01        : {key : 'willowwind01'        , image : {width : 250, height : 250, quality : undefined, filename :  'willowwind01.png'        ,}, object : {widthCM : undefined},}, 
    willowwind02        : {key : 'willowwind02'        , image : {width : 250, height : 250, quality : undefined, filename :  'willowwind02.png'        ,}, object : {widthCM : undefined},}, 
    willowwind03        : {key : 'willowwind03'        , image : {width : 250, height : 250, quality : undefined, filename :  'willowwind03.png'        ,}, object : {widthCM : undefined},}, 
    zinnia01            : {key : 'zinnia01'            , image : {width : 149, height : 250, quality : undefined, filename :  'zinnia01.png'            ,}, object : {widthCM : undefined},}, 

    
    

    /*******/
    /* AGO */ 
    /******************************************************************************************/
    /* All of the following Red Wing Trading Post Library objects are in the                  */
    /* RWTP_AgoCollection.  They represent Red Wing Trading Post images from 2017 and before. */
    /* These images are of generally less quality than the "Contemporary" images above, come  */ 
    /* from a previous Red Wing Trading Post era and are of a significantly less quality.     */
    /******************************************************************************************/
    
//  key                    name                             image's PNG information: dimensions (pixels,) image quality and filename                                             actual object information
//                                                                                                                                                                               width of actual object in centimeters
    _bobwhite01         : {key : '_bobwhite01'         , image : {width :  500,  height : 279      , quality :  2, filename : 'ago/_bobwhite01.png'  ,}, object : {widthCM : undefined},},
    _brittany01         : {key : '_brittany01'         , image : {width :  213,  height : 212      , quality :  5, filename : 'ago/_brittany01.png'  ,}, object : {widthCM : undefined},},
    _chuckwagon01       : {key : '_chuckwagon01'       , image : {width :  400,  height : 392      , quality :  3, filename : 'ago/_chuckwagon01.png',}, object : {widthCM : undefined},},
    _churn01            : {key : '_churn01'            , image : {width :  167,  height : 266      , quality :  1, filename : 'ago/_churn01.png'     ,}, object : {widthCM : undefined},},
    _churn02            : {key : '_churn02'            , image : {width :  167,  height : 266      , quality :  3, filename : 'ago/_churn02.png'     ,}, object : {widthCM : undefined},},
    _churn03            : {key : '_churn03'            , image : {width :  335,  height : 500      , quality :  5, filename : 'ago/_churn03.png'     ,}, object : {widthCM : undefined},},
    _churn05            : {key : '_churn05'            , image : {width :  283,  height : 472      , quality :  5, filename : 'ago/_churn05.png'     ,}, object : {widthCM : undefined},},
    _comm198501         : {key : '_comm198501'         , image : {width :  263,  height : 374      , quality :  2, filename : 'ago/_comm198501.png'  ,}, object : {widthCM : undefined},},
    _comm199001         : {key : '_comm199001'         , image : {width :  311,  height : 445      , quality :  1, filename : 'ago/_comm199001.png'  ,}, object : {widthCM : undefined},},
    _comm199201         : {key : '_comm199201'         , image : {width :  258,  height : 431      , quality :  1, filename : 'ago/_comm199201.png'  ,}, object : {widthCM : undefined},},
    _comm199202         : {key : '_comm199202'         , image : {width :  320,  height : 200      , quality :  9, filename : 'ago/_comm199202.png'  ,}, object : {widthCM : undefined},},
    _comm200301         : {key : '_comm200301'         , image : {width :  174,  height : 190      , quality :  5, filename : 'ago/_comm200301.png'  ,}, object : {widthCM : undefined},},
    _cookie01           : {key : '_cookie01'           , image : {width :  353,  height : 500      , quality :  2, filename : 'ago/_cookie01.png'    ,}, object : {widthCM : undefined},},
    _cooler01           : {key : '_cooler01'           , image : {width :  348,  height : 500      , quality :  3, filename : 'ago/_cooler01.png'    ,}, object : {widthCM : undefined},},
    _crock01            : {key : '_crock01'            , image : {width :  160,  height : 194      , quality :  4, filename : 'ago/_crock01.png'     ,}, object : {widthCM : undefined},},
    _crock02            : {key : '_crock02'            , image : {width :  405,  height : 500      , quality :  3, filename : 'ago/_crock02.png'     ,}, object : {widthCM : undefined},},
    _crock03            : {key : '_crock03'            , image : {width :  410,  height : 500      , quality :  2, filename : 'ago/_crock03.png'     ,}, object : {widthCM : undefined},},
    _crock04            : {key : '_crock04'            , image : {width :  392,  height : 469      , quality :  2, filename : 'ago/_crock04.png'     ,}, object : {widthCM : undefined},},
    _crock05            : {key : '_crock05'            , image : {width :  447,  height : 500      , quality :  7, filename : 'ago/_crock05.png'     ,}, object : {widthCM : undefined},},
    _crock06            : {key : '_crock06'            , image : {width :  449,  height : 498      , quality :  4, filename : 'ago/_crock06.png'     ,}, object : {widthCM : undefined},},
    _crock07            : {key : '_crock07'            , image : {width :  463,  height : 499      , quality :  6, filename : 'ago/_crock07.png'     ,}, object : {widthCM : undefined},},
    _crock40            : {key : '_crock40'            , image : {width :  345,  height : 465      , quality :  6, filename : 'ago/_crock40.png'     ,}, object : {widthCM : undefined},},
    _crockhazel         : {key : '_crockhazel'         , image : {width :  412,  height : 482      , quality :  5, filename : 'ago/_crockhazel.png'  ,}, object : {widthCM : undefined},},
    _fanvase01          : {key : '_fanvase01'          , image : {width :  393,  height : 403      , quality :  5, filename : 'ago/_fanvase01.png'   ,}, object : {widthCM : undefined},},
    _feeder01           : {key : '_feeder01'           , image : {width :  181,  height : 211      , quality :  1, filename : 'ago/_feeder01.png'    ,}, object : {widthCM : undefined},},
    _greyline01         : {key : '_greyline01'         , image : {width :  500,  height : 388      , quality :  2, filename : 'ago/_greyline01.png'  ,}, object : {widthCM : undefined},},
    _gypsy01            : {key : '_gypsy01'            , image : {width :  180,  height : 128      , quality :  1, filename : 'ago/_gypsy01.png'     ,}, object : {widthCM : undefined},},
    _jug01              : {key : '_jug01'              , image : {width :  328,  height : 500      , quality :  3, filename : 'ago/_jug01.png'       ,}, object : {widthCM : undefined},},
    _jug02              : {key : '_jug02'              , image : {width :  359,  height : 500      , quality :  3, filename : 'ago/_jug01.png'       ,}, object : {widthCM : undefined},},
    _jug03              : {key : '_jug03'              , image : {width :  337,  height : 500      , quality :  1, filename : 'ago/_jug03.png'       ,}, object : {widthCM : undefined},},
    _jug04              : {key : '_jug04'              , image : {width :  362,  height : 500      , quality :  1, filename : 'ago/_jug04.png'       ,}, object : {widthCM : undefined},},
    _jug05              : {key : '_jug05'              , image : {width :  326,  height : 500      , quality :  2, filename : 'ago/_jug05.png'       ,}, object : {widthCM : undefined},},
    _jug06              : {key : '_jug06'              , image : {width :  324,  height : 500      , quality :  1, filename : 'ago/_jug06.png'       ,}, object : {widthCM : undefined},},
    _jug07              : {key : '_jug07'              , image : {width :  294,  height : 500      , quality :  1, filename : 'ago/_jug07.png'       ,}, object : {widthCM : undefined},},
    _jug08              : {key : '_jug08'              , image : {width :  333,  height : 500      , quality :  1, filename : 'ago/_jug08.png'       ,}, object : {widthCM : undefined},},
    _jug09              : {key : '_jug09'              , image : {width :  319,  height : 500      , quality :  3, filename : 'ago/_jug09.png'       ,}, object : {widthCM : undefined},},
    _jugpotter          : {key : '_jugpotter'          , image : {width :  301,  height : 500      , quality :  4, filename : 'ago/_jugpotter.png'   ,}, object : {widthCM : undefined},},
    _mason02            : {key : '_mason02'            , image : {width :  286,  height : 398      , quality :  5, filename : 'ago/_mason02.png'     ,}, object : {widthCM : undefined},},
    _pitcher01          : {key : '_pitcher01'          , image : {width :  470,  height : 500      , quality :  1, filename : 'ago/_pitcher01.png'   ,}, object : {widthCM : undefined},},
    _reed01             : {key : '_reed01'             , image : {width :  284,  height : 267      , quality :  4, filename : 'ago/_reed01.png'      ,}, object : {widthCM : undefined},},
    _sheetmusic01       : {key : '_sheetmusic01'       , image : {width :  256,  height : 336      , quality : 10, filename : 'ago/_sheetmusic01.png',}, object : {widthCM : undefined},},
    _tarts              : {key : '_tarts'              , image : {width :  354,  height : 500      , quality :  7, filename : 'ago/_tarts.png'       ,}, object : {widthCM : undefined},},
    _tpotchicken01      : {key : '_tpotchicken01'      , image : {width :  232,  height : 207      , quality :  2, filename : 'ago/_tpotchicken01.png',}, object : {widthCM : undefined},},
    _tulip              : {key : '_tulip'              , image : {width :  219,  height : 402      , quality :  3, filename : 'ago/_tulip.png'       ,}, object : {widthCM : undefined},},
    _vase08             : {key : '_vase08'             , image : {width :  375,  height : 500      , quality :  1, filename : 'ago/_vase08.png'      ,}, object : {widthCM : undefined},},
    _vase09             : {key : '_vase09'             , image : {width :  226,  height : 500      , quality :  4, filename : 'ago/_vase09.png'      ,}, object : {widthCM : undefined},},
    _vasegreek01        : {key : '_vasegreek01'        , image : {width :  313,  height : 500      , quality :  4, filename : 'ago/_vasegreek01.png' ,}, object : {widthCM : undefined},},
    _vasegreek02        : {key : '_vasegreek02'        , image : {width :  303,  height : 500      , quality :  3, filename : 'ago/_vasegreek02.png' ,}, object : {widthCM : undefined},},



};  /* RedWingTradingPostLibrary */
  


  
