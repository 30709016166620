/******************************/
/* RedWingTradingPostImage.js */
/**********************************************************************************************/
/* <RedWingTradingPostImage> is a component-form of a (RedWingTradingPostLibrary) image.      */   
/*                                                                                            */     
/* Copyright (C) 2020 Orcadia Labs LLC                                                        */   
/**********************************************************************************************/
import React  from 'react'; 
import '../App.css';

import {RedWingTradingPostLibrarian  } from './RedWingTradingPostLibrarian.js'  ;
import {RedWingTradingPostLibraryCard} from './RedWingTradingPostLibraryCard.js';
import {RWTP_Constants               } from '../constants.js'                   ;


/***************************/
/* RedWingTradingPostImage */
/**********************************************************************************************/
/* Renders a single image from the (RedWingTradingPostLibrary).                               */
/* No state.                                                                                  */
/* This component will display all images in their natural dimensions without scaling.        */
/**********************************************************************************************/
export class RedWingTradingPostImage extends React.Component {

  /**********/
  /* render */
  /********************************************************************************************/
  /* Build an image with the source attribute as saved in state.                              */ 
  /********************************************************************************************/
  render() {
    // gather the image to render:
    var card = this.getLibraryCard();
    if (!card) return (null);
    
    var imageStyles = {
      imageBox : {
        height : RWTP_Constants.image.longestEdge,
        width  : RWTP_Constants.image.longestEdge,
      }, /* imageBox */
      image : {
        width        : 0,
        height       : 0,
        marginTop    : 0,
        marginRight  : 0,
        marginBottom : 0,
        marginLeft   : 0,
      }, /* image */
    }; /* imageBox */
    
    // record image actual dimensions:
    imageStyles.image.width  = card.getImageWidth ();
    imageStyles.image.height = card.getImageHeight();
    
    // calculate the rendering box:
    if (card.getImageWidth() > card.getImageHeight()) {
      // horizontally-oriented image:
      var margin = ((RWTP_Constants.image.longestEdge - card.getImageHeight()) /2);
      imageStyles.image.marginTop = margin; 
      imageStyles.image.marginBottom = margin; 
    } /*if*/
    else {
      // vertically-oriented image:
      var margin = ((RWTP_Constants.image.longestEdge - card.getImageWidth()) /2);
      imageStyles.image.marginRight = margin; 
      imageStyles.image.marginLeft = margin; 
    } /*else*/
  
    // render:
    return (
      <div style={imageStyles.imageBox}>
        <img style={imageStyles.image} src={card.getImageAddress()} />
      </div>
    );
  }; /* render */

  
  /******************/
  /* getLibraryCard */
  /* setLibraryCard */
  /********************************************************************************************/
  /* This <RedWingTradingPostImage> will know the one (RedWingTradingPostLibraryCard) which   */
  /* contains information about the image that this component will render.                    */
  /********************************************************************************************/
  getLibraryCard() {
    return (this.libraryCard);
  }; /* getLibraryCard */
  setLibraryCard(libraryCard = null) {
    this.libraryCard = libraryCard;
  }; /* setLibraryCard */
  
  
  /********/
  /* CTOR */
  /********************************************************************************************/
  /* Accept the name of the Red Wing Trading Post image to render.                            */
  /********************************************************************************************/
  constructor(props) {
    super(props);
    
    // retrieve the one library card represented by this (RedWingTradingPostImage).
    var imageName = this.props[RWTP_Constants.property.property_imageName];
    this.setLibraryCard();
    
    if (imageName) {
      this.setLibraryCard(RedWingTradingPostLibrarian.getCollection().getByName(imageName));
    } /*if*/
  }; /* CTOR */
  
}; /* RedWingTradingPostImage */
