/**************************/
/* RedWingTradingPost.com */
/**********************************************************************************************/
/* Red Wing Trading Post (index)                                                              */
/*                                                                                            */    
/* Copyright (C) 2019 - 2020 Orcadia Labs LLC                                                 */   
/**********************************************************************************************/
// React boilerplate
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

// Orcadia Labs - Red Wing Trading Post
import './index.css';
import {App} from './App.js';


// Open Red Wing Trading Post with all context and information:
ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>, 
    document.getElementById('root')
)

// The follwoing line runs Red Wing Trading Post with a single entry point, no routes:
// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// 20.10.13.1745:
// Problems with Firefox and service workers.
// I don't need offline or quick load support
// See:
//    https://stackoverflow.com/questions/58470055/unhandled-rejection-securityerror-the-operation-is-insecure-on-a-fresh-creat
//serviceWorker.unregister();
serviceWorker.register();
