/***************************************/
/* RedWingTradingPostLibraryCollection */
/**********************************************************************************************/
/* RedWingTradingPostLibrary_Collection supports all common functions for a library           */
/* collection.                                                                                */
/* for all, specifc collections of this RedWingTradingPostLibrary.                            */
/**********************************************************************************************/
import {RedWingTradingPostLibrary            } from '../RedWingTradingPostLibrary.js'    ;
import {RedWingTradingPostLibraryCard        } from '../RedWingTradingPostLibraryCard.js';


export class RedWingTradingPostLibraryCollection {


  /*************/
  /* getByName */
  /********************************************************************************************/
  /* Select and return the item from this collection, by its name (itemName.)  If no item     */ 
  /* from this collection exists with this given (itemName,) then return no item.             */
  /* @param itemName the name of one item to return.                                          */
  /* @return         a RedWingTradingPostLibraryCard representing the given information or    */
  /*                 undefined if an item by (itemName) is not in this collection.            */
  /********************************************************************************************/
  getByName(itemName) {
  
    // determine if this (itemName) is a member of this collection.  If not, quite:
    if (!this.collection.includes(itemName)) {
      return undefined;
    }
    
    // this (itemName) is a member of this collection, 
    // return the item if it is known in the library:
    var item = RedWingTradingPostLibrary[itemName];
    if (item) return(new RedWingTradingPostLibraryCard(item));
    
    return undefined;
  }; /* getByName */

  
  /***************/
  /* getByNumber */
  /********************************************************************************************/
  /* Select and return the item from this collection, by its ordinal (itemNumber.)  If this   */
  /* collection contains few then (itemNumber) items, return no item.                         */ 
  /* @param itemNumber the ordinal (index) of one item to return.                             */
  /* @return           a RedWingTradingPostLibraryCard representing the  (itemNumber)th item  */
  /*                   in this collection or undefined if this collection does hold that      */
  /*                   number of items.                                                       */
  /********************************************************************************************/
  getByNumber(itemNumber) {
    if ((itemNumber < 0) || (itemNumber >= this.collection.length)) {
      return undefined;
    }; /*if*/
    
    var item = RedWingTradingPostLibrary[this.collection[itemNumber]];
console.log("Picking by Number");
    if (item) return(new RedWingTradingPostLibraryCard(item));
    
    return undefined;
  }; /* getByNumber */

  
  /********/
  /* pick */
  /********************************************************************************************/
  /* @return a RedWingTradingPostLibraryCard representing the pick()ed item.                  */
  /********************************************************************************************/
  pick() {
    var which = (Math.floor(Math.random() * this.collection.length));
    return (this.getByNumber(which));
  }; /* pick */
  

  /***********/
  /* getName */
  /********************************************************************************************/
  /* Each RedWingTradingPostLibraryCollection subclass will return its name.                  */
  /* @return the name of this collection.                                                     */
  /********************************************************************************************/
  getName() { return undefined; }; 
  

  /********/
  /* CTOR */
  /********************************************************************************************/
  /* Record access to this collection.                                                        */
  /* This RedWingTradingPostLibraryCollection superclass will not own the collection and it   */
  /* may be modified in any fashion independtly of this superclass's operation.               */
  /* @param collection is the object array of items collected within this collection..        */
  /*                   If not provided, the collection used by this                           */
  /*                   RedWingTradingPostLibraryCollection will be empty.                     */
  /********************************************************************************************/
  constructor(collection) {
    this.collection = [];   // default to an empty collection
    
    // save a reference to this collection if it is a valid collection:
    if ((collection) && (Array.isArray(collection))) {
      this.collection = collection;
    } /*if*/
  }; /* CTOR */
}; /* RedWingTradingPostLibraryCollection  */


