/**************************/
/* OrcadiaLabs_JSXTrix.js */
/*******************************************************/
/* OrcadiaLabs_JSXTrix is a collection of handy and    */
/* convenient, generally useful JSX tools.             */
/*                                                     */
/* Copyright (C) 2018-2020 Orcadia Labs LLC,           */
/*                    all right reserved.              */
/*******************************************************/
import React from 'react';


/********************/
/* OrcadiaLabsJsxIF */
/*******************************************************/
/* A JSX component which conditionally renders its     */
/* children components.                                */
/*                                                     */
/* props:                                              */
/*   if       - a value which, if specified on the     */
/*              component and evaluates to true, will  */
/*              permit the children to be rendered.    */
/*   children - will be rendered if (if) evaluates to  */ 
/*              true.                                  */
/*******************************************************/
export let OrcadiaLabsJsxIF = (props) => {
  if (props['if']) {
    return <div>{props.children}</div>;
  } /*if*/
  
  return null; /* fail, do not generate anything */
}; /* OrcadiaLabsJsxIF */


/*********************/
/* OrcadiaLabsJsxNOT */
/*******************************************************/
/* The logical opposite of OrcadiaLabs_JSX_IF:         */         
/* A JSX component which conditionally renders its     */
/* children components.                                */
/*                                                     */
/* props:                                              */
/*   if       - a value which, if specified on the     */
/*              component and evaluates to false, will */
/*              permit the children to be rendered.    */
/*   children - will be rendered if (if) evaluates to  */
/*              false.                                 */
/*******************************************************/
export let OrcadiaLabsJsxNOT = (props) => {
  if (!props['if']) {
    return <div>{props.children}</div>;
  } /*if*/
  
  return null; /* fail, do not generate anything */
}; /* OrcadiaLabsJsxNOT */


/************************/
/* OrcadiaLabsJsxRepeat */
/*******************************************************/
/* A JSX component which repeats, in their entirety,   */
/* the complete set of children components.            */
/*                                                     */
/* props:                                              */
/*   count    - the number of times to repeat.         */
/*   children - will be rendered (count) number of     */
/*              times.                                 */
/*******************************************************/
export let OrcadiaLabsJsxRepeat = (props) => {
  if (props['count']) {
    /* the correct property was specified: */
    var countS = props['count'];
    var count  = Number.parseInt(countS);
    
    if (!Number.isNaN(count)) { /* throw away invalid values*/
      if (count > 0) {          /* repeat children at least once more */
        return (<div>
                  {props.children}
                  <OrcadiaLabsJsxRepeat count = {(count-1)}>
                    {props.children}
                  </OrcadiaLabsJsxRepeat>
                </div>
               ); /*return*/    
      } /*if*/
    } /*if*/
  } /*if*/
  
  /* fail (or beyond last iteration,) */ 
  /* do not generate anything         */
  return null; 
}; /* OrcadiaLabsJsxRepeat */


